/* eslint-disable default-case */
import { forgetContants } from "../actions/constants";

const initState = {
    otp:'',
    success:false,
    loading: false,
    error: null,
    message: ''
};

export default (state = initState, action) => {

    console.log(action);

    switch (action.type) {
        case forgetContants.FORGET_REQUEST:
            state = {
                ...state,
                loading: true
            }
            break;
        case forgetContants.FORGET_SUCCESS:
            state = {
                ...state,
                otp: action.payload,
                success: true,
                loading: false
            }
            break;
        case forgetContants.FORGET_FAILURE:
            state = {
                ...state,
                error: action.payload,
                success: false,
                loading: false
            }
            break;
    }


    return state;
}