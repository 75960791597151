/* eslint-disable default-case */
import { legalEntityConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  legalEntities: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case legalEntityConstants.FETCH_LEGAL_ENTITIES_REQUEST:
      state = {
        ...state,
        loading: true,
        legalEntities: []
      }
      break;
    case legalEntityConstants.FETCH_LEGAL_ENTITIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        legalEntities: action.payload.data,
      }
      break;
    case legalEntityConstants.FETCH_LEGAL_ENTITIES_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
  }

  return state;
}