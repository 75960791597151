/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function StatusProgram(props) {
  const { t } = useTranslation();
  const data_program = useSelector(state => state.dashboard.data_program)
  var dataSet = [];
  var tot = 0;
  data_program?.dataByProgram?.map((val,index) => {
    tot=tot+val.value;
  })
  let bgColor = "bg-" + props.color;
  function getColor(val) {
    val = (val/tot)*100;
    switch (true) {
      case (val < 40):
          return '#F1334D'
      break;
      case (val < 70):
          return '#FFA127'
      break;
      case (val < 101):
          return '#FCB630'
      break;
    }
  }
  var stat = ['draft', 'scheduled', 'progress', 'suspend', 'complete']
  stat.map((itm,index) => {
    let val = data_program?.dataByProgram?.find(item => item.status === itm)?.value??0
    dataSet.push({ value: val, name:itm, itemStyle: {
      color: getColor(val)}
    })
  })
  function getOption(){
    return {
      xAxis: {
        type: 'category',
        data: [t('draft'), t('scheduled'), t('on_progress'), t('on_hold'), t('done')]
      },
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} Program",
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: dataSet,
          type: 'bar'
        }
      ]
    }
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{tot+' Program'}</h6>
          </div>
          <div className="card-content echart-bidang">            
            <ReactEcharts style={{ height: "350px" }} option={getOption()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusProgram;