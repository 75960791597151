import { instansiConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  instansi: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case instansiConstants.FETCH_INSTANSI_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case instansiConstants.FETCH_INSTANSI_SUCCESS:
      state = {
        ...state,
        loading: false,
        instansi: action.payload.instansi
      }
      break;
    case instansiConstants.FETCH_INSTANSI_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case instansiConstants.INSTANSI_EDIT_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case instansiConstants.INSTANSI_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        instansi: action.payload.instansi
      }
      break;
    case instansiConstants.INSTANSI_EDIT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
  }

  return state;
}