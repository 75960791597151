import { dashboardConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getData = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}/trainers/${instanceId}/count`)

      if (res.data.status === "success") {
        const data = res.data.result

        dispatch({
          type: dashboardConstants.FETCH_DATA_DASHBOARD_SUCCESS,
          payload: { data }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}

export const getDataRecap = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_REKAP_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}chart/data-recap/${instanceId}`)
      if (res.data.status === "success") {
        const data_recap = res.data.result
        
        dispatch({
          type: dashboardConstants.FETCH_DATA_REKAP_DASHBOARD_SUCCESS,
          payload: { data_recap }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_REKAP_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}

export const getDataRecapBusinessmans = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}chart/data-recap-businessmans/${instanceId}`)
      if (res.data.status === "success") {
        const data_recap_businessmans = res.data.result
        dispatch({
          type: dashboardConstants.FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_SUCCESS,
          payload: { data_recap_businessmans }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}

export const getDataRecapTrainersCount = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_REKAP_TC_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}trainers/${instanceId}/count`)
      if (res.data.status === "success") {
        const data_recap_trainers_count = res.data.result
        dispatch({
          type: dashboardConstants.FETCH_DATA_REKAP_TC_DASHBOARD_SUCCESS,
          payload: { data_recap_trainers_count }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_REKAP_TC_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}

export const getDataRecapUMKM = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_REKAP_UMKM_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}chart/total-umkm/${instanceId}`)
      if (res.data.status === "success") {
        const total_umkm = res.data.result
        dispatch({
          type: dashboardConstants.FETCH_DATA_REKAP_UMKM_DASHBOARD_SUCCESS,
          payload: { total_umkm }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_REKAP_UMKM_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}

export const getDataMonitoring = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_MONITORING_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}chart/monitoring/${instanceId}`)
      if (res.data.status === "success") {
        const data_monitoring = res.data.result
        dispatch({
          type: dashboardConstants.FETCH_DATA_MONITORING_DASHBOARD_SUCCESS,
          payload: { data_monitoring }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_MONITORING_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}

export const getDataProgram = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}chart/program/${instanceId}`)
      if (res.data.status === "success") {
        const data_program = res.data.result
        console.log(res);
        dispatch({
          type: dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_SUCCESS,
          payload: { data_program }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}

export const getStakeholderUMKM = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_STAKEHOLDER_REKAP_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}corp/umkm/chart/${instanceId}`)
      if (res.data.status === "success") {
        const stakeholder_umkm = res.data.result
        dispatch({
          type: dashboardConstants.FETCH_STAKEHOLDER_REKAP_SUCCESS,
          payload: { stakeholder_umkm }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_STAKEHOLDER_REKAP_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}


export const getStakeholderProgram = (instanceId) => {
  return async (dispatch) => {
    dispatch({ type: dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_REQUEST })

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}corp/program/chart/${instanceId}`)
      if (res.data.status === "success") {
        const data_program = res.data.result
        console.log(res);
        dispatch({
          type: dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_SUCCESS,
          payload: { data_program }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}