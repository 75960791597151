import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/UI/Modal";
import AvatarEditor from 'react-avatar-editor';
import Swal from "sweetalert2";
import "./style.scss"
import { useTranslation } from "react-i18next";

const UpdImage = ({ isShow, handleClose, handleSave }) => {
  const { t } = useTranslation();
  const [img, setImg] = useState('');
  const editor = useRef()
  const [scale, setScale] = useState(1.2);
  const [allowZoomOut, setAllowZoomOut] = useState(false);
  const dispatch = useDispatch()

  const handleNewImage = e => {
    let baner = e.target.files[0];
    if (baner.size > 1024000) {      
      Swal.fire({
        title: 'Error!',
        html: 'Size banner maksimal 1 MB',
        icon: 'error',
        confirmButtonColor: '#FCB630',
        confirmButtonText: 'Okay'
      })
    } else {
      setImg(baner);
    }
  }

  const handleScale = e => {
    const scale = parseFloat(e.target.value)
    setScale(scale);
  }

  const onSave = () => {
    if(img!==''){
        Swal.fire({
            title: '',
            text: t('are_you_sure'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: t('save'),
            cancelButtonColor: '#d33',
            cancelButtonText: t('re_edit'),
        
          }).then((result) => {
            if (result.isConfirmed) {
                handleSave(editor.current.getImage().toDataURL())
            }
          })
    }else{
      Swal.fire({
          title: '',
          text: t('havent_chosen_banner'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: t('re_edit'),
          cancelButtonColor: '#d33',
          cancelButtonText: t('exit'),
      
        }).then((result) => {
          if (!result.isConfirmed) {
            handleClose();
          }
        })
    }
  }
  return(
    <>
      <Modal modalTitle={t('upload_banner')} size="lg" show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col-md-12 col-kelas">
            <AvatarEditor
              ref={editor}
              image={img}
              width={360}
              height={450}
              border={20}
              scale={parseFloat(scale)}
            />
            <div className="avatar-tools">
              <div className="avatar-zoom">
                <label>Zoom :</label><br />
                <input
                  name="scale"
                  type="range"
                  onChange={handleScale}
                  min={allowZoomOut ? '0.1' : '1'}
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
              </div>
              <div className="avatar-uplod">
                <label>Upload Logo :</label><br />
                <input name="newImage" type="file" onChange={handleNewImage} />
              </div>
            </div>
            
          </div>
          <div className="col">
            <div className="float-right">
              <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
              <button className="btn btn-sm button-green" onClick={() => onSave()}>{t('save')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdImage