import React from 'react';
import { useSelector } from 'react-redux';

function Jenisusaha2(props) {
  let bgColor = "bg-" + props.color;
  const data_recap_businessmans = useSelector(state => state.dashboard.data_recap_businessmans)

  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{props.label}</h6>
          </div>
          <div className="card-content-wrap">
          {
              data_recap_businessmans?.own_production?.data?.map((val,index) => {
                var color = '';
                if (val < 50){
                  color = 'bd3'; 
                }else if (val >= 50 && val < 100){
                  color = 'bd1'; 
                }else if (val >= 100){
                  color = 'bf4'; 
                }
                return(
                    <div className="kt-widget14__progress">
                        <div className={ "kt-widget14__circle " + color }>
                          <span className={"kt-widget14__stat " + color }>{val}</span>
                        </div>
                        <span>{data_recap_businessmans?.own_production?.label[index]}</span>
                    </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jenisusaha2;