import React, { useEffect, useState } from "react";
import "./main.css";
import Navbar from '../navbar/navbar';
import Sidebar from '../sidebar/sidebar';
import { useDispatch } from "react-redux";
import { getProfil } from "../../../actions/instansi.actions";
import loadgif from "../../../assets/loading.gif";

const Layout = ({ children, loading }) => {
  const dispatch = useDispatch()
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const instanceId = user.user_instansi[0].instansi_id
    dispatch(getProfil(instanceId))
  }, [])

  const openSidebar = () => {
    setSidebarOpen(true);
  };
  
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div className="main__layout">
      {loading&&
      <div className="fade loading-frm show">
        <img src={loadgif} alt="logo" />
      </div>
      }
      <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar}/>
        <div className="main">
          <div className="d-flex flex-column flex-shrink-0 h-100">
            <div className="container-fluid mt-2 mb-4 p-3">
              {/* <div class="alert alert-danger" role="alert">
                This is a warning alert—danger it out!
              </div> */}
              {children}
            </div>
          </div>
        </div>
      <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar}/>
    </div>
  );
};

export default Layout;
