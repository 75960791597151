import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/UI/Modal";
import Swal from "sweetalert2";
import "./style.scss"
import * as XLSX from 'xlsx';
import { make_cols } from "../../../helpers/excel/render";
import { useTranslation } from "react-i18next";

const UpdData = ({ isShow, handleClose, handleSave }) => {
  const { t } = useTranslation();
  const [dataFile, setDataFile] = useState({ file: {}, data: [], cols: [] });
  const [count, setCount] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setDataFile({ file: e.dataTransfer.files[0] });
      setCount(count + 1);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setDataFile({ file: e.target.files[0] });
      setCount(count + 1);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (dataFile.file?.name) {
      handleFiles();
    }
  }, [dataFile]);

  const handleFiles = function (e) {
    setTimeout(() => {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      try {
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {
            type: rABS ? "binary" : "array",
            bookVBA: true,
          });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws);
          /* Update state */
          console.log("data", data);
          if (data.length > 0) {
            handleSave(data);
          } else {
            Swal.fire({
              title: "",
              text: "File anda masih kosong atau tidak sesuai template yang diberikan, mohon cek kembali",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Upload Ulang",
              cancelButtonColor: "#d33",
              cancelButtonText: t('cancelled'),
            }).then((result) => {
              if (!result.isConfirmed) {
                handleClose();
              }
            });
          }

          setDataFile({ data: data, cols: make_cols(ws["!ref"]) }, () => {
            console.log(JSON.stringify(dataFile.data, null, 2));
          });
        };

        if (rABS) {
          dataFile?.file && reader.readAsBinaryString(dataFile?.file);
        } else {
          dataFile?.file && reader.readAsArrayBuffer(dataFile?.file);
        }
      } catch (error) {
        Swal.fire({
          title: "",
          text: "Error, upload hanya file excel",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Upload Ulang",
          cancelButtonColor: "#d33",
          cancelButtonText: t('cancelled'),
        }).then((result) => {
          if (!result.isConfirmed) {
            handleClose();
          }
        });
      }
    }, 1000);
  };

  return (
    <>
      <Modal
        modalTitle=""
        size="md"
        show={isShow}
        handleClose={() => handleClose()}
      >
        <div className="row">
          <div className="col-md-12 col-wrapper-file">
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple={true}
                accept=".xlsx, .xls"
                onChange={handleChange}
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                className={dragActive ? "drag-active" : ""}
              >
                <div>
                  <p>{t("text_file_upload")}</p>
                  <button className="upload-button" onClick={onButtonClick}>
                    {t("upload_file")}
                  </button>
                </div>
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdData