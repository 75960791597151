/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import './table.css';
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgram } from "../../../actions/program.actions";
import FormDet from "./detail";
import moment from 'moment';
import {setStatus} from "../../Program/fnc_var";
import { useTranslation } from "react-i18next";

function Umkmbinaan() {
  const { t } = useTranslation();
  const [detail, setDetail] = useState(false);
  const [program_id, setProgram_id] = useState(null);
  const loading = useSelector(state => state.program.loading)
  const program = useSelector(state => state.program)

  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = program?.program?.map((i, index) => {
    return {
      col1: (((page - 1) * perPage) + (index + 1)).toString(),
      col2: i.program_name,
      col3: i.program_category?.name,
      col4: moment(i.program_start).format('DD-MM-YYYY HH:mm')+' WIB',
      col5: moment(i.program_end).format('DD-MM-YYYY HH:mm')+' WIB',
      col6: setStatus(i.status),
      col7: i.participant_quota??'-',
      col8: i.program_participans.length??'-',
      col9: i.id,
    };
  });
  
  const data = React.useMemo(() => list, [program?.program]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: 'col1',
        width: '5%',
        textAlign: 'center'
      },
      {
        Header: t('program_name'),
        accessor: 'col2'
      },
      {
        Header: t('program_type'),
        accessor: 'col3',
        width: '18%',
      },
      {
        Header: t('start_type'),
        accessor: 'col4',
        width: '10%',
        textAlign: 'center'
      },
      {
        Header: t('end_time'),
        accessor: 'col5',
        width: '10%',
        textAlign: 'center'
      },
      {
        Header: t("status"),
        accessor: 'col6',
        width: '8%',
        textAlign: 'center',
        className:'capitalize'
      },
      {
        Header: t("quota"),
        accessor: 'col7',
        width: '8%',
        textAlign: 'center'
      },
      {
        Header: t("number_of_participants"),
        accessor: 'col8',
        width: '8%',
        textAlign: 'center'
      },
      {
        Header: t("details"),
        accessor: 'col9',
        Cell: ({cell}) => (                 
          <span onClick={() => {handleDetail(cell.value)}} className="span-rincian">
              List UMKM
          </span>
        ),
        width: '12%',
        textAlign: 'center'
      }
    ],
    [program,t]
  );

  useEffect(() => {
    let parm = {
      status:'complete'
    }
    dispatch(getProgram(page, perPage, parm))
  }, [page, perPage])

  const handleDetail = (id) => { 
    setProgram_id(id)
    setDetail(true)
  }

  return (
    <Layout>
    <div className="row table-title">
      <span className="title">UMKM Binaan</span>
      <span className="desk">UMKM {'>'} UMKM Binaan</span>
    </div>
    <div className="row table-view">
      { !detail ?
      <div className="col">
        <div className="row" style={{marginBottom:1}}>
          <div className="col-wrapper">
            <div className="text-start">
              <div className="search-wrapper" style={{marginLeft:20}}>
              </div>
            </div>
            <div className="text-end">
            </div>
          </div>
        </div>

        <DataTable
          data={data}
          columns={columns}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={program?.meta?.meta?.last_page}
          loading={program.loading}
        />
      </div>
      :     
      <FormDet
        program_id={program_id}
        handleBack={() => setDetail(false)}
      />
      }
    </div>      
    </Layout>
  );
}

export default Umkmbinaan;
