/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';


function Medsos(props) {
  var data_recap = useSelector(state => state.dashboard.data_recap);
  let bgColor = "bg-" + props.color;
  function getOption(){
    function generateColor(val) {
      if (val < 50){
        return '#053DC8'; 
      }else if (val >= 50 && val < 100){
        return '#FFA127'; 
      }else if (val >= 100){
        return '#FCB630'; 
      }
    }
    if (data_recap?.social_media?.data !== undefined){
      var data_val = [];
      data_recap?.social_media?.data.map((val) => {
        data_val.push({value: val,itemStyle: {color: generateColor(val)}})
      })
    }
    return {
        tooltip: {
            trigger: 'axis',
            formatter: "{b} <br/>{c} {a}",
            axisPointer: {    
                type: 'shadow'
            }
        },
        grid: {
            top: 10,
            bottom: 30,
            left: 60,
            right: 40
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: data_recap?.social_media?.label,
            axisLabel: {
                show: true,
                textStyle: {
                    fontSize: 10
                },
                rich: {
                    flag: {
                        fontSize: 5,
                        padding: 2
                    }
                }
            },
            axisTick: {
                alignWithLabel: true
            }
        },
        series: [{
            name: 'UMKM',
            data: data_val,
            type: 'bar',
            inverse: false,
            label: {
                show: true,
                precision: 1,
                position: 'right',
                valueAnimation: true,
                fontFamily: 'poppins'
            }
        }]
    }
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{props.label}</h6>
          </div>
          <div className="card-content">
            <ReactEcharts style={{ height: "220px" }} option={getOption()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Medsos;
