import React, { useEffect, useState } from "react";
import Input from "../../../components/UI/Input";
import Modal from "../../../components/UI/Modal";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const MdlDesk = ({ isShow, handleClose, handleSave, state }) => {
  const { t } = useTranslation();
  const [ket, setKet] = useState('')

  useEffect(() => {
   if(state=='check'){
     setKet(t('you_verified'))
   } else {
      setKet(t('you_not_verified'))
   }
  }, [state])

  const onSave = () => {
    if (!ket=='') {
      Swal.fire({
          title: '',
          text: t('are_you_sure'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: t('save'),
          cancelButtonColor: '#d33',
          cancelButtonText: t('re_edit'),
      
        }).then((result) => {
          if (result.isConfirmed) {
              handleSave(ket)
          }
        })
    } else {
      Swal.fire({
        text: t('note_yet'),
        icon: 'error',
        confirmButtonText: t('closed')
      })
    }
  }

  return(
    <>
      <Modal modalTitle={state=="check"?t('program_verified'):t('program_unverified')} show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col-md-12">
              <Input
                type="textarea"
                rows={5}
                label={t("notes")}
                placeholder={t('enter_note_here')}
                name="ket"
                value={ket}
                onChange={(event)=>setKet(event.target.value)}
              />
          </div>
          <div className="col">
            <div className="float-right">
              {!state==='view'?
                <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
                :
                <>
                <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
                <button className="btn btn-sm button-green" onClick={() => onSave()}>{t('save')}</button>
                </>
              }      
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MdlDesk