import React, { useEffect, useState } from "react";
import Layout from '../../../../components/Base/main/main';
import { FaDownload, FaSyncAlt, FaUserTie, FaSearch, FaSortAlphaDownAlt } from 'react-icons/fa'
import Input from "../../../../components/UI/Input";
import ErrorRes from "../../../../components/UI/ErrorRes";
import Switch from "../../../../components/UI/Switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { postProgram, editProgram } from "../../../../actions/stakeholder-program.actions";
import ImgHp from "../../../../assets/appon/img_hp.png";

import UpdImage from "./updImage";

import { 
  FaAngleRight} from 'react-icons/fa'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import Moment from 'moment';
import isBase64  from 'is-base64';
import { useTranslation } from "react-i18next";

const FormProgram = ({ handleBack, editState }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.auth)
  const error = useSelector(state => state.program.error);
  const [errorTemp, setErrorTemp] = useState([]);
  const [count, setCount] = useState(0);
  const date_end = new Date();
  //inisialisasi formData
  const program_form = {
    program_name        : editState?.program_name??'',
    program_image       : editState?.program_image??'',
    program_category_id : editState?.program_category?.id??null,
    program_description : editState?.program_description??null,
    program_start       : new Date(editState?.program_start??new Date()),
    program_end         : new Date(editState?.program_end??new Date(date_end.getTime() + 10*60000)),
    participant_quota   : editState?.participant_quota??0,
    participant_budget  : editState?.participant_budget??0,
    status              : editState?.status??'draft',

  };
  //......... end of inisialisasi formData

  const stat = [{
      value:'',
      name:'Pilih Status',
      disabled:true,
      selected:true
    },{
      value:'draft',
      name: t('draft')
    },
    {
      value:'scheduled',
      name: t('scheduled')
    },
    {
      value:'progress',
      name: t('on_progress')
    },
    {
      value:'complete',
      name: t('done')
    },
    {
      value:'suspend',
      name: t('on_hold')
    }]

  const jenis = [{
    value:'',
    name: t('select_program_type'),
    disabled:true,
    selected:true
  },{
    value:'1',
    name: t('loan_funding')
  },
  {
    value:'2',
    name: t('grant_funding')
  },
  {
    value:'3',
    name: t('competition_funding')
  },
  {
    value:'4',
    name: t('event')
  },
  {
    value:'5',
    name: t('trainig')
  },
  {
    value:'6',
    name: t('procurement')
  }]

  const [program, setProgram] = useState(program_form)
  const [loading, setLoading] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [showUpdateKelas, setShowUpdateKelas] = useState(false);
  const [showJenisIndustri, setShowJenisIndustri] = useState(false);
  
  const [showUpdateWilayah, setShowUpdateWilayah] = useState(false);
  const [showUpdateOkr, setShowUpdateOkr] = useState(false);
  const [showUpdateKolab, setShowUpdateKolab] = useState(false);

  const dispatch = useDispatch()
  const [editorState, setEditorState] = useState(null);
  useEffect(() => {
    if(editState?.program_description){
      const blocksFromHTML = convertFromHTML(editState?.program_description);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [])

  useEffect(() => {
    if (program.program_end<program.program_start) {
      Swal.fire({
        title: 'Error!',
        text: 'Waktu selesai tidak boleh kurang dari waktu mulai',
        icon: 'error',
        confirmButtonColor: '#FCB630',
        confirmButtonText: t('closed')
      })
      
      setProgram({...program, ['program_end']: new Date(program.program_start.getTime() + 10*60000)});
    }
  }, [program.program_end])

  useEffect(() => {
    if (program.program_end<program.program_start) {      
      setProgram({...program, ['program_end']: new Date(program.program_start.getTime() + 10*60000)});
    }
  }, [program.program_start])
  
  const handleChange = e => {
    const {name, value} = e.target;
    setProgram({...program, [name]: value});
  }

  const handleChangeSw = e => {
    const {name, value} = e.target;
    const val = program[name] == false?true:false;
    setProgram({...program, [name]: val});
  }

  const handleCancel = () => {
    if (editState) { //state editing
      Swal.fire({
        title: '',
        text: t('back_to_program'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ya',
        cancelButtonColor: '#d33',
        cancelButtonText: t('re_edit'),
    
      }).then((result) => {
        if (result.isConfirmed) {
          handleBack()
        }
      })      
    } else {
      Swal.fire({
        title: '',
        text: t('form_reloaded'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: t('delete_confirmation_yes'),
        cancelButtonColor: '#d33',
        cancelButtonText: t('re_edit'),
    
      }).then((result) => {
        if (result.isConfirmed) {
            setProgram(program_form)
        }
      })  
    }
  }

  const handleSaveAll = () => {
    var newProgram = {};
    newProgram.instansi_id = user?.user.user_instansi[0]?.instansi_id;
    newProgram.user_id = user?.user.id;
    newProgram.program_name       = program['program_name'];
    const content_desk = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    newProgram.program_description= content_desk;
    newProgram.program_category_id= program['program_category_id'];
    if(program['program_image'].indexOf('base64') !== -1){
      newProgram.program_image      = program['program_image'];
    }
    newProgram.program_start      = Moment(program['program_start']).format('YYYY-MM-DD HH:mm:00');
    newProgram.program_end        = Moment(program['program_end']).format('YYYY-MM-DD HH:mm:00');
    newProgram.participant_budget = program['participant_budget'];
    newProgram.participant_quota  = program['kuota']=='terbatas'?program['participant_quota']:0;
    newProgram.status             = program['status'];
    
    Swal.fire({
      title: '',
      text: t('are_you_sure'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: t('save'),
      cancelButtonColor: '#d33',
      cancelButtonText: t('re_edit'),
  
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (!editState) {
          dispatch(postProgram(newProgram))
          .then((res) => {      
            setLoading(false);      
            Swal.fire({
              title: t('success'),
              text: t('program_registered'),
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: t('closed'),
              cancelButtonColor: '#d33',
              cancelButtonText: t('create_new_program'),
            }).then((res) => {
              if (result.isConfirmed) {
                window.location = "/stakeholder-program-data";
              }else{
                setProgram(program_form);
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            var msg = '';
            if(err instanceof Array){
              msg = 'Gagal membuat Program baru, cek apakah ada data yang belum lengkap <br>';
              Object.keys(err).map((keyName, txt) => {
                err[keyName].map((val, i) => {
                  msg = msg + '<span style="font-size:12px;color:#053DC8"> - '+val+'</span>' +'<br>'
                })
              });
            } else {
              msg = t('something_went_wrong');
            }

            Swal.fire({
              title: 'Error!',
              html: msg,
              icon: 'error',
              confirmButtonColor: '#FCB630',
              confirmButtonText: t('closed')
            })
          }
          )
        } else {
          dispatch(editProgram(newProgram,editState?.id))
          .then((res) => {
            setLoading(false);        
            Swal.fire({
              title: t('success'),
              text: t('program_modified'),
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: t('closed'),
              cancelButtonColor: '#d33',
              cancelButtonText: t('create_new_program'),
            }).then((res) => {
              if (result.isConfirmed) {
                handleBack();
              }else{
                setProgram(program_form);
              }
            });
          })
          .catch((err) => {
            setLoading(false);
            Swal.fire({
              title: 'Error!',
              text: t('failed_save_program'),
              icon: 'error',
              confirmButtonColor: '#FCB630',
              confirmButtonText: t('closed')
            })
          }
          )
        }          
      }
    })
  }

  return (
    <>
    <Layout loading={loading}>
    <div className="row table-view">
      <div className="col">
        <div className="row">
          {/* <div className="col-md-4">
            <div className="det-content1">
              <div className="det-tittle">
                  <span className="span-tittle">
                    {t('banner_program')} ({t('optional')})
                  </span>
              </div>
              <div className="det-image">
                {program?.program_image?
                  <img className="img-banner" src={program.program_image} alt="logo" />:<div className="img-banner" />}   
                  <ErrorRes
                    error={error?.program_image}
                  />
              </div>
              
              <div className="det-detail">
                <button className="btn btn-detail" onClick={() => {setShowUpdateImage(true) }}>
                  {t('upload_banner')}
                </button>
                <div className="det-txt">
                  <span className="span-type">
                    tipe file : jpg, jpeg, png
                  </span>
                  <span className="span-type">
                    ukuran : 540x675px (maksimal 1 MB)
                  </span>
                </div>
              </div>
            </div>
          </div>   */}
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                  <Input
                    type="input"
                    label={t('program_name')}
                    name="program_name"
                    error={error?.program_name}
                    value={program.program_name}
                    onChange={handleChange}
                  />
              </div>
              <div className="col-md-12">
                  <Input
                    type="select"
                    label={t('program_type')}
                    name="program_category_id"
                    error={error?.program_category_id}
                    options={jenis}
                    value={program.program_category_id}
                    onChange={handleChange}
                  />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('description')}</span>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'history'],
                      inline: { inDropdown: false },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="dt-picker">
                  <span className="span-desk">{t('early_period')}</span>
                  <DatePicker
                    dateFormat="dd MMMM yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={program.program_start}
                    onChange={(date) => {
                      setProgram({...program, ['program_start']: date});
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="dt-picker">
                  <span className="span-desk">{t('end_period')}</span>
                  <DatePicker
                    dateFormat="dd MMMM yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={program.program_end}
                    onChange={(date) => {
                      setProgram({...program, ['program_end']: date});
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('estimated_amount')}</span>
                  <div className="total-group">
                    <span>{t('total_estimate')} RM</span>
                    <Input
                      type="currency"
                      placeholder={t('total_estimate') +".."}
                      name="participant_budget"
                      error={error?.participant_budget}
                      value={program?.participant_budget}
                      decimal={2}
                      default={program?.participant_budget??0}
                      onChange={(val)=>setProgram({...program, ['participant_budget']: val})}
                    />
                  </div>
                </div>
              </div>
              
              {/* <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('participant_quota')}</span>
                  <label key="kuota1" className="rad-kuota">
                    <input 
                        type="radio"
                        className="rad-green"
                        checked={program.kuota == "takterbatas"? true: false}
                        name="kuota"
                        onChange={handleChange}
                        value="takterbatas" />
                    Tidak Dibatasi
                  </label>
                  <label key="kuota2" className="rad-kuota">
                    <input 
                        type="radio"
                        className="rad-green"
                        checked={program.kuota == "terbatas"? true: false}
                        name="kuota"
                        onChange={handleChange}
                        value="terbatas" />
                    Terbatas, Maksimal
                    <Input
                      type="number"
                      placeholder="maksimal.."
                      name="participant_quota"
                      disabled={program.kuota !== "terbatas"}
                      value={program.participant_quota}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div> */}
              <div className="col-md-12">
              </div>
              <div className="col-md-12">
                  <Input
                    type="select"
                    label={t("status")}
                    name="status"
                    options={stat}
                    value={program.status}
                    onChange={handleChange}
                  />
              </div> 
              <div className="col-md-12">
                <div className="btn-ctrl-form">
                  <button className="btn btn-batal"  onClick={() => handleCancel()}>
                    {t('cancelled')}
                  </button>
                  <button className="btn btn-simpan"  onClick={() => handleSaveAll()}>
                    {t('save')}
                  </button>
                </div>
              </div>             
            </div>               
          </div>   
        </div>   
      </div>
    </div>
    </Layout>
    <UpdImage 
      isShow={showUpdateImage} 
      handleSave={(val)=>{setProgram({...program, ['program_image']: val}); setShowUpdateImage(false)}}
      handleClose={() => setShowUpdateImage(false)}
    />
    </>
  );
}

export default FormProgram;
