import { agencyConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  agencies: [],
  agency: null,
}

export default (state = initState, action) => {
  switch (action.type) {
    case agencyConstants.FETCH_AGENCIES_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case agencyConstants.FETCH_AGENCIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        agencies: action.payload.data
      }
      break;
    case agencyConstants.FETCH_AGENCIES_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case agencyConstants.FETCH_AGENCY_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case agencyConstants.FETCH_AGENCY_SUCCESS:
      state = {
        ...state,
        loading: false,
        agency: action.payload.data
      }
      break;
    case agencyConstants.FETCH_AGENCY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
  }

  return state;
}