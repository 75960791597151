import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editInstansi, getProfil } from "../../actions/instansi.actions";
import Input from "../../components/UI/Input";
import Modal from "../../components/UI/Modal";
import AvatarEditor from 'react-avatar-editor'
import "./style.scss"
import { useTranslation } from "react-i18next";

const UpdateProfil = ({ data, isShow, isLogo, handleClose }) => {
  const { t } = useTranslation();
  const [instansi, setInstansi] = useState(data) 
  const [img, setImg] = useState('');
  const editor = useRef()
  const user = JSON.parse(localStorage.getItem("user"));
  const [scale, setScale] = useState(1.2);
  const [allowZoomOut, setAllowZoomOut] = useState(false);
  const dispatch = useDispatch()

    useEffect(() => {
        setInstansi(data)
    }, [data])


  const handleSave = () => {
    delete instansi.cover_url
    delete instansi.profit_share
    
    if (isLogo) {
      instansi.cover = editor.current.getImage().toDataURL()
    }

    dispatch(editInstansi(instansi))
      .then((res) => {
        const instanceId = user.user_agency.id
        dispatch(getProfil(instanceId))
        handleClose()
      })
      .catch((err) => console.log(err))
  }

  const handleChange = e => {
    const {name, value} = e.target;
    setInstansi({...instansi, [name]: value});
  }

  const handleNewImage = e => {
    setImg(e.target.files[0]);
  }

  const handleScale = e => {
    const scale = parseFloat(e.target.value)
    setScale(scale);
  }

  return(
    <>
      <Modal modalTitle="Ubah Instansi" show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col">
            {!isLogo?            
              <Input
                type="input"
                label="Nama"
                name="name"
                value={instansi.name}
                onChange={handleChange}
              />
              :    
              <>
              <AvatarEditor
                ref={editor}
                image={img}
                width={200}
                height={200}
                border={50}
                scale={parseFloat(scale)}
              />
              <br />
              Upload Logo:
              <input name="newImage" type="file" onChange={handleNewImage} />
              <br />
              Zoom:
              <input
                name="scale"
                type="range"
                onChange={handleScale}
                min={allowZoomOut ? '0.1' : '1'}
                max="2"
                step="0.01"
                defaultValue="1"
              />
              </>
            }
            <div className="float-right">
              <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
              <button className="btn btn-sm button-green" onClick={() => handleSave()}>{t('save')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdateProfil