/* eslint-disable default-case */
import { category_industryConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  category_industry: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case category_industryConstants.FETCH_CATEGORY_INDUSTRY_REQUEST:
      state = {
        ...state,
        loading: true,
        category_industry: []
      }
      break;
    case category_industryConstants.FETCH_CATEGORY_INDUSTRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        category_industry: action.payload.category_industry,
      }
      break;
    case category_industryConstants.FETCH_CATEGORY_INDUSTRY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
  }

  return state;
}