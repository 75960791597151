import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';

function Loker(props) {
  const data = useSelector(state => state.dashboard.data_recap_trainers_count)
  let bgColor = "bg-" + props.color;
  let TotA = 0;
  let TotB = 0;
  let TotC = 0;
  let TotD = 0;
  if (data?.jumlah_pegawai) {
    Object.keys(data.jumlah_pegawai).map(function(key, index) {
      switch (key) {
        case 'less_than_5':
          TotA = (TotA + data.jumlah_pegawai[key]) * 3
        break;
        case '5_to_19':
          TotB = (TotB + data.jumlah_pegawai[key]) * 13
        break;
        case '20_to_99':
          TotC = (TotC + data.jumlah_pegawai[key]) * 55
        break;
        case 'more_than_100':
          TotD = (TotD + data.jumlah_pegawai[key]) * 100
        break;
      }
    });
  }
  
  return (
    <div className="col-md-6 stat-card2">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{props.label}</h6>
          </div>
          <div className="card-content echart-loker">
            <span className="card-loker">{TotA+TotB+TotC+TotD}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loker;