/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editInstansi, getProfil } from "../../../actions/instansi.actions";
import Input from "../../../components/UI/Input";
import Modal from "../../../components/UI/Modal";
import "./style.scss"
import { typeVerifikasi, statusData, renderCategory, renderOwnership, renderEmployees } from "../fnc_var";
import { getCategory_Industry } from "../../../actions/category_industry.actions";
import { postData, updateData } from "../../../actions/stakeholder.actions";
import Compressor from 'compressorjs'
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { getLegalEntities } from "../../../actions";

const FormAdd = ({ data, isShow, activeTab, state, handleClose }) => {
  const { t } = useTranslation();
  const [dataUmkm, setDataUmkm] = useState([]);
  const error = useSelector((state) => state.stakeholder?.error);
  const user = JSON.parse(localStorage.getItem("user"));
  const category_industry = useSelector((state) => state.category_industry);
  const legalEntities = useSelector((state) => state.legalEntity.legalEntities);
  const dispatch = useDispatch();

  if (isShow && error?.message === "Network Error") {
    Swal.fire({
      title: "Error!",
      text: "Ada kesalahan server, proses ini tidak dapat dilakukan",
      icon: "error",
      confirmButtonColor: "#FCB630",
      confirmButtonText: "Okay",
    });
    handleClose();
  }

  useEffect(() => {
    setDataUmkm([]);
  }, [data]);

  useEffect(() => {
    dispatch(getCategory_Industry());
    dispatch(getLegalEntities());
    setDataUmkm([]);
  }, []);

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const handleSave = () => {
    const instanceId = user.user_agency.id;
    var newData = dataUmkm;
    newData.agency_id = instanceId;
    newData.created_by = "-";
    newData.status = dataUmkm?.status ?? "new";
    newData.business_category = dataUmkm?.business_category?.id
      ? dataUmkm?.business_category
      : dataUmkm?.business_category ?? null;

    if (state === "edit") {
      //state editing
      if (newData?.photo_identity?.indexOf("base64") == -1) {
        delete newData.photo_identity;
      }
      if (newData?.photo_business?.indexOf("base64") == -1) {
        delete newData.photo_business;
      }

      dispatch(updateData(newData, dataUmkm.id))
        .then((res) => {
          handleClose();
        })
        .catch((err) => {
          Swal.fire({
            title: "Error!",
            text: "Gagal mengupdate data, cek apakah ada data yang error atau belum lengkap",
            icon: "error",
            confirmButtonColor: "#FCB630",
            confirmButtonText: "Okay",
          });
          console.log(err);
        });
    } else {
      dispatch(postData(newData))
        .then((res) => {
          handleClose();
        })
        .catch((err) => {
          Swal.fire({
            title: "Error!",
            text: "Failed to save data, check if there is an error or incomplete data",
            icon: "error",
            confirmButtonColor: "#FCB630",
            confirmButtonText: "Okay",
          });
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataUmkm({ ...dataUmkm, [name]: value });
  };

  const handleChangeFile = (e) => {
    const { name, value } = e.target;
    if (e.target.files.length > 0) {
      const file = e.currentTarget.files[0];

      new Compressor(file, {
        quality: 0.6,
        success(result) {
          getBase64(result, (res) => {
            setDataUmkm({ ...dataUmkm, [name]: res });
          });
        },
        error(err) {
          console.log(err);
        },
      });
    }
  };

  return (
    <>
      <Modal
        modalTitle={t("add_data")}
        size="lg"
        show={isShow}
        handleClose={() => handleClose()}
      >
        <div className="row">
          <div className="col">
            <div className="row modal-title">
              <div className="col-12 col-md-12">
                <span class="modal-sub-title">{t("entrepreneur_profile")}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                {dataUmkm?.photo_identity !== null && (
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={dataUmkm?.photo_identity}
                  />
                )}
                <Input
                  type="file"
                  label={t("photo_id")}
                  name="id_photo"
                  onChange={handleChangeFile}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("id_number")}
                  name="id_number"
                  error={error?.id_number ?? ""}
                  value={dataUmkm?.id_number ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("owners_name")}
                  name="owner_name"
                  error={error?.owner_name ?? ""}
                  value={dataUmkm?.owner_name ?? ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("email_address")}
                  name="email_address"
                  value={dataUmkm?.email_address ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("contact_number")}
                  name="contact_number"
                  error={error?.contact_number ?? ""}
                  value={dataUmkm?.contact_number ?? ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("tax_id_number_personal")}
                  name="individual_taxpayer_identification_no"
                  value={dataUmkm?.individual_taxpayer_identification_no ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                  <Input
                    type="input"
                    label={t("address")}
                    name="address"
                    value={dataUmkm?.address ?? ""}
                    onChange={handleChange}
                  />
              </div>
            </div>
            
            <div className="row modal-title">
              <div className="col-12 col-md-12">
                <span class="modal-sub-title">{t("business_photo")}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                {dataUmkm?.business_photo !== null && (
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={dataUmkm?.business_photo}
                  />
                )}
                <Input
                  type="file"
                  label={t("business_photo")}
                  name="business_photo"
                  onChange={handleChangeFile}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("business_name")}
                  name="business_name"
                  error={error?.business_name ?? ""}
                  value={dataUmkm?.business_name ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  type="number"
                  label={t("business_phone_number")}
                  name="business_contact_number"
                  error={error?.business_contact_number ?? ""}
                  value={dataUmkm?.business_contact_number ?? ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("business_address")}
                  name="business_address"
                  value={dataUmkm?.business_address ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("tax_id_number_company")}
                  name="business_taxpayer_identification"
                  value={dataUmkm?.business_taxpayer_identification ?? ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={t("business_master_number")}
                  name="business_identification_number"
                  value={dataUmkm?.business_identification_number ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  type="select"
                  label={t("business_category")}
                  name="business_category"
                  options={renderCategory(
                    category_industry,
                    dataUmkm?.business_category ?? null,
                    t('select_business_category')
                  )}
                  value={dataUmkm?.business_category ?? null}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={'Suruhanjaya Syarikat Malaysia (SSM)'}
                  name="ssm"
                  value={dataUmkm?.ssm ?? null}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={'Local Town Council License (PBT License)'}
                  name="local_town_council_license"
                  value={dataUmkm?.local_town_council_license ?? null}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={'JAKIM Halal'}
                  name="jakim_halal"
                  value={dataUmkm?.jakim_halal ?? null}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={'Kementerian Kesihatan Malaysia (KKM)'}
                  name="kkm"
                  value={dataUmkm?.kkm ?? null}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <Input
                  type="input"
                  label={'Law Firm Type'}
                  name="law_firm_type"
                  value={dataUmkm?.law_firm_type ?? null}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4">
                <Input
                  type="select"
                  label={t("legal_entity")}
                  name="legal_entity"
                  options={legalEntities.map((item) => {
                    return {
                      value: item.id,
                      name: item.name_en
                    }
                  })}
                  value={dataUmkm?.legal_entity ?? null}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  type="input"
                  label={t('numbers_of_employee')}
                  name="total_employee"
                  value={dataUmkm?.total_employee ?? null}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  type="currency"
                  label={t("turnover")}
                  name="business_omset"
                  error={error?.business_omset}
                  value={dataUmkm?.business_omset}
                  decimal={2}
                  default={dataUmkm?.business_omset ?? 0}
                  onChange={(val) =>
                    setDataUmkm({ ...dataUmkm, ["business_omset"]: val })
                  }
                />
              </div>
            </div>
            

            <div className="row modal-title">
              <div className="col-12 col-md-12">
                <span class="modal-sub-title">{t('data_verification')}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <Input
                  type="select"
                  label={t('verification_status')}
                  name="verification_status"
                  options={statusData(activeTab, dataUmkm?.verification_status)}
                  value={dataUmkm?.verification_status ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  type="select"
                  label={t('verification_via')}
                  name="verification_via"
                  options={typeVerifikasi(dataUmkm?.verification_via)}
                  value={dataUmkm?.verification_via ?? ""}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-4">
                <Input
                  type="input"
                  label={t('verified_by')}
                  name="verified_by"
                  value={dataUmkm?.verified_by ?? ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <Input
                  type="input"
                  label={t('Verification_record')}
                  name="verified_note"
                  value={dataUmkm?.verified_note ?? ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="float-right">
              <button
                className="btn btn-sm button-primary mr-2"
                onClick={() => handleClose()}
              >
                {t('cancelled')}
              </button>
              <button
                className="btn btn-sm button-green"
                onClick={() => handleSave()}
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormAdd