import { importDataConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  deleteId: null,
  dataImport: [],
  data: [],
  meta: {
    total: 0
  },
}

export default (state = initState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case importDataConstants.IMPORT_DATA_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case importDataConstants.IMPORT_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        dataImport: action.payload.data
      }
      break;
    case importDataConstants.IMPORT_DATA_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
      case importDataConstants.FETCH_DATA_STAKEHOLDER_REQUEST:
        state = {
          ...state,
          loading: true
        }
        break;
      case importDataConstants.FETCH_DATA_STAKEHOLDER_SUCCESS:
        state = {
          ...state,
          loading: false,
          data: action.payload.data,
          meta: action.payload.meta
        }
        break;
      case importDataConstants.FETCH_DATA_STAKEHOLDER_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
        break;
      case importDataConstants.DATA_STAKEHOLDER_POST_REQUEST:
        state = {
          ...state,
          loading: true
        }
      break;
      case importDataConstants.DATA_STAKEHOLDER_POST_SUCCESS:
        state = {
          ...state,
          loading: false,
          message: action.payload.message
        }
        break;
      case importDataConstants.DATA_STAKEHOLDER_POST_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
      break;
      case importDataConstants.DATA_STAKEHOLDER_DELETE_REQUEST:
        state = {
          ...state,
          loading: true
        }
      break;
      case importDataConstants.DATA_STAKEHOLDER_DELETE_SUCCESS:
        state = {
          ...state,
          loading: false,
          deleteId: action.payload.id
        }
        break;
      case importDataConstants.DATA_STAKEHOLDER_DELETE_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
      break;
  }

  return state;
}