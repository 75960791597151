import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';
import loading from "../../../assets/loading.gif";

function Kategori(props) {
  const data = useSelector(state => state.dashboard.data_recap_trainers_count)
  let bgColor = "bg-" + props.color;
  function getOption(){
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        bottom: "bottom",
        data: ["Mikro", "Kecil", "Menengah"],
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#053DC8", "#FFA127 ", "#FCB630"],
      series: [
        {
          name: "Kategori",
          type: "pie",
          radius: "55%",
          bottom: "20%",
          center: ["50%", "60%"],
          data: [
            { value: data?.level?.mikro, name: "Mikro" }, { value: data?.level?.kecil, name: "Kecil" }, { value: data?.level?.menengah, name: "Menengah" }
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  return (
    <div className="col-md-6 stat-card2">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{props.label}</h6>
          </div>
          <div className="card-content echart-kategori">
            { data?.level ?
            <ReactEcharts style={{ height: "275px" }} option={getOption()} />
            :
            <img className="img-dash-load" src={loading} alt="logo" />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kategori;