/* eslint-disable default-case */
import { fltwilayahConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  province: [],
  city: [],
  district: [],
  sub_district: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case fltwilayahConstants.FETCH_FLTPROVINCE_REQUEST:
      state = {
        ...state,
        loading: true,
        province: []
      }
      break;
    case fltwilayahConstants.FETCH_FLTPROVINCE_SUCCESS:
      state = {
        ...state,
        loading: false,
        province: action.payload.province,
      }
      break;
    case fltwilayahConstants.FETCH_FLTPROVINCE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case fltwilayahConstants.FETCH_FLTCITY_REQUEST:
      state = {
        ...state,
        loading: true,
        city: []
      }
      break;
    case fltwilayahConstants.FETCH_FLTCITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        city: action.payload.city,
      }
      break;
    case fltwilayahConstants.FETCH_FLTDISTRICT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case fltwilayahConstants.FETCH_FLTDISTRICT_REQUEST:
      state = {
        ...state,
        loading: true,
        district: []
      }
      break;
    case fltwilayahConstants.FETCH_FLTDISTRICT_SUCCESS:
      state = {
        ...state,
        loading: false,
        district: action.payload.district,
      }
      break;
    case fltwilayahConstants.FETCH_FLTDISTRICT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case fltwilayahConstants.FETCH_FLTSUBDISTRICT_REQUEST:
      state = {
        ...state,
        loading: true,
        sub_district: []
      }
      break;
    case fltwilayahConstants.FETCH_FLTSUBDISTRICT_SUCCESS:
      state = {
        ...state,
        loading: false,
        sub_district: action.payload.sub_district,
      }
      break;
    case fltwilayahConstants.FETCH_FLTSUBDISTRICT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
  }

  return state;
}