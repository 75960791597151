import React, { useEffect, useState } from "react";
import Modal from "../../components/UI/Modal";
import "./style.scss"
import { useTranslation } from "react-i18next";

const FormReevaluate = ({ isShow, data, handleClose, handleUpdateStatus }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState()

  useEffect(() => {
    // setEmail(data?.user?.email ?? '')
    // setName(data.name_agency)
    // setPicNumber(data.pic_number)
  }, [data])

  return(
    <>
      <Modal modalTitle={'Reevaluate'} size="lg" show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col-md-12">
            <form className="mt-2">
              <label>Reason</label>
              <textarea 
                className="form-control d-inline mb-3" 
                type="text" 
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                rows="15"
                required
              />
            </form>
          </div>
        </div>
        <div className="col">
          <div className="float-right">
            <button className="btn btn-sm button-secondary mr-2" onClick={() => {
              handleUpdateStatus(data, 'reevaluate', reason)
              handleClose()  
            }}>Reevaluate</button>
          </div>
        </div>
      </Modal>
    </>
    
  )
}

export default FormReevaluate