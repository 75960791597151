import React, { useEffect } from 'react';
import Layout from '../../../components/Base/main/main';
import SuccessRate from "./SuccessRate";
import AbsorptionBudget from "./AbsorptionBudget";
import "./style.css"
import { useDispatch, useSelector } from 'react-redux';
import { getDataMonitoring} from '../../../actions/dashboard.actions';
import { useTranslation } from 'react-i18next';

function Rekap() {
  const { t } = useTranslation();
  // const { setHeaderComponent } = useContext(LayoutContext);

  // useEffect(() => {
  //   setHeaderComponent(<b>Rekap Data</b>);
  //   return () => {
  //     setHeaderComponent(null);
  //   };
  // }, []);

  const user = JSON.parse(localStorage.getItem("user"))
  const dispatch = useDispatch()

  useEffect(() => {
    const instanceId = user.user_agency.id
    dispatch(getDataMonitoring(instanceId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Layout>
      <div className="row">
        <SuccessRate stat={t('level_of_success')} label={"31 "+t('type')} color="white" />
        <AbsorptionBudget stat={t('absorption_budget')} label={"31 "+t('type')} color="white" />
      </div>
    </Layout>
  );
}

export default Rekap;
