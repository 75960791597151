import React, { useEffect } from 'react';
import Layout from '../../../components/Base/main/main';
import StatCard from "./StatCard";
import MarketPlace from "./MarketPlace";
import Medsos from "./Medsos";
import Bidangindustri from "./Bidangindustri";
import Kategori from "./Kategori";
import Loker from "./Loker";
import Badanhukum from "./Badanhukum";
import Jenisusaha from "./Jenisusaha";
import Jenisusaha2 from "./Jenisusaha2";
import Pegawai from "./Pegawai";
import "./style.css"
import { useDispatch, useSelector } from 'react-redux';
import { 
  getDataRecap,
  getDataRecapBusinessmans,
  getDataRecapTrainersCount,
  getDataRecapUMKM
} from '../../../actions/dashboard.actions';
import { useTranslation } from 'react-i18next';

function Rekap() {
  const { t } = useTranslation();
  // const { setHeaderComponent } = useContext(LayoutContext);

  // useEffect(() => {
  //   setHeaderComponent(<b>Rekap Data</b>);
  //   return () => {
  //     setHeaderComponent(null);
  //   };
  // }, []);

  const user = JSON.parse(localStorage.getItem("user"))
  const dispatch = useDispatch()

  useEffect(() => {
    const instanceId = user.user_agency.id
    dispatch(getDataRecap(instanceId))
    dispatch(getDataRecapBusinessmans(instanceId))
    dispatch(getDataRecapTrainersCount(instanceId))
    dispatch(getDataRecapUMKM(instanceId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Layout>
      <div className="row">
        <StatCard color="redap" />
        <Kategori stat={t('umkm_category')} color="white" />
        <Loker stat={t('umkm_category')} color="white" />
        <Bidangindustri stat={t('industrial_sector')} label="" color="white" />
        <Badanhukum stat={t('legal_entity')} label="" color="white" />
        <Jenisusaha stat={t('business_characteristics')} label="" color="white" />
        <Jenisusaha2 stat={t('business_unit')} label="" color="white" />
        <MarketPlace stat={t('most_popular_marketplaces')} color="white" />
        <Medsos stat={t('most_popular_social_media')} color="white" />
      </div>
    </Layout>
  );
}

export default Rekap;
