import React, { useEffect, useState } from "react";
import Layout from '../../components/Base/main/main';
import { FaCogs, FaPencilAlt, FaUserTie } from 'react-icons/fa'
import { Dropdown } from "react-bootstrap";
import { Tabs, Tab } from 'react-bootstrap';
import './style.scss';
import FormDet from "./formDetail";
import FormEdit from "../Stakeholder/Program/ProgramTambah";
import DataTable from '../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import FormParticipant from "./detail";
import { useTranslation } from "react-i18next";
import { getAgencies, updateStatusAgency } from "../../actions/agency.actions";

function ProgramData() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('new');
  const [selectedProgram, setSelectedProgram] = useState({})
  const [selectedData, setSelectedData] = useState({})
  const [detail, setDetail] = useState(false);
  const [count, setCount] = useState(0);
  const [program_id, setProgram_id] = useState(null);
  const agencies = useSelector(state => state.agency.agencies)
  const loading = useSelector(state => state.agency.loading)
  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = agencies?.map((i, index) => {
    return {
      col1: (((page - 1) * perPage) + (index + 1)).toString(),
      col2: i.user.email ?? '-',
      col3: i.name_agency ?? '-' ?? '-',
      col4: i.name_representative ?? '-',
      col5: i.pic_number ?? '-',
      col6: i,
    };
  });
  
  const data = React.useMemo(() => list, [agencies]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: 'col1',
        textAlign: 'center'
      },
      {
        Header: 'Email',
        accessor: 'col2',
      },
      {
        Header: 'Name of Agency',
        accessor: 'col3',
      },
      {
        Header: 'Name of Representative',
        accessor: 'col4',
      },
      {
        Header: 'PIC Contact Number',
        accessor: 'col5',
        // textAlign: 'center'
      },
      {
        Header: t("details"),
        accessor: 'col6',
        Cell: (item) => (
          <div className="btn-setting">
            <Dropdown className="dpw-setting">
              <Dropdown.Toggle variant="setting" id="dropdown-basic">
                <FaCogs className="me-1" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <>
                  <Dropdown.Item href="#" onClick={() => {
                    setSelectedData(item.cell.value)
                    setShowFormDetail(true)
                  }}>
                    <FaUserTie />
                    Detail
                  </Dropdown.Item>
                  {/* <Dropdown.Item href="#" onClick={() => {
                    handleUpdateStatus(item.cell.value, 'approve')
                  }}>
                    <FaUserTie />
                    Approve
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => {
                    handleUpdateStatus(item.cell.value, 'failed')
                  }}>
                    <FaPencilAlt />
                    Reject
                  </Dropdown.Item> */}
                </>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        width: '5%',
        textAlign: 'center'
      }
    ],
    [agencies, t]
  );

  useEffect(() => {
    dispatch(getAgencies({ page, perPage, status: activeTab }))
  }, [page, perPage, activeTab, count, showFormDetail])
  
  const handleUpdateStatus = (item, status, reason) => { 
    dispatch(updateStatusAgency(item.id, status, reason))
      .then((res) => {
        setShowFormDetail(false)
        setTimeout(() => {
          dispatch(getAgencies({ page, perPage, status: activeTab }))
        }, 200)
      })
      .catch((err) => {

      })
  }

  return (
    <>
    {!showFormEdit?
      <>
      <Layout>
      <div className="row table-title">
        <span className="title">Agency Management</span>
        <span className="desk">Standalone Data {'>'} Agency Management</span>
      </div>
      <div className="row table-view">
        
        { !detail ?
        <div className="col"> 
          <div className="col-tab-wrapper col-tab-mrgn">
            <Tabs
              id="controlled-tab-example"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="mb-1"
              >
              <Tab eventKey="new" title={t('agency_submission_tab_new_application')} />
              <Tab eventKey="approve" title={t('agency_submission_tab_approved')} />
              <Tab eventKey="reevaluate" title={t('agency_submission_tab_reevaluate')} />
              <Tab eventKey="failed" title={t('agency_submission_tab_failed')} />
            </Tabs>
          </div>
          <DataTable
            data={data}
            customState={{listProgram:agencies ?? [],activeTab:activeTab}}
            columns={columns}
            loading={loading}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={page}
            perPage={perPage}
            // totalPage={program?.meta?.last_page}
          />
        </div>
        :     
        <FormParticipant
          program_id={program_id}
          handleBack={() => {
            setDetail(false);
            setCount(count+1);
          }}
        />
        }
      </div>      
      </Layout>
      <FormDet 
        isShow={showFormDetail}
        data={selectedData}
        handleUpdateStatus={handleUpdateStatus}
        handleClose={() => setShowFormDetail(false)}
      />
      </>
    :
      <FormEdit 
        editState={selectedProgram} 
        handleBack={() => {
          setShowFormEdit(false);
          setCount(count+1);
        }}
      />
    }
    </>
  );
}

export default ProgramData;
