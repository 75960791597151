import { programConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getPogramKolaborator = () => {
    return async (dispatch) => {
        dispatch({ type: programConstants.FETCH_PROGRAMKOLABORATOR_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}okr/level`)
            if (res.data.status == "success") {
                const levels = res.data.result.data
                dispatch({
                    type: programConstants.FETCH_PROGRAMKOLABORATOR_SUCCESS,
                    payload: { levels }
                })
            }
        } catch (err) {
            dispatch({
                type: programConstants.FETCH_PROGRAMKOLABORATOR_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getProgram = (page, perPage, parm) => {
    return async (dispatch) => {
        dispatch({ type: programConstants.FETCH_PROGRAM_REQUEST })
        const user = JSON.parse(localStorage.getItem("user"))
        const instanceId = user.user_instansi[0].instansi_id
        var stat = '';
        if (parm?.is_owner>=0) {
          stat = stat + `&is_owner=${parm.is_owner}`
        }
        if (parm?.status) {
          let sts = parm.status==='all'?'':`&filter[status]=${parm.status}`
              sts = parm.status==='deleted'?`&deleted=true`:sts //tab deleted
          stat = stat + sts
        }
        if (parm?.jenis) {
            let jns = parm.jenis==='all'?'':`&filter[program_category_id]=${parm.jenis}`
            stat = stat + jns;
        }
        if (parm?.search) {
          stat = stat + `&filter[search]=${parm.search}`
        }
        try {
            await axios.get(`${process.env.REACT_APP_MASTER_URI}program?with_resource=1&hide_okr_detail=1&instansi_id=${instanceId}&page=${page}&perPage=${perPage}${stat}`)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    const program = data.result?.data??[]
                    const met = data.result;
                    delete met.data;
                    const meta = met;
    
                    dispatch({
                        type: programConstants.FETCH_PROGRAM_SUCCESS,
                        payload: { program, meta }
                    })
                }
             })
             .catch(error => {
                dispatch({
                    type: programConstants.FETCH_PROGRAM_FAILURE,
                    payload: { error: error }
                })
             })
        } catch (err) {
            console.log(err);
            dispatch({
                type: programConstants.FETCH_PROGRAM_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const postProgram = (program) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: programConstants.PROGRAM_POST_REQUEST })
        try {
            const res = 
            await axios.post(`${process.env.REACT_APP_MASTER_URI}program`, program)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: programConstants.PROGRAM_POST_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                console.log('errorr ',error.response);
                dispatch({
                    type: programConstants.PROGRAM_POST_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: programConstants.PROGRAM_POST_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const editProgram = (program,id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: programConstants.PROGRAM_EDIT_REQUEST })
        try {
            const res = 
            await axios.put(`${process.env.REACT_APP_MASTER_URI}program/${id}`, program)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: programConstants.PROGRAM_EDIT_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                dispatch({
                    type: programConstants.PROGRAM_EDIT_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: programConstants.PROGRAM_EDIT_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const deleteProgram = (id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: programConstants.PROGRAM_DELETE_REQUEST })
        try {
            const res = await axios.delete(`${process.env.REACT_APP_MASTER_URI}program/${id}`)
            console.log(res);
            if (res.data.status === "success") {
                dispatch({
                    type: programConstants.PROGRAM_DELETE_SUCCESS,
                    payload: { id: id }
                })
                resolve(res.data.status)
            } else {
                dispatch({
                    type: programConstants.PROGRAM_DELETE_FAILURE,
                    payload: { error: res.data.message }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: programConstants.PROGRAM_DELETE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
  })
}
export const restoreProgram = (id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: programConstants.PROGRAM_RESTORE_REQUEST })
        try {
            const res = await axios.put(`${process.env.REACT_APP_MASTER_URI}program/${id}/restore`)
            console.log(res);
            if (res.data.status === "success") {
                dispatch({
                    type: programConstants.PROGRAM_RESTORE_SUCCESS,
                    payload: { id: id }
                })
                resolve(res.data.status)
            } else {
                dispatch({
                    type: programConstants.PROGRAM_RESTORE_FAILURE,
                    payload: { error: res.data.message }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: programConstants.PROGRAM_DELETE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
  })
}

export const getProgramNoResource = (type = null) => {
    return async (dispatch) => {
        dispatch({ type: programConstants.FETCH_PROGRAM_NO_RESOURCE_REQUEST})

        const user = JSON.parse(localStorage.getItem("user"))
        const instanceId = user.user_agency.id

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}program/?with_resource=0&instansi_id[]=${instanceId}${type !== null ? `&filter[status]=${type}` : '' }`)

            if (res.data.status === "success") {
                const programNoResource = res.data.result.data ?? []
                const meta = res.data.result.meta ?? []

                dispatch({
                    type: programConstants.FETCH_PROGRAM_NO_RESOURCE_SUCCESS,
                    payload: { programNoResource, meta }
                })
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: programConstants.FETCH_PROGRAM_NO_RESOURCE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getProgramParticipant = (program_id ,page, perPage, search, status='') => {
    return async (dispatch) => {
        if(program_id !== null){
            dispatch({ type: programConstants.FETCH_PROGRAM_PARTICIPANT_REQUEST})
            var prgid = parseInt(program_id)>0?`filter[program_id]=${parseInt(program_id)}&`:'';
            try {
                const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}program/participant/?${prgid}search[businessman]=${search}${status !== '' ? '&filter[status]=' +status : '' }&page=${parseInt(page)}&perPage=${parseInt(perPage)}`)    
                if (res.data.status === "success") {
                    var ProgramParticipant = res.data.result
                    if(!ProgramParticipant?.data){
                        ProgramParticipant = {
                            data: [],
                            meta: {
                              current_page: 1,
                              per_page: 0,
                              last_page: 0
                            }
                          }
                    }
    
                    dispatch({
                        type: programConstants.FETCH_PROGRAM_PARTICIPANT_SUCCESS,
                        payload: { ProgramParticipant }
                    })
                }
            } catch (err) {
                console.log(err);
                dispatch({
                    type: programConstants.FETCH_PROGRAM_PARTICIPANT_FAILURE,
                    payload: { error: i18next.t("something_went_wrong") }
                })
            }
        }
    }
}

export const updateBatchProgram = (payload) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: programConstants.PROGRAM_UPDATE_BATCH_REQUEST })
        try {
            const res = await axios.put(`${process.env.REACT_APP_MASTER_URI}program/participant/update-batch`, payload)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status === "success") {
                    dispatch({
                        type: programConstants.PROGRAM_UPDATE_BATCH_SUCCESS,
                        payload: { program: data.result }
                    })
                    resolve('success')
                }
             })
             .catch(error => {
                dispatch({
                    type: programConstants.PROGRAM_UPDATE_BATCH_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: programConstants.PROGRAM_UPDATE_BATCH_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const getProgramUMKMBinaan = (program_id ,page, perPage, search='', status='') => {
    return async (dispatch) => {
        dispatch({ type: programConstants.FETCH_PROGRAM_UMKM_BINAAN_REQUEST})

        try {
            const user = JSON.parse(localStorage.getItem("user"))
            const instanceId = user.user_agency.id
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}program/umkm-binaan/${instanceId}?search[businessman]=${search}${status !== '' ? '&filter[status]=' +status : '' }&page=${parseInt(page)}&perPage=${parseInt(perPage)}${program_id !== '' ? '&filter[program_id]=' +program_id : '' }`)    
            if (res.data.status === "success") {
                var ProgramUMKMBinaan = res.data.result
                if(!ProgramUMKMBinaan?.data){
                    ProgramUMKMBinaan = {
                        data: [],
                        meta: {
                            current_page: 1,
                            per_page: 0,
                            last_page: 0
                        }
                        }
                }

                dispatch({
                    type: programConstants.FETCH_PROGRAM_UMKM_BINAAN_SUCCESS,
                    payload: { ProgramUMKMBinaan }
                })
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: programConstants.FETCH_PROGRAM_UMKM_BINAAN_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}