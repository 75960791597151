/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaArrowLeft, FaUserTie, FaSearch } from 'react-icons/fa'
import './table.css';
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgramParticipant } from "../../../actions/program.actions";
import ProgressBar from 'react-bootstrap/ProgressBar'
import FormUmkm from "../../DataUmkm/Umkm/formDetail";
import { useTranslation } from "react-i18next";

const Detail = ({ program_id, handleBack}) => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState(false);
  const [showFormUmkm, setShowFormUmkm] = useState(false);
  const [selectedUmkm, setSelectedUmkm] = useState([]);
  const loading = useSelector(state => state.program.loading)
  const ProgramParticipant = useSelector(state => state.program.ProgramParticipant)
  const [search, setSearch] = useState('');

  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = ProgramParticipant?.data.map((i, index) => {
    return {
      col1: (((page - 1) * perPage) + (index + 1)).toString(),
      col2: i?.businessman?.business_name,
      col3: i?.businessman?.okr_level?.level_name,
      col4: i?.businessman?.category_industry?.category ?? '-',
      col5: i?.progress ?? 0,
      col6: i?.businessman_id,
    };
  });
  
  const data = React.useMemo(() => list, [ProgramParticipant?.data]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: 'col1',
        width: '5%',
        textAlign: 'center'
      },
      {
        Header: t("msme_name"),
        accessor: 'col2',
        width: '10%',
        Cell: ({row: { original }}) => (     
          <div className="td-usaha">                
              <span className={`span-icon-${original.col5}`}>
                  <FaUserTie className="me-1" />
              </span>                           
              <span className="span-text">
                  {original.col2}
              </span>
          </div>
        )
      },
      {
        Header: t("msme_class"),
        accessor: 'col3',
        width: '10%',
      },
      {
        Header: t("industrial_sector"),
        accessor: 'col4',
        width: '15%',
      },
      {
        Header: t('progress'),
        accessor: 'col5',
        Cell: ({cell}) => (                 
          <div>
            <ProgressBar now={cell.value} />
            <text>{cell.value}%</text>
          </div>
        ),
        width: '30%',
        textAlign: 'center'
      },
      {
        Header: t("details"),
        accessor: 'col6',
        Cell: (item) => (                 
          <span onClick={() => {
            setSelectedUmkm(item.customState.listumkm.find(val => val.businessman_id === item.cell.value)?.businessman);
            setShowFormUmkm(true);
            }} className="span-rincian">
              {t("details")}
          </span>
        ),
        width: '10%',
        textAlign: 'center'
      }
    ],
    [ProgramParticipant?.data,t]
  );

  
  useEffect(() => {
    dispatch(getProgramParticipant(program_id ,page, perPage, search))
  }, [program_id, page, perPage, search])

  return (
    <>
      <div className="col">
        <div className="row">
          <div className="col-wrapper">
            <div className="text-start">              
              <button onClick={() => handleBack()} className="btn btn-sm btn-outline-secondary btn-back">
                <FaArrowLeft className="me-1" />
                kembali
              </button>  
            </div>
            <div className="text-end">
            </div>
          </div>
          
            <div className="col-wrapper">
              <div className="text-start">
                <div className="search-wrapper">
                  <input onChange={(e) => {
                    setSearch(e.target.value)
                  }} id="search-input" placeholder="Cari UMKM.." type="text" />
                  <button className="btn btn-sm btn-outline-secondary mt-1">
                    <FaSearch className="me-1" />
                  </button>                  
                </div>
              </div>
              <div className="text-end">
              </div>
            </div>
        </div>

        <DataTable
          data={data}
          customState={{listumkm:ProgramParticipant?.data??[]}}
          columns={columns}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={ProgramParticipant?.meta.last_page}
          loading={loading}
        />
      </div>   
      <FormUmkm 
        isShow={showFormUmkm}
        data={selectedUmkm}
        handleClose={() => setShowFormUmkm(false)}
      />
    </>
  );
}

export default Detail;
