import React, { useEffect, useState } from "react";
import { useLocation, withRouter, Link } from "react-router-dom";
import "./sidebar.css";
import "./_vertical.scss";
import {
  FaLandmark,
  FaFileContract,
  FaStore, 
  FaChartLine, 
  FaClipboardList,
  FaDatabase} from 'react-icons/fa'
import logo from "../../../assets/appon/garuda.png";
import logo_foot from "../../../assets/appon/logo@1x.svg";
import MetisMenu from "metismenujs";
import loading from "../../../assets/loading.gif";
import { useSelector } from "react-redux";
import env from '../../../config/env';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { getAgencyInfo } from "../../../actions/agency.actions";
import { useDispatch } from "react-redux";

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const instansi = useSelector(state => state.instansi);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const dispatch = useDispatch()

  useEffect(() => {
    initMenu();
  }, [pathname]);

  useEffect(() => {
    if (user?.user_instansi[0]?.level !== 'superadmin' && user.user_agency !== null && (user.user_agency.status == 'uncomplete' || user.user_agency.status == 'reevaluate') && pathname !== '/profil') {
      Swal.fire({
        title: ' ',
        text: user.user_agency.status == 'uncomplete' ? 'Please complete the profile data for approval' : 'Please Reevaluate the profile',
        icon: 'info',
        confirmButtonColor: '#FCB630',
        confirmButtonText: 'Ok',
        closeOnConfirm: false, //It does close the popup when I click on close button
        closeOnCancel: false,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = '/profil'
        }
      })
    } else if (user?.user_instansi[0]?.level !== 'superadmin' &&  user.user_agency !== null && user.user_agency.status == 'new') {
      Swal.fire({
        title: ' ',
        text: 'Account is under review. please wait a moment',
        icon: 'info',
        confirmButtonColor: '#FCB630',
        confirmButtonText: 'Ok',
        closeOnConfirm: false, //It does close the popup when I click on close button
        closeOnCancel: false,
        allowOutsideClick: false
      })
    }
  }, [user])

  useEffect(() => {
    if (user?.user_instansi[0]?.level !== 'superadmin') {
      dispatch(getAgencyInfo(user.user_agency.id))
        .then((res) => {
          setUser(JSON.parse(localStorage.getItem("user")))
        })
        .catch((err) => {

        })
    }
  }, [])

  function noOnlink() {
    Swal.fire({
      title: t('information'),
      text: t('catalog_information'),
      icon: 'info',
      confirmButtonColor: '#FCB630',
      confirmButtonText: t('closed')
    })
  }

  function initMenu(){
    // new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }

  function activateParentDropdown(item){
    item.classList.add("mm-active");
    item.classList.add("mm-act-bdr");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        // parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a

          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
            parent4.childNodes[0].classList.add("mm-active"); //a
          }
        }
      }
      return false;
    }
    return false;
  };

  function comingSoon() {
    Swal.fire({
      title: t('information'),
      text: 'This feature is under development',
      icon: 'info',
      confirmButtonColor: '#FCB630',
      confirmButtonText: t('closed')
    })
  }

  return (
    <div className={sidebarOpen ? "sidebar__responsive shadow" : "shadow"} id="sidebar-a">
      <div className="sidebar__title mb-30">
          <div className="sidebar__img">
            <img src={logo_foot} alt="logo" />
            {/* <h1>{instansi.instansi.name}</h1> */}
          </div>
        <i
          className="fa fa-times"
          id="sidebarIcon"
          onClick={() => closeSidebar()}
        ></i>
      </div>
      {/* <center className="mb-30">
        {
          instansi.instansi?.onlink?.link ?
          <a className="title-statcard" target="_blank" href={env.onlinkUrl+instansi.instansi.onlink.link}>
            <FaStore className="me-1" /> {t("msme_catalog")}
          </a>
          :
          <span className="title-statcard" onClick={()=>{noOnlink()}}>
            <FaStore className="me-1" /> {t("msme_catalog")}
          </span>
        }
      </center> */}
      <div className="sidebar__menu" id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li>
            <Link to={user?.user_instansi[0]?.level === 'superadmin' ? '/agency-submission' : '/'}>
              <FaStore />
              <span>{user?.user_instansi[0]?.level === 'superadmin' ? 'Agency Management' : 'Standalone Data'}</span>
            </Link>
            {user?.user_instansi[0]?.level === 'admin' && <ul className="sub-menu">  
            <li>
              <Link to="/">
                {/* <FaLandmark /> */}
                {/* <span className="badge badge-pill badge-danger float-right">2</span> */}
                <span>- {t("businessmen_data")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="stakeholder-import">- {t('import_data')}</Link>
                </li>
                <li>
                  <Link to="stakeholder-rekap">- {t('data_recap')}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/stakeholder-program">
                {/* <FaClipboardList /> */}
                <span>- {t('program')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="stakeholder-program-data">- {t('program_data')}</Link>
                </li>
                <li>
                  <Link to="stakeholder-program-tambah">- {t('add_program')}</Link>
                </li>
              </ul>
            </li>
            </ul>}
          </li>
          {user?.user_instansi[0]?.level === 'admin' &&<li>
            <Link to="/">
              <FaStore />
              <span>Connected Data</span>
            </Link>
            <ul className="sub-menu">            
              <li>
                <Link to="#" onClick={comingSoon}>
                  <span>- {t('data_recap')}</span>
                </Link>
                <ul className="sub-menu">
                  {instansi.instansi.zone_id != null && <li>
                    <Link to="#" onClick={comingSoon}>{t('not_assisted_msme')}</Link>
                  </li>}
                  <li>
                    <Link to="#" onClick={comingSoon}>{t('assisted_msme')}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#" onClick={comingSoon}>
                  <span>- Program</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#" onClick={comingSoon}> {t('program_data')}</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={comingSoon}> {t('program_finished')}</Link>
                  </li>
                  <li>
                    <Link tto="#" onClick={comingSoon}> {t('add_program')}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#" onClick={comingSoon}>
                  <span>- Monitoring</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#" onClick={comingSoon}> {t('program_on_progress')}</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={comingSoon}> {t('suspended_program')}</Link>
                  </li>
                  <li>
                    <Link to="#" onClick={comingSoon}> {t('program_finished')}</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>}
        </ul>
      </div>
      <div className="sidebar__footer">
        <span>Powered By :</span>
        <img className="img-fluid mt-1" src={logo_foot} alt="" />
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
