import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import PrivateRoute from './routes/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { isUserLoggedIn } from './actions';

// Stakeholder
import StakeholderDash from './pages/Stakeholder/DashboardUmkm';
import StakeholderImport from './pages/Stakeholder/ImportData';
import StakeholderRekap from './pages/Stakeholder/RekapData';
// import StakeholderProgram from './pages/Stakeholder/Program';
// StakeholderProgram
import StakeholderProgramDash from './pages/Stakeholder/DashboardProgram';
import StakeholderProgramTambah from './pages/Stakeholder/Program/ProgramTambah';
import StakeholderProgramData from './pages/Stakeholder/Program/ProgramData';

// DataUmkm
import Rekap from './pages/DataUmkm/Rekap';
import UMKMBinaan from './pages/DataUmkm/UMKMBinaan';
import UMKMNonBinaan from './pages/DataUmkm/Umkm';

// Program
import Program from './pages/Program/Program';
import ProgramTambah from './pages/Program/ProgramTambah';
import ProgramData from './pages/Program/ProgramData';
import ProgramKolaborasi from './pages/Program/ProgramKolaborasi';

// Monitoring
import Monitoring from './pages/Monitoring/Monitoring';
import ProgramSelesai from './pages/Monitoring/ProgramSelesai';
import ProgramOnProgress from './pages/Monitoring/ProgramOnProgress';
import ProgramDitangguhkan from './pages/Monitoring/ProgramDiTangguhkan';

// AdditionalPages
import Signin from './pages/AdditionalPages/Signin';
import SignUp from './pages/AdditionalPages/SignUp';
import ForgetPassword from './pages/AdditionalPages/ForgetPassword';
import Profil from './pages/Profil';

import AgencySubmission from './pages/AgencySubmission';

require('dotenv').config();

function App() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth)

  //componentDidMount or componentDidUpdate
  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }   
  }, [auth.authenticate]);

  return (
    <>
      <Switch>
        {/* Stakeholder */}
        <PrivateRoute path="/" exact component={StakeholderDash} />  
        <PrivateRoute path="/stakeholder-import" exact component={StakeholderImport} /> 
        <PrivateRoute path="/stakeholder-rekap" exact component={StakeholderRekap} /> 
        
        {/* Stakeholder Program */}
        <PrivateRoute path="/stakeholder-program" component={StakeholderProgramDash} /> 
        <PrivateRoute path="/stakeholder-program-tambah" component={StakeholderProgramTambah} /> 
        <PrivateRoute path="/stakeholder-program-data" component={StakeholderProgramData} /> 

        <PrivateRoute path="/agency-submission" component={AgencySubmission} /> 

        {/* DataUmkm */}
        <PrivateRoute path="/wirausaha" exact component={Rekap} />  
        <PrivateRoute path="/umkm" component={UMKMNonBinaan} /> 
        <PrivateRoute path="/umkm-binaan" component={UMKMBinaan} /> 

        {/* Program */}
        <PrivateRoute path="/program" component={Program} /> 
        <PrivateRoute path="/program-tambah" component={ProgramTambah} /> 
        <PrivateRoute path="/program-data" component={ProgramData} /> 
        <PrivateRoute path="/program-kolaborasi" component={ProgramKolaborasi} /> 
        
        {/* Monitoring */}
        <PrivateRoute path="/monitoring" component={Monitoring} /> 
        <PrivateRoute path="/program-on-progress" component={ProgramOnProgress} /> 
        <PrivateRoute path="/program-ditangguhkan" component={ProgramDitangguhkan} /> 
        <PrivateRoute path="/program-selesai" component={ProgramSelesai} /> 

        {/* AdditionalPages */}
        <PrivateRoute path="/profil" component={Profil} /> 
        <Route path="/signin" component={Signin} />
        <Route path="/signup" component={SignUp} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Redirect from="*" to="/" /> 
        
      </Switch>
    </>
  );
}

export default App;
