import { fltOkrConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getOkrLevel = () => {
    return async (dispatch) => {
        dispatch({ type: fltOkrConstants.FETCH_FLTOKRLEVEL_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}okr/level`)
            if (res.data.status == "success") {
                const levels = res.data.result.data
                dispatch({
                    type: fltOkrConstants.FETCH_FLTOKRLEVEL_SUCCESS,
                    payload: { levels }
                })
            }
        } catch (err) {
            dispatch({
                type: fltOkrConstants.FETCH_FLTOKRLEVEL_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getOkrPaket = () => {
    return async (dispatch) => {
        dispatch({ type: fltOkrConstants.FETCH_FLTOKRPAKET_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}okr/master`)
            if (res.data.status == "success") {
                const paket = res.data.result.data
                dispatch({
                    type: fltOkrConstants.FETCH_FLTOKRPAKET_SUCCESS,
                    payload: { paket }
                })
            }
        } catch (err) {
            dispatch({
                type: fltOkrConstants.FETCH_FLTOKRPAKET_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getOkrKelompok = () => {
    return async (dispatch) => {
        dispatch({ type: fltOkrConstants.FETCH_FLTOKRKELOMPOK_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}okr-category-group`)
            if (res.data.status == "success") {
                const kelompok = res.data.result.data
                dispatch({
                    type: fltOkrConstants.FETCH_FLTOKRKELOMPOK_SUCCESS,
                    payload: { kelompok }
                })
            }
        } catch (err) {
            dispatch({
                type: fltOkrConstants.FETCH_FLTOKRKELOMPOK_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getOkrKategori = () => {
    return async (dispatch) => {
        dispatch({ type: fltOkrConstants.FETCH_FLTOKRKATEGORI_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}okr/category`)
            if (res.data.status == "success") {
                const kategori = res.data.result.data
                dispatch({
                    type: fltOkrConstants.FETCH_FLTOKRKATEGORI_SUCCESS,
                    payload: { kategori }
                })
            }
        } catch (err) {
            dispatch({
                type: fltOkrConstants.FETCH_FLTOKRKATEGORI_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getListOkr = (page, perPage, par) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: fltOkrConstants.FETCH_FLTLISTOKR_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}okr/task/list?page=${page}&perPage=${perPage}&${par}`)
            if (res.data.status == "success") {
                const listokr = res.data.result
                dispatch({
                    type: fltOkrConstants.FETCH_FLTLISTOKR_SUCCESS,
                    payload: { listokr }
                })
                resolve(res)
            } else {
                dispatch({
                    type: fltOkrConstants.FETCH_FLTLISTOKR_FAILURE,
                    payload: { error: i18next.t("something_went_wrong") }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: fltOkrConstants.FETCH_FLTLISTOKR_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}