import { category_industryConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getCategory_Industry = () => {
  return async (dispatch) => {
    dispatch({ type: category_industryConstants.FETCH_CATEGORY_INDUSTRY_REQUEST }) 

    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}category_industries`)

      if (res.data.status === "success") {
        const category_industry = res.data.result
        dispatch({
          type: category_industryConstants.FETCH_CATEGORY_INDUSTRY_SUCCESS,
          payload: { category_industry }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: category_industryConstants.FETCH_CATEGORY_INDUSTRY_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}