import React, { useState } from 'react';
import { login, register } from '../../../actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './style.scss';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import logo from "../../../assets/logo1.svg";
import logo2 from "../../../assets/logo2.svg";
import illustationLogin from "../../../assets/illustration_login.svg";
import Loading from '../../../components/UI/Loading';
/**
* @author
* @function SignUp
**/

const SignUp = (props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nameAgency, setNameAgency] = useState('');
    const [nameRepresentative, setNameRepresentative] = useState('');
    const [picContact, setPicContact] = useState('');
    const [error, setError] = useState('');
    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const _register = (e) => {
        e.preventDefault();

        const data = {
          nameAgency,
          email,
          password,
          picContact,
          nameRepresentative
        }
        setLoading(true)
        dispatch(register(data))
          .then((res) => {
            setLoading(false)
            Swal.fire({
              text: 'Successful registration',
              icon: 'success',
              confirmButtonColor: '#FCB630',
              confirmButtonText: t('closed')
            }).then((result) => {
              if (result.isConfirmed) {
                window.location = '/signin'
              }
            })
          })
          .catch((err) => {
            setLoading(false)
            Swal.fire({
              text: err,
              icon: 'error',
              confirmButtonColor: '#FCB630',
              confirmButtonText: t('closed')
            })
          })
    }

    if (auth.authenticate) {
        return <Redirect to={`/`} />
    }

    return (
      <>
        {loading && <Loading />}
        <div className="min-vh-100 d-flex flex-column">
          <div className="d-flex justify-content-between px-5 py-2 mb-2" style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <img src={logo} />
          </div>
          <div className="d-flex ">
            <div className="flex-column w-100 align-items-center pt-5 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block" style={{ background: '#fbfbfb' }} >
              <center><img src={illustationLogin} /></center>
              <center><h4>Hi, Welcome to SMEspace</h4></center>
              <center><p className="text-center">Platform for you entrepreneurs in<br/> monitoring contracts, cooperation and negotiations with fellow entrepreneurs or stakeholders</p></center>
            </div>
            <div className="flex-shrink-1 justify-content-center mt-5">
              <div className="d-flex flex-column px-5">
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <h3>Register as an Agency</h3>
                  <p>Register now by Email or Phone</p>
                  <form className="mt-2" onSubmit={_register}>
                    <label>Email</label>
                    <input 
                      className="form-control d-inline mb-3" 
                      type="text" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label>Password</label>
                    <input 
                      className="form-control d-inline mb-2" 
                      type="password" 
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <label>Confirm Password</label>
                    <input 
                      className="form-control d-inline mb-3" 
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <label>Name of Agency</label>
                    <input
                      className="form-control d-inline mb-3"
                      type="text"
                      value={nameAgency}
                      onChange={(e) => setNameAgency(e.target.value)}
                      required
                    />
                    <label>Name of Representative</label>
                    <input
                      className="form-control d-inline mb-3"
                      type="text"
                      value={nameRepresentative}
                      onChange={(e) => setNameRepresentative(e.target.value)}
                      required
                    />
                    <label>PIC Contact Number</label>
                    <input
                      className="form-control d-inline mb-5"
                      type="text"
                      value={picContact}
                      onChange={(e) => setPicContact(e.target.value)}
                      required
                    />
                    <button className="btn btn-signin font-bold" type="submit">Sign Up</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )    
}

export default SignUp