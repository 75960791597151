import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaCogs, FaSearch, FaEye, FaTrashAlt } from 'react-icons/fa'
import { Modal, Form, Dropdown } from "react-bootstrap";
import Input from "../../../components/UI/Input";
import { Tabs, Tab } from 'react-bootstrap';
import './style.scss';
import FormDet from "../ProgramData/formDetail";
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgram } from "../../../actions/program.actions";
import moment from 'moment';
import { useTranslation } from "react-i18next";

function ProgramData() {
  const { t } = useTranslation();
  const [search, setSearch] = useState('')
  const [jenis, setJenis] = useState('')
  const [status, setStatus] = useState('')
  const [activeTab, setActiveTab] = useState('all');
  const [selectedProgram, setSelectedProgram] = useState({})
  const [showFormDetail, setShowFormDetail] = useState(false);
  const program = useSelector(state => state.program)
  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const j_program = [{
    value:'',
    name: t('select_program_type'),
    disabled:true,
    selected:true
  },{
    value:'all',
    name: t('all')
  },{
    value:'1',
    name: t('loan_funding')
  },
  {
    value:'2',
    name:t('grant_funding')
  },
  {
    value:'3',
    name: t('competition_funding')
  },
  {
    value:'4',
    name: t('event')
  },
  {
    value:'5',
    name: t('trainig')
  },
  {
    value:'6',
    name: t('procurement')
  }]
  
  const s_program = [{
    value:'',
    name: t('select_program_status'),
    disabled:true
  },{
    value:'all',
    name: t('all'),
    selected:true
  },
  {
    value:'draft',
    name: t('draft')
  },
  {
    value:'scheduled',
    name: t('scheduled')
  },
  {
    value:'progress',
    name: t('on_progress')
  },
  {
    value:'complete',
    name: t('done')
  },
  {
    value:'suspend',
    name: t('on_hold')
  }]
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = program?.program?.map((i, index) => {
    return {
      col1: (((page - 1) * perPage) + (index + 1)).toString(),
      col2: i.program_name,
      col3: i?.program_owners[0]?.instansi?.name,
      col4: moment(i.program_start).format('DD-MM-YYYY HH:mm')+' WIB',
      col5: moment(i.program_end).format('DD-MM-YYYY HH:mm')+' WIB',
      col6: rendStatus(i.status),
      col7: i.participant_quota,
      col8: i.program_quota,
      col9: i.id,
      col10: i.program_category?.name
    };
  });

  function rendStatus(val){
    switch (val) {
      case 'suspend':
        return 'Ditangguhkan'
      break;    
      case 'complete':
        return 'Selesai'
      break;  
      default:
        return val.charAt(0).toUpperCase() + val.slice(1);
      break;
    }
  }

  function setType(val){
    switch (val) {
      case 'pinjaman':
        return t('loan_funding')
      break;    
      case 'hibah':
        return t('grant_funding')
      break;    
      case 'kompetisi':
        return t('competition_funding')
      break;    
      case 'pameran':
        return t('event')
      break;    
      case 'pelatihan':
        return t('trainig')
      break;    
      case 'pengadaan':
        return t('procurement')
      break;    
    }
  }
  
  const data = React.useMemo(() => list, [program?.program]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: 'col1',
        width: '5%',
        textAlign: 'center'
      },
      {
        Header: t('program_name'),
        accessor: 'col2'
      },
      {
        Header: t('program_owner'),
        accessor: 'col3',
      },
      {
        Header: t('program_type'),
        accessor: 'col10',
      },
      {
        Header: t('start_type'),
        accessor: 'col4',
        width: '10%',
        textAlign: 'center'
      },
      {
        Header: t('end_time'),
        accessor: 'col5',
        width: '10%',
        textAlign: 'center'
      },
      {
        Header: t("status"),
        accessor: 'col6',
        width: '8%',
        textAlign: 'center',
        className:'capitalize'
      },
      {
        Header: t("quota"),
        accessor: 'col7',
        width: '8%',
        textAlign: 'center'
      },
      {
        Header: t("number_of_participants"),
        accessor: 'col8',
        width: '8%',
        textAlign: 'center'
      },
      {
        Header: t("details"),
        accessor: 'col9',
        Cell: (item) => (                 
          <div className="btn-setting">
            <Dropdown className="dpw-setting">
              <Dropdown.Toggle variant="setting" id="dropdown-basic">
                <FaCogs className="me-1" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#" onClick={() => {
                  handleFormDetail(item.cell.value,item.customState.listProgram,'view')
                }}>
                  <FaEye />
                  {t("details")}
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={() =>{
                }}>
                  <FaTrashAlt />
                  {t('delete')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        width: '5%',
        textAlign: 'center'
      }
    ],
    [program,t]
  );

  useEffect(() => {
    let parm = {
      status:status,
      is_owner:0,
      jenis:jenis,
      search:search
    }
    dispatch(getProgram(page, perPage, parm))
  }, [page, perPage, status, jenis, search])

  const handleFormDetail = (id,data,state) => { 
    const data_program = data.find(item => item.id === id);
    setSelectedProgram(data_program);
    switch (state) {
      case 'view':
        setShowFormDetail(true);
      break;
    }
  }

  return (
    <>
    <Layout>
    <div className="row table-title">
      <span className="title">{t('collaboration_program')}</span>
      <span className="desk">Program {'>'} {t('collaboration_program')}</span>
    </div>
    <div className="row table-view">
      <div className="col">
        <div className="col-wrapper">
          <div className="text-start">
            <div className="search-wrapper src-wrap">
              <input id="search-input" placeholder={t('search_program')}type="text" onChange={(e) => setSearch(e.target.value)}/>
              <button className="btn btn-sm btn-outline-secondary" onClick={() => setSearch(search)}>
                <FaSearch className="me-1" />
              </button>                  
            </div>
            <Input
              type="select"
              classGroupName="filter_jenis"
              label=""
              name="jenis"
              options={j_program}
              onChange={(e) => {
                setJenis(e.target.value);
              }}
            />
            <Input
              type="select"
              label=""
              name="status"
              options={s_program}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />
          </div>
        </div>
        <DataTable
          data={data}
          columns={columns}
          loading={program.loading}
          customState={{listProgram:program?.program??[]}}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={program?.meta?.last_page}
        />
      </div>
    </div>      
    </Layout>
    <FormDet 
      isShow={showFormDetail}
      data={selectedProgram}
      handleClose={() => setShowFormDetail(false)}
    />
    </>
  );
}

export default ProgramData;