import { forgetContants } from "./constants";
import axios from "../helpers/axios_login";

export const forget = (user) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: forgetContants.FORGET_REQUEST });
        const res = await axios.get(`/reqForgotPassword/${user.phone}?role_id=9`);
        console.log('res',res);
        if(res.data.status === 'success'){        
            resolve(res?.data?.result?.user?.id)
            dispatch({
                type: forgetContants.FORGET_SUCCESS,
            });
        }else{
            rejected(res.data.message.custom[0])
            if(res.status === 400){
                dispatch({
                    type: forgetContants.FORGET_FAILURE,
                    payload: { error: res.data.error }
                });
            }
        }
    })
}

export const verificationOTP = (user) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: forgetContants.FORGET_REQUEST });
        const res = await axios.post(`/verificationOTP/${user.userId}`,{
            otp: user.OTP
        });

        if(res.data.status === 'success'){        
            resolve(true)
            dispatch({
                type: forgetContants.FORGET_SUCCESS,
            });
        }else{
            rejected(res.data.message.custom[0])
            if(res.status === 400){
                dispatch({
                    type: forgetContants.FORGET_FAILURE,
                    payload: { error: res.data.error }
                });
            }
        }
    })
}


export const resetPassword = (user) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: forgetContants.FORGET_REQUEST });
        const res = await axios.post(`/forgotPassword/${user.phone}`,{
            otp:user.OTP,
            password:user.password,
            password_confirmation:user.password_confirmation
        });

        if(res.data.status === 'success'){        
            resolve(true)
            dispatch({
                type: forgetContants.FORGET_SUCCESS,
            });
        }else{
            rejected(res.data.message.password[0])
            if(res.status === 400){
                dispatch({
                    type: forgetContants.FORGET_FAILURE,
                    payload: { error: res.data.error }
                });
            }
        }
    })
}

