import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Input from "../../../components/UI/Input";
import Modal from "../../../components/UI/Modal";
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import Swal from "sweetalert2";
import "./style.scss"
import { useTranslation } from "react-i18next";

const UpdKelas = ({ isShow, handleClose, handleSave, selectedData, viewState }) => {
  const { t } = useTranslation();
  const [slcKelas, setSlcKelas] = useState([{slc:false,id:1,name:'Mikro'},{slc:false,id:2,name:'Kecil'},{slc:false,id:3,name:'Menengah'}]) 
  const [count, setCount] = useState(0);

  const onSave = () => {
    Swal.fire({
        title: '',
        text: t('are_you_sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: t('save'),
        cancelButtonColor: '#d33',
        cancelButtonText: t('re_edit'),
    
      }).then((result) => {
        if (result.isConfirmed) {
            var data = [];
            slcKelas.map((val,index) => {
                if(val.slc){
                  data.push(slcKelas[index])
                }
            })
            handleSave(data)
        }
      })
  }
  
  useEffect(() => { //state Editing
    if(selectedData?.length>0){
      let kelas_def = [{slc:false,id:1,name:'Mikro'},{slc:false,id:2,name:'Kecil'},{slc:false,id:3,name:'Menengah'}];//default value
      selectedData.map((val,i) => {
        let slcKey = kelas_def.findIndex(item => item.id === val.id)
        kelas_def[slcKey].slc = true
      })
      setSlcKelas(kelas_def);
      setCount(count+1);
    }
  }, [selectedData])

  function handleSlc(key,val){
    //mengubah selected area
    var stateCopy = slcKelas;
    if(val){
      stateCopy[key].slc= val;
    } else {
      stateCopy[key].slc= slcKelas[key].slc?false:true;
    }
    setSlcKelas(stateCopy);
    setCount(count+1);
  }

  return(
    <>
      <Modal modalTitle="Pilih Kelas" show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col-md-12 col-kelas">
            <label className="check-kelas">
              <input
                  type="checkbox"
                  disabled={viewState??false}
                  checked={slcKelas[0].slc}
                  value={slcKelas[0].id}
                  onChange={(e) => {
                    handleSlc(0)
                  }}
              />
              {slcKelas[0].name}
            </label>
            <label className="check-kelas">
              <input
                  type="checkbox"
                  disabled={viewState??false}
                  checked={slcKelas[1].slc}
                  value={slcKelas[1].id}
                  onChange={(e) => {
                    handleSlc(1)
                  }}
              />
              {slcKelas[1].name}
            </label>
            <label className="check-kelas">
              <input
                  type="checkbox"
                  disabled={viewState??false}
                  checked={slcKelas[2].slc}
                  value={slcKelas[2].id}
                  onChange={(e) => {
                    handleSlc(2)
                  }}
              />
              {slcKelas[2].name}
            </label>
          </div>
          <div className="col">
            <div className="float-right">
              {!viewState?
                <>
                <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
                <button className="btn btn-sm button-green" onClick={() => onSave()}>{t('save')}</button>
                </>
                :
                <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
              }      
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdKelas