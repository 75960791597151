/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function SuccessRate(props) {
  const { t } = useTranslation();
  const data_monitoring = useSelector(state => state.dashboard.data_monitoring)
  var dataSet = [];

  function renderName(params) {
    if(params === 'not_working'){
      return 'Tidak Berjalan'
    } else if(params === 'problem'){
      return 'Bermasalah'
    } else if(params === 'success'){
      return 'Sukses'
    } else {
      return '-'
    }
  }
  data_monitoring?.level_of_success?.data?.map((val,index) => {
    dataSet.push({ value: val, name: renderName(data_monitoring?.level_of_success?.label[index]) })
  })
  let bgColor = "bg-" + props.color;
  function getOption(){
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      color: ["#FCB630", "#f8b425", "#c603fc", "#ec4561", "#5902a6"],
      series: [
        {
          name: t('total_msme'),
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: dataSet,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  
  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
          </div>
          <div className="card-content echart-bidang">
            <ReactEcharts style={{ height: "300px" }} option={getOption()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessRate;