import React from 'react';
import { FaStore } from 'react-icons/fa'
import { useSelector } from 'react-redux';

function StatCard(props) {
  const data_program = useSelector(state => state.dashboard.data_program)
  
  return (
    <div className="col-md-12">
      <div className="row">
        {data_program?.programCategory?.map((val) => (
          <div className="col-md-2">
            <div className="card card-hg card-hg-wt mb-3 border-0 rounded-3 shadow">
              <div className="hg-desk">
                <span>{val.name}</span>
              </div>
              <span className="hg-title">{data_program?.dataByCategory.find(item => item.id === val.id)?.value??0}</span>
              <span className="hg-foot">UMKM </span>
            </div>
          </div>
        ))
        }
      </div>
    </div>
  );
}

export default StatCard;
