import { fltwilayahConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getProvince = () => {
    return async (dispatch) => {
        dispatch({ type: fltwilayahConstants.FETCH_FLTPROVINCE_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}provinces?no_paginate=1`)

            if (res.data.status == "success") {
                const province = res.data.result.data
                dispatch({
                    type: fltwilayahConstants.FETCH_FLTPROVINCE_SUCCESS,
                    payload: { province }
                })
            }
        } catch (err) {
            dispatch({
                type: fltwilayahConstants.FETCH_FLTPROVINCE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getCity = (parent) => {
    return async (dispatch) => {
        dispatch({ type: fltwilayahConstants.FETCH_FLTCITY_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}cities/showbyparent/${parent}`)
            if (res.data.status == "success") {
                const city = res.data.result
                dispatch({
                    type: fltwilayahConstants.FETCH_FLTCITY_SUCCESS,
                    payload: { city }
                })
            }
        } catch (err) {
            dispatch({
                type: fltwilayahConstants.FETCH_FLTCITY_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getDistrict = (parent) => {
    return async (dispatch) => {
        dispatch({ type: fltwilayahConstants.FETCH_FLTDISTRICT_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}districts/showbyparent/${parent}`)
            if (res.data.status == "success") {
                const district = res.data.result
                dispatch({
                    type: fltwilayahConstants.FETCH_FLTDISTRICT_SUCCESS,
                    payload: { district }
                })
            }
        } catch (err) {
            dispatch({
                type: fltwilayahConstants.FETCH_FLTDISTRICT_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getSubDistrict = (parent) => {
    return async (dispatch) => {
        dispatch({ type: fltwilayahConstants.FETCH_FLTSUBDISTRICT_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}subdistricts/showbymulti/get?byDistrict=${parent}`)
            if (res.data.status == "success") {
                const sub_district = res.data.result
                dispatch({
                    type: fltwilayahConstants.FETCH_FLTSUBDISTRICT_SUCCESS,
                    payload: { sub_district }
                })
            }
        } catch (err) {
            dispatch({
                type: fltwilayahConstants.FETCH_FLTSUBDISTRICT_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}