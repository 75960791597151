import React from 'react';
import { Form } from 'react-bootstrap';
import "./style.scss";

/**
* @author
* @function Switch
**/

const Switch = (props) => {
  let input = null;
  input = <Form.Group>
                {props.label && <Form.Label>{props.label}</Form.Label>}
                <label className="switch">
                    {!props.alwaysOn?
                    <input type="checkbox" name={props.name} checked={props.value} value={props.value} onChange={props.onChange} />
                    :
                    <input type="checkbox" name={props.name} checked={props.value} value={props.value} />
                    }
                    <div className="slider"></div>
                </label>
            </Form.Group>

  return input;
}

export default Switch