/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCategory_Industry } from "../../../actions/category_industry.actions";
import "./style.scss"
import { FaChevronDown, FaChevronRight, FaRegCheckSquare, FaRegMinusSquare, FaRegSquare } from 'react-icons/fa'
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const JenisIndustri = (props) => {
    const { t } = useTranslation();
    const [dataIndusries, setDataIndusries] = useState([]) 
    const [isOpen, setIsOpen] = useState([]) 
    const [count, setCount] = useState(0) 
    const dispatch = useDispatch()
    const data_res = useSelector(state => state.category_industry)

    useEffect(() => {
        dispatch(getCategory_Industry())
    }, [])

    useEffect(() => { //state Editing
        let slcInd = []
        if(props.selectedData?.length>0){
            props.selectedData.map((val)=>{
                slcInd.push(val.id)
            })
        }
        setDataIndusries(slcInd);
        console.log('ind',props.selectedData);
    }, [props.selectedData])

    function isCheckedHead(params) {
        if(dataIndusries.find(element => element === params.id)){
            var cnt = 0;
            params.children.map((val)=>{
                if(dataIndusries.find(element => element === val.id)){
                    cnt++
                }
            })
            if(cnt < params.children.length){
                return <FaRegMinusSquare size={25}/>
            }else{
                return <FaRegCheckSquare size={25}/>
            }
        }else{
            return <FaRegSquare size={25}/> 
        }
    }

    function isCheckedBody(params) {
        if(dataIndusries.find(element => element === params)){
            return <FaRegCheckSquare size={25}/>
        }else{
            return <FaRegSquare size={25}/> 
        }
    }

    function renderArrow(params) {
        return isOpen.find(element => element === params) ? <FaChevronDown size={15}/> : <FaChevronRight size={15}/> 
    }

    function setOpenArrow(params) {
        var d = isOpen;
        const found2 = d.find(element => element === params);
        if(!found2){
            d.push(params)
            setIsOpen(d)
            setCount(count+1)
        }else{
            if(d.indexOf(params) === 0){
                d.shift();
                setIsOpen(d)
                setCount(count+1)
            }else{
                d.splice(d.indexOf(params), 1);
                setIsOpen(d)
                setCount(count+1)
            }
        }
    }

    function setCheckedHead(params,params2 = true){
        var d = dataIndusries;
        const found = d.find(element => element === params.id);
        if(!found){
            d.push(params.id)
            if(params2){
                params?.children?.map((val2,index2) => {
                    const found2 = d.find(element => element === val2.id);
                    if(!found2){
                        d.push(val2.id)
                    }
                })
            }
            setDataIndusries(d)
            setCount(count+1)
        }else{
            if(d.indexOf(params.id) === 0){
                d.shift();
                if(params2){
                    params?.children?.map((val2,index2) => {
                        const found2 = d.find(element => element === val2.id);
                        if(found2){
                            d.splice(d.indexOf(val2.id), 1);
                        }
                    })
                }
                setDataIndusries(d)
                setCount(count+1)
            }else{
                d.splice(d.indexOf(params.id), 1);
                if(params2){
                    params?.children?.map((val2,index2) => {
                        const found2 = d.find(element => element === val2.id);
                        if(found2){
                            d.splice(d.indexOf(val2.id), 1);
                        }
                    })
                }
                setDataIndusries(d)
                setCount(count+1)
            }
        }
    }

    function setCheckedBody(params,params2){
        var d = dataIndusries;
        const found2 = d.find(element => element === params);
        if(!found2){
            d.push(params)
            setDataIndusries(d)
            setCount(count+1)

            if(!dataIndusries.find(element => element === params2.id)){
                setCheckedHead(params2,false)
            }

        }else{
            if(d.indexOf(params) === 0){
                d.shift();
                setDataIndusries(d)
                setCount(count+1)
            }else{
                d.splice(d.indexOf(params), 1);
                setDataIndusries(d)
                setCount(count+1)

            }
            if(dataIndusries.find(element => element === params2.id)){
                var cnt = 0;
                params2.children.map((val)=>{
                    if(dataIndusries.find(element => element === val.id)){
                        cnt++
                    }
                })
                if(cnt === 0){
                    setCheckedHead(params2)
                }
            }
        }
    }

    function renderChild(params,index) {
        if(isOpen.find(element => element === index)){
            return params?.children?.map((val2,index2) => {
                return (
                    <div>
                        <button
                            className="btn btn-check"
                            style={{marginLeft:80}}
                            onClick={()=>{!props.viewState && setCheckedBody(val2.id,params)}}
                        >
                            {isCheckedBody(val2.id)}
                            <span style={{marginLeft:15, fontSize:12, marginBottom:5}}>{val2.category}</span>
                        </button>
                    </div>
                );
            })
        }
    }
    

    return(
        <>
        <Modal 
            className="modal-me"
            size="lg"
            show={props.isShow}
            onHide={()=> {
                if(!props.viewState){
                    if(dataIndusries.length > 0){
                        Swal.fire({
                            title: '',
                            text: "Apakah anda ingin mereset seluruh data atau tetap menyimpan data yang ada?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: t('save'),
                            cancelButtonColor: '#d33',
                            cancelButtonText: 'Reset',
                        
                        }).then((result) => {
                            if (result.isConfirmed) {
                                props.handleSave(dataIndusries)
                                setIsOpen([])
                            } else {
                                props.handleSave([])
                                setDataIndusries([])
                                setIsOpen([])
                            }
                        })
                    }else{
                        props.onClose()
                        setIsOpen([])
                    }
                } else {
                    props.onClose()
                }
            }}
        >
        <Modal.Header
            style={{
                marginLeft:40,
                marginRight:10,
                marginTop:20,
            }}  
            closeButton
        > 
            <Modal.Title>Filter berdasarkan jenis industri</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row category-industries">
                <div style={{flexDirection:'column',height:580,width:'100%',overflow:'scroll', padding: '10px'}}>
                    {
                        data_res?.category_industry?.map((val,index) => {
                            return (
                                <div>
                                    <button className="btn btn-check" onClick={()=>{setOpenArrow(index+1)}}>
                                        {renderArrow(index+1)}
                                    </button>
                                    <button className="btn btn-check" onClick={()=>{!props.viewState && setCheckedHead(val)}}>
                                        {isCheckedHead(val)}
                                        <span style={{marginLeft:15, fontSize:12, marginBottom:5}}>{val.category}</span>
                                    </button>
                                    <div style={{marginTop:20,marginBottom:20}}>
                                        {renderChild(val,index+1)}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="row">
            <div className="col">  
                <div className="float-right btn-ctrl-form">
                {!props.viewState?
                    <>
                        <button className="btn btn btn-batal" onClick={() => {
                            props.handleSave([])
                            setDataIndusries([])
                            setIsOpen([])
                        }}>Reset</button>
                        <button className="btn btn btn-simpan" onClick={() => {
                            props.handleSave(dataIndusries)
                            setIsOpen([])
                        }}>{t('save')}</button>
                    </>
                :
                    <button className="btn btn-sm button-secondary mr-2" onClick={() => props.handleClose()}>{t('closed')}</button>
                }
                </div>
            </div>
        </div> 
        </Modal.Body>
        </Modal>
        </>
    )
}

export default JenisIndustri