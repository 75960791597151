import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaStore, FaSearch, FaMapMarkerAlt } from 'react-icons/fa'
import { Modal, Form } from "react-bootstrap";
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getBusinessmans } from "../../../actions/businessman.actions";
import { getDataRecapTrainersCount, getDataRecapUMKM } from "../../../actions/dashboard.actions";
import ImgEmptyUmkm from "../../../assets/empty_umkm.png";
import { useTranslation } from "react-i18next";

function Umkm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [check, setChecked] = useState(true);
  const handleClose = () => setShow(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const [selectedData, setSelectedData] = useState(undefined);
  const businessmans = useSelector(
    (state) => state.businessman?.businessmans.data
  );
  const meta = useSelector((state) => state.businessman?.businessmans.meta);
  const isLoading = useSelector((state) => state.businessman?.loading);
  const _totalUmkm = useSelector((state) => state.dashboard.total_umkm);
  const user = JSON.parse(localStorage.getItem("user"));
  const instanceId = user.user_agency.id;

  useEffect(() => {
    // console.log('businessmans',businessmans);
  }, [businessmans]);
  useEffect(() => {
    dispatch(getDataRecapTrainersCount(instanceId));
    dispatch(getDataRecapUMKM(instanceId));
  }, []);

  useEffect(() => {
    dispatch(getBusinessmans(page, perPage, search));
  }, [page, perPage]);

  const list = businessmans?.map((i, index) => {
    return {
      col1: ((page - 1) * perPage + (index + 1)).toString(),
      col2: i.business_name,
      col3: i.owner_name,
      col4: i.business_wa_number,
      col5: i.level,
      col6: "-",
      col7: i.status_disabled == 0 ? "Aktif" : "Tidak Aktif",
    };
  });
  const data = React.useMemo(() => list, [businessmans]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: t("business_name"),
        accessor: "col2", // accessor is the "key" in the data
      },
      {
        Header: t("owners_name"),
        accessor: "col3",
      },
      {
        Header: t("whatsapp_contact"),
        accessor: "col4",
      },
      {
        Header: t("class"),
        accessor: "col5",
      },
      {
        Header: t("value"),
        accessor: "col6",
      },
      {
        Header: t("status"),
        accessor: "col7",
      },
      {
        Header: t("details"),
        accessor: "col8",
        Cell: ({ cell }) => (
          <span
            onClick={() => {
              setSelectedData(businessmans[cell.row.index]);
              setShow(true);
            }}
            className="span-rincian"
          >
            Lihat
          </span>
        ),
      },
    ],
    [businessmans,t]
  );

  return (
    <Layout>
      <div className="row table-title">
        <span className="title">UMKM Non Binaan</span>
        <span className="desk">Data UMKM {">"} UMKM Non Binaan</span>
      </div>
      <div className="row table-view">
        <div className="col">
          <div className="col-wrapper col-info">
            <div className="text-start">
              <div className="search-wrapper">
                <input
                  id="search-input"
                  placeholder={t('search_umkm')}
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() =>
                    dispatch(getBusinessmans(page, perPage, search))
                  }
                >
                  <FaSearch className="me-1" />
                </button>
              </div>

              <span className="span-text-umkm">
                {" "}
                {t('total_msme')} : {_totalUmkm?.umkm_nonbinaan}
              </span>
            </div>
          </div>

          <DataTable
            data={data}
            columns={columns}
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={meta.current_page}
            perPage={meta.per_page}
            totalPage={meta.last_page}
            loading={isLoading}
          />
        </div>
      </div>
      <Modal className="modal-back" show={show} onHide={handleClose}>
        <div className="row">
          <div className="col">
            <div className="col-header">
              <div className="col-detail">
                <div className="row">
                  <div className="col-md-2 col-detail-icon">
                    {selectedData?.business_logo_url == null ? (
                      <span className="span-icon">
                        <FaStore className="me-1" />
                      </span>
                    ) : (
                      <img src={selectedData?.business_logo_url} />
                    )}
                  </div>
                  <div className="col-md-10 col-detail-toko">
                    <span className="span-tittle">
                      {selectedData?.business_name}
                    </span>
                    <div className="text-xs text-white taddr mb-2">
                      <FaMapMarkerAlt />
                      {selectedData?.latitude !== null ? (
                        <>
                          <a
                            href={`https://maps.google.com/maps?q=${selectedData?.latitude},${selectedData?.longitude}&hl=es&z=14&amp;output=embed`}
                            target="_blank"
                          >
                            {selectedData?.business_address
                              ? selectedData.business_address
                              : ""}
                          </a>
                        </>
                      ) : selectedData?.business_address ? (
                        selectedData.business_address
                      ) : (
                        ""
                      )}
                    </div>
                    <span className="span-status">
                      {selectedData?.status_disabled == 0
                        ? "Aktif"
                        : "Tidak Aktif"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-content">
              <div className="col-data">
                <span className="span-tittle">{t('industrial_sector')}</span>
                <span>{selectedData?.category_industry?.category}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('type_of_business')}</span>
                <span>{selectedData?.type_business}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('number_of_employees')}</span>
                <span>{selectedData?.number_of_employees}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('type_legal_entity')}</span>
                <span>{selectedData?.business_ownership}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('last_login')}</span>
                <span>-</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('last_change')}</span>
                <span>-</span>
              </div>
              <div className="col-data-sw">
                <span className="span-tittle">{t('show_on_display')}</span>
                <Form.Check
                  checked={check}
                  type="switch"
                  id="custom-switch"
                  onClick={() => setChecked(check ? false : true)}
                />
              </div>
            </div>

            <div className="col-footer">
              <span onClick={() => setShow(false)}>{t('closed')}</span>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

export default Umkm;
