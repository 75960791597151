/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaArrowLeft, FaUserTie, FaSearch, FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import './table.css';
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgramParticipant, updateBatchProgram } from "../../../actions/program.actions";
import Nav from 'react-bootstrap/Nav'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Swal from "sweetalert2";
import FormUmkm from "../../DataUmkm/Umkm/formDetail";
import MdlDesk from "./mdlDesk";

import { FaCaretDown, FaChevronDown, FaChevronRight, FaRegCheckSquare, FaRegMinusSquare, FaRegSquare } from 'react-icons/fa'

import { Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const Detail = ({ program_id, handleBack}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState(false);
  const [showFormUmkm, setShowFormUmkm] = useState(false);
  const [showMdlDesk, setShowMdlDesk] = useState(false);
  const [mdlState, setMdlState] = useState('');
  const [selectedUmkm, setSelectedUmkm] = useState([]);
  const [status, setStatus] = useState('');
  const [selectedCoach, setSelectedCoach] = useState({})
  const loading = useSelector(state => state.program.loading)
  const ProgramParticipant = useSelector(state => state.program.ProgramParticipant)
  const [dataSelected, setDataSelected] = useState([]) 

  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [count, setCount] = useState(0)

  function isChecked() {
    const params =  ProgramParticipant.data;
    var cnt = 0;
    params.map((val)=>{
      if(dataSelected.find(element => element === val.id)){
        cnt++
      }
    })
    if(dataSelected.length === 0){
      return <FaRegSquare size={16} onClick={()=>{
        params.map((val)=>{
          setChecked(val.id, 'add')
        })
      }}/> 
    } else if( dataSelected.length > 0 && cnt < params.length ){
      return <FaRegMinusSquare size={16} onClick={()=>{
        params.map((val)=>{
          setChecked(val.id, 'remove')
        })
      }}/>
    }  else{
      return <FaRegCheckSquare size={16} onClick={()=>{
        params.map((val)=>{
          setChecked(val.id, 'remove')
        })
      }}/>
    }
  }

  function isCheckedBody(value) {
    if(dataSelected.find(element => element === value)){
      return <FaRegCheckSquare size={16} onClick={()=>{
        setChecked(value, 'remove')
      }}/>
    }else{
      return <FaRegSquare size={16} onClick={()=>{
        setChecked(value, 'add')
      }}/> 
    }
  }

  function setChecked(params, type){
    var d =  dataSelected;
    if(type === 'add'){
          d.push(params)
          setDataSelected(d)
    }else{
        if(d.indexOf(params) === 0){
          d.shift();
          setDataSelected(d)
        }else{
          d.splice(d.indexOf(params), 1);
          setDataSelected(d)
        }
    }
    setCount(Math.random())
  }

  function updateBatch(stat,desk) {
    let payload = [];
    dataSelected.map((val)=>{
      payload.push({
        'id' : val,
        'note' : stat==="selected"?"Lolos seleksi OKR, lolos verifikasi instansi penyedia program":"Tidak lolos quota penerima manfaat",
        'note_description' : desk,
        'status' : stat
      })
    })
    
    dispatch(updateBatchProgram({
      "program_participans":payload
    })).then((res) => {
      dispatch(getProgramParticipant(program_id ,page, perPage, search, status))
      setDataSelected([])
    })
    .catch((err) => {
      Swal.fire({
        text: err,
        icon: 'error',
        confirmButtonText: t('closed')
      })
    })
  }

  function showModal(stat) {
    if (dataSelected.length>0) {
      setMdlState(stat)
      setShowMdlDesk(true)
    } else {
        Swal.fire({
          text: t('selected_participants'),
          icon: 'error',
          confirmButtonText: t('closed')
        })
    }
  }

  function generateStatus(status) {
    if(status === 'participant'){
      return (
        <text style={{color:'#828282'}}>Peserta</text>
      );
    } else if(status === 'selection'){
      return (
        <text style={{color:'#F28900'}}>Lolos seleksi OKR</text>
      );
    } else if(status === 'selected'){
      return (
        <text style={{color:'#FCB630'}}>Terverifikasi</text>
      )
    } else if(status === 'not_pass'){
      return (
        <text style={{color:'#F1334D'}}>Tidak Terverifikasi</text>
      );
    } else {
      return status;
    }
  }

  const list = ProgramParticipant?.data.map((i, index) => {
    return {
      col0: i?.id,
      col1: (((page - 1) * perPage) + (index + 1)).toString(),
      col2: i?.businessman?.business_name ?? '-',
      col3: i?.businessman?.okr_level?.level_name ?? '-',
      col4: i?.businessman?.category_industry?.category ?? '-',
      col5: i?.progress ?? 0,
      col6: i?.status ?? '-',
      col7: i?.note ?? '-',
      col8: i?.id,
    };
  });
  
  const data = React.useMemo(() => list, [ProgramParticipant?.data]);


  const columnsChecked = React.useMemo(
    () => [
      {
        accessor: 'col0',
        Header: (item) => (                 
          <div style={{flexDirection:'row',alignItems:'center',display:'flex', width:80,height:17}}>
            <div onClick={() => {}}>
              {
                isChecked()
              }
            </div>
              <Dropdown>
                <Dropdown.Toggle variant="transparent" size="sx">
                  <FaCaretDown size={20} className="span-caret-down"/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="area-menu-dropdown">
                  <Dropdown.Item  className="area-menu-dropdown" onClick={()=>{
                    showModal('check')
                  }}>
                    <FaCheckCircle className="check"/>
                    <text style={{color:"black", marginLeft:5}}>{t('program_verification')}</text>
                  </Dropdown.Item>
                  <Dropdown.Item className="area-menu-dropdown" onClick={()=>{
                    showModal('cross')
                  }}>
                    <FaTimesCircle className="cross"/>
                    <text style={{color:"black", marginLeft:5}}>{t('unverified_Program')}</text>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
        ),
        Cell: (item ) => (                 
          <div style={{flexDirection:'column',alignItems:'center',display:'flex', width:80,height:17}}>
            {
              isCheckedBody(item.cell.value)
            }
          </div>
        ),
        textAlign: 'center'
      },
      {
        Header: t("number"),
        accessor: 'col1',
        width: '5%',
        textAlign: 'center'
      },
      {
        Header: t("msme_name"),
        accessor: 'col2',
        width: '10%',
        Cell: ({row: { original }}) => (     
          <div className="td-usaha">                
              <span className={`span-icon-${original.col5}`}>
                  <FaUserTie className="me-1" />
              </span>                           
              <span className="span-text">
                  {original.col2}
              </span>
          </div>
        )
      },
      {
        Header: t("msme_class"),
        accessor: 'col3',
        width: '10%',
      },
      {
        Header: t("industrial_sector"),
        accessor: 'col4',
        width: '15%',
      },
      {
        Header: t('progress'),
        accessor: 'col5',
        Cell: ({cell}) => (                 
          <div>
            <ProgressBar now={cell.value} />
            <text>{cell.value}%</text>
          </div>
        ),
        width: '30%',
        textAlign: 'center'
      },
      {
        Header: t("status"),
        accessor: 'col6',
        width: '15%',
        Cell: ({cell}) => (                 
          generateStatus(cell.value)
        ),
      },
      {
        Header: t("notes"),
        accessor: 'col7',
        width: '15%',
      },
      {
        Header: t("details"),
        accessor: 'col8',
        Cell: (item) => (                 
          <span onClick={() => {
            setSelectedUmkm(item.customState.listumkm.find(val => val.id === item.cell.value)?.businessman);
            setShowFormUmkm(true);
            }} className="span-rincian">
              {t("details")}
          </span>
        ),
        width: '10%',
        textAlign: 'center'
      }
    ],
    [ProgramParticipant?.data]
  );

  
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: 'col1',
        width: '5%',
        textAlign: 'center'
      },
      {
        Header: t("msme_name"),
        accessor: 'col2',
        width: '10%',
        Cell: ({row: { original }}) => (     
          <div className="td-usaha">                
              <span className={`span-icon-${original.col5}`}>
                  <FaUserTie className="me-1" />
              </span>                           
              <span className="span-text">
                  {original.col2}
              </span>
          </div>
        )
      },
      {
        Header: t("msme_class"),
        accessor: 'col3',
        width: '10%',
      },
      {
        Header: t("industrial_sector"),
        accessor: 'col4',
        width: '15%',
      },
      {
        Header: t('progress'),
        accessor: 'col5',
        Cell: ({cell}) => (                 
          <div>
            <ProgressBar now={cell.value} />
            <text>{cell.value}%</text>
          </div>
        ),
        width: '30%',
        textAlign: 'center'
      },
      {
        Header: t("status"),
        accessor: 'col6',
        width: '15%',
        Cell: ({cell}) => (                 
          generateStatus(cell.value)
        ),
      },
      {
        Header: t("notes"),
        accessor: 'col7',
        width: '15%',
      },
      {
        Header: t("details"),
        accessor: 'col8',
        Cell: (item) => (                 
          <span onClick={() => {
            setSelectedUmkm(item.customState.listumkm.find(val => val.id === item.cell.value)?.businessman);
            setShowFormUmkm(true);
            }} className="span-rincian">
              {t("details")}
          </span>
        ),
        width: '10%',
        textAlign: 'center'
      }
    ],
    [ProgramParticipant?.data,t]
  );

  useEffect(() => {
    dispatch(getProgramParticipant(program_id ,page, perPage, search, status))
  }, [program_id, page, perPage, search, status])


  const handleDetail = (selectedIndex) => { 
    setSelectedCoach(ProgramParticipant[selectedIndex])
    setDetail(true)
  }

  return (
    <>
      <div className="col">
        <div className="row">
          <div className="col-wrapper">
            <div className="text-start">              
              <button onClick={() => handleBack()} className="btn btn-sm btn-outline-secondary btn-back">
                <FaArrowLeft className="me-1" />
                kembali
              </button>  
            </div>
            <div className="text-end">
            </div>
          </div>
          
            <div className="col-wrapper">
              <div className="text-start">
                <div className="search-wrapper">
                  <input onChange={(e) => {
                    setSearch(e.target.value)
                  }} id="search-input" placeholder="Cari UMKM.." type="text" />
                  <button className="btn btn-sm btn-outline-secondary mt-1">
                    <FaSearch className="me-1" />
                  </button>                  
                </div>
              </div>
              <div className="text-end">
              </div>
            </div>
        </div>
        <div className="row mt-3 mb-3" style={{marginLeft:10}}>
          <div className="ml-3 mr-5">
            <h6 
              onClick={()=>{
                setStatus('')
              }}
              style={{
                color: status === '' ? '#FCB630' : '#333333',
                textUnderlinePosition: status === '' ? 'under' : 'unset',
                textDecorationLine:'underline',
                cursor: 'default'
                }}
                >
                  {t('all')}
                </h6>
          </div>
          
          <div className="mr-5">
            <h6 
              onClick={()=>{
                setStatus('selection')
              }}
              style={{
                color:status === 'selection' ? '#FCB630' : '#333333',
                textDecorationLine: status === 'selection' ? 'underline' : 'unset',
                cursor: 'default'
                }}
                >
                  Lolos seleksi OKR
                </h6>
          </div>
          
          <div className="mr-5">
            <h6 
              onClick={()=>{
                setStatus('not_pass')
              }}
              style={{
                color:status === 'not_pass' ? '#FCB630' : '#333333',
                textDecorationLine: status === 'not_pass' ? 'underline' : 'unset',
                cursor: 'default'
                }}
                >
                  Tidak lolos seleksi OKR
                </h6>
          </div>
          
          <div className="mr-5">
            <h6 
              onClick={()=>{
                setStatus('selected')
              }}
              style={{
                color:status === 'selected' ? '#FCB630' : '#333333',
                textDecorationLine: status === 'selected' ? 'underline' : 'unset',
                cursor: 'default'
                }}
                >
                  Terverifikasi Program
                </h6>
          </div>
        </div>
        <DataTable
          data={data}
          customState={{listumkm:ProgramParticipant?.data??[]}}
          columns={ status === 'selection' ? columnsChecked : columns}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={ProgramParticipant?.meta.last_page}
          loading={loading}
        />
      </div>  
      <FormUmkm 
        isShow={showFormUmkm}
        data={selectedUmkm}
        handleClose={() => setShowFormUmkm(false)}
      />
      <MdlDesk 
        isShow={showMdlDesk}
        handleSave={(val)=>{
            if (mdlState=='check') {
              updateBatch('selected',val)
            } else {
              updateBatch('not_pass',val)
            }
            setShowMdlDesk(false)
          }
        }
        state={mdlState}
        handleClose={() => setShowMdlDesk(false)}
      />
    </>
  );
}

export default Detail;
