import React, { useEffect } from 'react';
import Layout from '../../../components/Base/main/main';
import StatCard from "./StatCard";
import Bidangindustri from "./Bidangindustri";
import Badanhukum from "./Badanhukum";
import "./style.css"
import { useDispatch, useSelector } from 'react-redux';
import { 
  getStakeholderUMKM
} from '../../../actions/dashboard.actions';
import { useTranslation } from "react-i18next";

function Rekap() {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const instanceId = user.user_agency.id;
    dispatch(getStakeholderUMKM(instanceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      {user.user_agency.status === "approve" && <div className="row">
        <StatCard color="redap" />
        <Bidangindustri
          stat={t("industrial_sector")}
          label={""}
          color="white"
        />
        <Badanhukum
          stat={t("legal_entity")}
          label={""}
          color="white"
        />
      </div>}
    </Layout>
  );
}

export default Rekap;
