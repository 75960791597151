/* eslint-disable default-case */
import { fltOkrConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  levels: [],
  paket: [],
  kelompok: [],
  kategori: [],
  listokr: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case fltOkrConstants.FETCH_FLTOKRLEVEL_REQUEST:
      state = {
        ...state,
        loading: true,
        levels: []
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRLEVEL_SUCCESS:
      state = {
        ...state,
        loading: false,
        levels: action.payload.levels,
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRLEVEL_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRPAKET_REQUEST:
      state = {
        ...state,
        loading: true,
        paket: []
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRPAKET_SUCCESS:
      state = {
        ...state,
        loading: false,
        paket: action.payload.paket,
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRPAKET_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    case fltOkrConstants.FETCH_FLTOKRKELOMPOK_REQUEST:
      state = {
        ...state,
        loading: true,
        kelompok: []
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRKELOMPOK_SUCCESS:
      state = {
        ...state,
        loading: false,
        kelompok: action.payload.kelompok,
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRKELOMPOK_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    case fltOkrConstants.FETCH_FLTOKRKATEGORI_REQUEST:
      state = {
        ...state,
        loading: true,
        kategori: []
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRKATEGORI_SUCCESS:
      state = {
        ...state,
        loading: false,
        kategori: action.payload.kategori,
      }
      break;
    case fltOkrConstants.FETCH_FLTOKRKATEGORI_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    case fltOkrConstants.FETCH_FLTLISTOKR_REQUEST:
      state = {
        ...state,
        loading: true,
        listokr: []
      }
      break;
    case fltOkrConstants.FETCH_FLTLISTOKR_SUCCESS:
      state = {
        ...state,
        loading: false,
        listokr: action.payload.listokr,
      }
      break;
    case fltOkrConstants.FETCH_FLTLISTOKR_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
  }

  return state;
}