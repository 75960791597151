import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaCogs, FaPencilAlt, FaEye, FaTrashAlt, FaTrashRestoreAlt } from 'react-icons/fa'
import { Dropdown } from "react-bootstrap";
import { Tabs, Tab } from 'react-bootstrap';
import './style.scss';
import FormDetail from "./formDetail";
import FormAdd from "./formAdd";
import FormEdit from "./formEdit";
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgram, deleteProgram, restoreProgram } from "../../../actions/program.actions";
import { getData, deleteData } from "../../../actions/stakeholder.actions";
import moment from 'moment';
import {setStatus, setVerif} from "../fnc_var";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ProgramData() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("new");
  const [state, setState] = useState("add");
  const [selectedData, setSelectedData] = useState({});
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const stakeholder = useSelector(state => state.stakeholder)
  const loading_page = useSelector(state => state.stakeholder.loading)
  const deleteId = useSelector(state => state.stakeholder.deleteId)
  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()
  
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [showFormAdd, setShowFormAdd] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = stakeholder?.data?.map((i, index) => {
    return {
      col1: ((page - 1) * perPage + (index + 1)).toString(),
      col2: i.id_number,
      col3: i.business_name,
      col4: i.owner_name,
      col5: i.business_contact_number,
      col6: i.category ?? "-",
      col7: i.id,
    };
  });

  const data = React.useMemo(() => list, [stakeholder?.data, t]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: "col1",
        width: "5%",
        textAlign: "center",
      },
      {
        Header: t("id_number"),
        accessor: "col2",
        width: "15%",
      },
      {
        Header: t("business_name"),
        accessor: "col3",
      },
      {
        Header: t("owners_name"),
        accessor: "col4",
        width: "15%",
        textAlign: "center",
      },
      {
        Header: t("business_contact"),
        accessor: "col5",
        width: "15%",
        textAlign: "center",
      },
      {
        Header: t("business_category"),
        accessor: "col6",
        width: "15%",
        textAlign: "center",
        className: "capitalize",
      },
      {
        Header: t("action"),
        accessor: "col7",
        Cell: (item) => (
          <div className="btn-setting">
            <Dropdown className="dpw-setting">
              <Dropdown.Toggle variant="setting" id="dropdown-basic">
                <FaCogs className="me-1" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {item.customState.activeTab == "deleted" ? (
                  <Dropdown.Item
                    href="#"
                    onClick={() => {
                      handleRestore(item.cell.value);
                    }}
                  >
                    <FaTrashRestoreAlt />
                    {t("restore")}
                  </Dropdown.Item>
                ) : (
                  <>
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        handleFormDetail(
                          item.cell.value,
                          item.customState.listUmkm,
                          "edit"
                        );
                      }}
                    >
                      <FaPencilAlt />
                      {t("edit")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        handleFormDetail(
                          item.cell.value,
                          item.customState.listUmkm,
                          "view"
                        );
                      }}
                    >
                      <FaEye />
                      {t("detail")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        handleDelete(item.cell.value);
                      }}
                    >
                      <FaTrashAlt />
                      {t("delete")}
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        width: "5%",
        textAlign: "center",
      },
    ],
    [stakeholder, t]
  );

  useEffect(() => {
    let parm = {
      status:activeTab
    }
    dispatch(getData(page, perPage, parm))
  }, [page, perPage, activeTab, count, deleteId])

  const handleFormDetail = (id,data_umkm,state) => { 
    const _data = data_umkm.find(item => item.id === id);
    setSelectedData(_data)
    
    // eslint-disable-next-line default-case
    switch (state) {
      case 'add':
        setShowFormAdd(true);
        setState('add');
        setCount2(count2+1)
      break;
      case 'edit':
        setShowFormEdit(true);
        setState('edit');
      break;
      case 'view':
        setShowFormDetail(true);
      break;
    }
  }


  const handleDelete = (id) => { 
    Swal.fire({
      title: "",
      text: t("delete_confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: t("delete_confirmation_yes"),
      cancelButtonColor: "#d33",
      cancelButtonText: t("cancelled"),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteData(id));
      }
    }); 
  }

  const handleRestore = (id) => {
    Swal.fire({
      title: "",
      text: t("restore_confirmation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: t("restore_confirmation_yes"),
      cancelButtonText: t("cancelled"),
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(restoreProgram(id));
      }
    });
  };

  return (
    <>
      <Layout loading={loading_page}>
        <div className="row table-title">
          <span className="title">{t("businessmen_data")}</span>
          <span className="desk">
            {t("businessmen_data")} {">"} {t("data_recap")}
          </span>
        </div>
        <div className="row table-view">
          <div className="col">
            <button
              className="btn btn-import"
              onClick={() => {
                handleFormDetail(0, [], "add");
              }}
            >
              {t("add_data")}
            </button>
            <div className="col-tab-wrapper col-tab-mrgn">
              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-1"
              >
                <Tab eventKey="new" title={t("new_data")} />
                <Tab eventKey="approved" title={t("verified")} />
                {/* <Tab eventKey="linked" title={t("businessmen")} /> */}
                <Tab eventKey="failed" title={t("unverified")} />
              </Tabs>
            </div>
            <DataTable
              data={data}
              customState={{
                listUmkm: stakeholder?.data,
                activeTab: activeTab,
              }}
              columns={columns}
              loading={stakeholder.loading}
              setPage={setPage}
              setPerPage={setPerPage}
              currentpage={page}
              perPage={perPage}
              totalPage={stakeholder?.meta?.last_page}
            />
          </div>
        </div>
      </Layout>
      <FormDetail
        isShow={showFormDetail}
        data={selectedData}
        handleClose={() => setShowFormDetail(false)}
      />
      <FormAdd
        isShow={showFormAdd}
        data={count2}
        state={state}
        activeTab={activeTab}
        handleClose={() => {
          setShowFormAdd(false);
          setCount(count + 1);
        }}
      />
      <FormEdit
        isShow={showFormEdit}
        data={state === "add" ? [] : selectedData}
        state={state}
        activeTab={activeTab}
        handleClose={() => {
          setShowFormEdit(false);
          setCount(count + 1);
        }}
      />
    </>
  );
}

export default ProgramData;
