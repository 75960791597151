/* eslint-disable default-case */
import { dashboardConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  stakeholder_umkm:[]
}

export default (state = initState, action) => {
  switch (action.type) {
    case dashboardConstants.FETCH_DATA_DASHBOARD_REQUEST:
      state = {
        ...state,
        loading: true,
      }
      break;
    case dashboardConstants.FETCH_DATA_DASHBOARD_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_DASHBOARD_REQUEST:
      state = {
        ...state,
        data_recap:[],
        loading: true,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_DASHBOARD_SUCCESS:
      state = {
        ...state,
        data_recap: action.payload.data_recap,
        loading: false,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_DASHBOARD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_REQUEST:
      state = {
        ...state,
        data_recap_businessmans:[],
        loading: true,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_SUCCESS:
      state = {
        ...state,
        data_recap_businessmans: action.payload.data_recap_businessmans,
        loading: false,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_TC_DASHBOARD_REQUEST:
      state = {
        ...state,
        data_recap_trainers_count:[],
        loading: true,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_TC_DASHBOARD_SUCCESS:
      state = {
        ...state,
        data_recap_trainers_count: action.payload.data_recap_trainers_count,
        total_recap: action.payload.data_recap_trainers_count.level.total,
        loading: false,
      }
      break;
    case dashboardConstants.FETCH_DATA_REKAP_TC_DASHBOARD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
      break;
      case dashboardConstants.FETCH_DATA_REKAP_UMKM_DASHBOARD_REQUEST:
        state = {
          ...state,
          total_umkm:[],
          loading: true,
        }
        break;
      case dashboardConstants.FETCH_DATA_REKAP_UMKM_DASHBOARD_SUCCESS:
        state = {
          ...state,
          total_umkm: action.payload.total_umkm,
          loading: false,
        }
        break;
      case dashboardConstants.FETCH_DATA_REKAP_UMKM_DASHBOARD_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error,
        }
        break;
        case dashboardConstants.FETCH_DATA_MONITORING_DASHBOARD_SUCCESS:
          state = {
            ...state,
            data_monitoring: action.payload.data_monitoring,
            loading: false,
          }
          break;
        case dashboardConstants.FETCH_DATA_MONITORING_DASHBOARD_FAILURE:
          state = {
            ...state,
            loading: false,
            error: action.payload.error,
          }
          break;
        case dashboardConstants.FETCH_DATA_MONITORING_DASHBOARD_REQUEST:
          state = {
            ...state,
            data_monitoring:[],
            loading: true,
          }
          break;
        case dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_SUCCESS:
          state = {
            ...state,
            data_program: action.payload.data_program,
            loading: false,
          }
          break;
        case dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_FAILURE:
          state = {
            ...state,
            loading: false,
            error: action.payload.error,
          }
          break;
        case dashboardConstants.FETCH_DATA_PROGRAM_DASHBOARD_REQUEST:
          state = {
            ...state,
            data_program:[],
            loading: true,
          }
          break;
          case dashboardConstants.FETCH_STAKEHOLDER_REKAP_REQUEST:
            state = {
              ...state,
              loading: true,
            }
            break;
          case dashboardConstants.FETCH_STAKEHOLDER_REKAP_SUCCESS:
            state = {
              ...state,
              loading: false,
              stakeholder_umkm: action.payload.stakeholder_umkm,
            }
            break;
            case dashboardConstants.FETCH_STAKEHOLDER_REKAP_FAILURE:
              state = {
                ...state,
                loading: false,
                error: action.payload.error,
              }
              break;
  }

  return state;
}