import React from 'react';
/**
* @author
* @function Error Response
**/

const error = (props) => {

  let error = null;
  error = 
          <>
          {(props?.error&&props?.error!==null) &&                
            <div className="is-invalid">
              <span>
                {props?.error?.map((val) => {
                      return val;
                  })}
              </span>
            </div>
          }
          </>
  return error;
}

export default error