import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from '../../../components/UI/Table/Table';
import { getOkrLevel, getOkrPaket, getOkrKelompok, getOkrKategori,getListOkr } from "../../../actions/filter_okr.actions";
import Input from "../../../components/UI/Input";
import Modal from "../../../components/UI/Modal";
import Swal from "sweetalert2";
import "./style.scss"
import { 
  FaTrash, FaEye, FaRegMinusSquare, FaRegCheckSquare, FaRegSquare} from 'react-icons/fa'
import axios from "../../../helpers/axios";
import { useTranslation } from "react-i18next";

const UpdOkr = ({ isShow, handleClose, handleSave}) => {
    const { t } = useTranslation();
    const fltokrlevel = useSelector(state => state.fltOkr.levels);
    const fltokrpaket = useSelector(state => state.fltOkr.paket);
    const fltokrkelompok = useSelector(state => state.fltOkr.kelompok);
    const fltokrkategori = useSelector(state => state.fltOkr.kategori);
    const listOkr = useSelector(state => state.fltOkr.listokr);
    const [tempOkr, setTempOkr] = useState([]);

    const [okrlevel, setOkrLevel] = useState({});
    const [okrpaket, setOkrPaket] = useState({});
    const [count, setCount] = useState(0);
    const [okrkelompok, setOkrKelompok] = useState({});
    const [okrkategori, setOkrKategori] = useState({});
    const [slcOkr, setSelectOkr] = useState([]);
    const [head, setHead] = useState(<FaRegSquare size={25}/>);
    const [okr_flt, setOkrFlt] = useState(['semua','semua','semua','semua']);
    const dispatch = useDispatch()

    useEffect(() => { 
      if (isShow) {
        loadData('')
        setSelectOkr([]);
        dispatch(getOkrLevel())
        dispatch(getOkrPaket())
        dispatch(getOkrKelompok())
        dispatch(getOkrKategori())
        setHead(<button className="btn btn-sm btn-check" onClick={() => console.log('check all')}>
                    <FaRegSquare size={25}/>
                </button>);
      }
    }, [isShow]) 

    useEffect(() => {
      setCheckedHead(slcOkr,listOkr);
    }, [listOkr])

    useEffect(() => {
      setOkrLevel([]);
      let okrlvl = [{
        value: 'semua',
        name: 'Semua',
        selected:true
      }];
      if (fltokrlevel.length > 0){
        fltokrlevel.map((val,i) => {
          okrlvl.push({
            value: val.id,
            name: val.level_name,
          });
        })
      }
      setOkrLevel(okrlvl);
    }, [fltokrlevel])

    useEffect(() => {
      setOkrPaket([]);
      let okrpkt = [{
        value: 'semua',
        name: 'Semua',
        selected:true
      }];
      if (fltokrpaket.length > 0){
        fltokrpaket.map((val,i) => {
          okrpkt.push({
            value: val.id,
            name: val.okr_name,
          });
        })
      }
      setOkrPaket(okrpkt);
    }, [fltokrpaket])

    useEffect(() => {
      setOkrKelompok([]);
      let okrklmp = [{
        value: 'semua',
        name: 'Semua',
        selected:true
      }];
      if (fltokrkelompok.length > 0){
        fltokrkelompok.map((val,i) => {
          okrklmp.push({
            value: val.id,
            name: val.name,
          });
        })
      }
      setOkrKelompok(okrklmp);
    }, [fltokrkelompok])

    useEffect(() => {
      setOkrKategori([]);
      let okrktg = [{
        value: 'semua',
        name: 'Semua',
        selected:true
      }];
      if (fltokrkategori.length > 0){
        fltokrkategori.map((val,i) => {
          okrktg.push({
            value: val.id,
            name: val.category_name,
          });
        })
      }
      setOkrKategori(okrktg);
    }, [fltokrkategori])
    
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const list_okr = listOkr?.data?.map((i) => {
      return {
        col1: i.id,
        col2: i.indicator.objective.objective_name,
        col3: i.indicator.indicator_name,
        col4: i.task_level,
        col5: i.task_name,
        col6: i.task_point,
        col7: i.indicator.objective.category.category_name,
      };
    });
    const data_okr = React.useMemo(() => list_okr, [listOkr]);
    const columns = React.useMemo(
      () => [
        {
          Header:'',
          textAlign:'center',
          accessor: 'col1',
          width:"2%",
          Cell: (item) => (
            <input 
              type="checkbox"
              checked={item?.customState?.slcOkr.find(val => val.okr_task.id === item.cell.value)}
              onChange={(e) => {
                setCheckedBody(item.cell.value,item)
              }}
            />
          )
          
        },
        {
          Header: t('category'),
          accessor: 'col7',
          width:"15%",
        },
        {
          Header: t('objective'),
          accessor: 'col2',
          width:"15%",
        },
        {
          Header: t('key_success_indicators'),
          accessor: 'col3',
          width:"15%",
        },
        {
          Header: t('levels'),
          accessor: 'col4',
          width:"5%",
        },
        {
          Header: t('task'),
          accessor: 'col5',
        },
        {
          Header: t("value"),
          accessor: 'col6',
          width:"5%",
        },
        {
          Header: t("details"),
          accessor: 'col8',
          width:"5%",
          Cell: ({ cell }) => (
            <div className="btn-setting">
              <button className="btn btn-sm grp-button-eye"><FaEye /></button>
            </div>
          )
        }
      ],
      [listOkr,t]
    );

    const onSave = () => {
      handleSave(slcOkr)
    }

    const handleOkrFlt = (key,val) => {
      //mengubah selected filter
      var stateCopy = okr_flt;
      stateCopy[key]= val;
      setOkrFlt(stateCopy);
    }

    useEffect(() => {
      if (isShow) {
        loadData();
      }
    }, [isShow, page, perPage])

    const loadData = async() => {
        const kelas = okr_flt[0]!=='semua'?`okr_level=${okr_flt[0]}&`:'';
        const paket = okr_flt[1]!=='semua'?`okr_master=${okr_flt[1]}&`:'';
        const klmpk = okr_flt[2]!=='semua'?`okr_category_group=${okr_flt[2]}&`:'';
        const ktgri = okr_flt[3]!=='semua'?`okr_category=${okr_flt[3]}&`:'';
        
        dispatch(getListOkr(page,perPage,`${kelas}${paket}${klmpk}${ktgri}`)).then(response => {
          if(response?.data?.status=='success'){
            loadTempOkr(response?.data?.result.data)
          }
        });
    }

    function setCheckedHead(slOkr,lstOkr){
      var svg = <FaRegSquare size={25}/>;
      if (slOkr.length>0 && slOkr.length==lstOkr.length) {
        svg = <FaRegCheckSquare size={25}/>  ;   
      }else if(slOkr.length>0){
        svg = <FaRegMinusSquare size={25}/>;
      }
      setHead(<button className="btn btn-sm btn-check" onClick={() => console.log('check all')}>
                  {svg}
              </button>);
    }

    function setArrayOkr(data) {
      const data_okr = {
        okr_category_group : {
          id : data.indicator.objective.category.okr_category_group_id,
          name : data.indicator.objective.category.okr_category_group?.name??'',
          description : data.indicator.objective.category.okr_category_group?.description??''
        },
        okr_category : {
          id : data.indicator.objective.category.id,
          category_name : data.indicator.objective.category.category_name,
          category_description : data.indicator.objective.category.category_description
        },
        okr_objective : {
          id : data.indicator.objective.id,
          objective_name : data.indicator.objective.objective_name,
          objective_description : data.indicator.objective.objective_description
        },
        okr_indicator : {
          id : data.indicator.id,
          indicator_name : data.indicator.indicator_name,
          indicator_description : data.indicator.indicator_description
        },
        okr_task : {
          id : data.id,
          task_name : data.task_name,
          task_description : data.task_description,
          task_type : data.task_type,
          task_point : data.task_point,
          okr_indicator_id : data.okr_indicator_id,
          exam_id : data.exam_id,
          task_level : data.task_level
        }
      }

      return data_okr;
    }
    
    function loadTempOkr(data) {
      const idmap = tempOkr?.map(item => { return item.id });
      var newTempOkr = tempOkr?.length>0 ? tempOkr : []; 
      let arr = []
      if(data?.length>0){ 
        arr = data?.filter(function(item){
          return idmap.indexOf(item.id) === -1;
        });
        arr.length>0 && arr.map((val,i) => {
          newTempOkr.push(val)
        })
      }
      setTempOkr(newTempOkr);
      setCount(count+1);
    }

    const setCheckedBody = (params,item) => {
      var d = item?.customState?.slcOkr;
      var list = item?.customState?.tempOkr;
      var data = item?.customState?.tempOkr.find(item => item.id === params);
      const found = d.find(element => element.okr_task.id === params);
      if(!found){
        var list_data = list.filter(item => item.okr_indicator_id === data.okr_indicator_id) //cek level satu indicator
        d.push(setArrayOkr(data))
        list_data.map((val,i) => {
          if (val.id!==data.id) {
            if(val.task_level<data.task_level){
              !d.find(itm => itm.okr_task.id === val.id) && d.push(setArrayOkr(val))
            }         
          }
        })
      }else{
        var cek = 0;  
        var list_data = d.filter(item => item.okr_indicator.id === data.okr_indicator_id) //cek level satu indicator      
        list_data.map((val,i) => {
          if(val.okr_task.task_level>data.task_level){
            cek=cek+1;
          }
        })
        console.log(cek);
        if (cek==0) {
          var index = d.findIndex(itm => itm.okr_task.id === data.id);
          if(index === 0){
            d.shift();
          }else{
            d.splice(index, 1);
          }      
        }
      }
      setSelectOkr(d);
      setCheckedHead(item?.customState?.slcOkr,item?.data);
    }
    
  return(
    <>
      <Modal modalTitle={t('okr_based_requirements')} size="xl" show={isShow} handleClose={() => handleClose()}>
        <label className="mdl-desk">{t('desc_okr')}</label>
        <div className="row">
          <div className="col">    
            <div className="row">
              <div className="col-md-3">  
                  <Input
                    type="select"
                    label={t("msme_class")}
                    name="kelas"
                    options={okrlevel}
                    onChange={(e) => {
                      handleOkrFlt(0,e.target.value);
                      loadData();
                      setSelectOkr([])
                    }}
                  />
              </div>
              <div className="col-md-3">  
                  <Input
                    type="select"
                    label="Paket OKR"
                    name="paket"
                    options={okrpaket}
                    onChange={(e) => {
                      handleOkrFlt(1,e.target.value);
                      loadData();
                      setSelectOkr([])
                    }}
                  />
              </div>
              <div className="col-md-3">  
                  <Input
                    type="select"
                    label="Kelompok OKR"
                    name="kelompok"
                    options={okrkelompok}
                    onChange={(e) => {
                      handleOkrFlt(2,e.target.value);
                      loadData();
                      setSelectOkr([])
                    }}
                  />
              </div>
              <div className="col-md-3">  
                  <Input
                    type="select"
                    label="Kategori OKR"
                    name="kategori"
                    options={okrkategori}
                    onChange={(e) => {
                      handleOkrFlt(3,e.target.value);
                      loadData();
                      setSelectOkr([])
                    }}
                  />
              </div>
              <div className="col-md-12"> 
                <DataTable
                  data={data_okr??[]}
                  loading={listOkr.loading}
                  customState={{slcOkr:slcOkr??[],tempOkr:tempOkr??[]}}
                  cstmHead={head}
                  columns={columns}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  currentpage={page}
                  perPage={perPage}
                  totalPage={listOkr?.total}
                  noPaging={true}
                />
              </div> 
            </div>
            <div className="float-right">
                <>
                <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
                <button className="btn btn-sm button-green" onClick={() => onSave()}>Tambahkan</button>
                </>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdOkr