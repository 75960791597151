export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};

export const forgetContants = {
  FORGET_REQUEST: "FORGET_REQUEST",
  FORGET_SUCCESS: "FORGET_SUCCESS",
  FORGET_FAILURE: "FORGET_FAILURE",
};


export const userContants = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",
};

export const instansiConstants = {
  FETCH_INSTANSI_REQUEST: "FETCH_INSTANSI_REQUEST",
  FETCH_INSTANSI_SUCCESS: "FETCH_INSTANSI_SUCCESS",
  FETCH_INSTANSI_FAILURE: "FETCH_INSTANSI_FAILURE",
  INSTANSI_EDIT_REQUEST: "INSTANSI_EDIT_REQUEST",
  INSTANSI_EDIT_SUCCESS: "INSTANSI_EDIT_SUCCESS",
  INSTANSI_EDIT_FAILURE: "INSTANSI_EDIT_FAILURE"
}

export const dashboardConstants = {
  FETCH_DATA_DASHBOARD_REQUEST: "FETCH_DATA_DASHBOARD_REQUEST",
  FETCH_DATA_DASHBOARD_SUCCESS: "FETCH_DATA_DASHBOARD_SUCCESS",
  FETCH_DATA_DASHBOARD_FAILURE: "FETCH_DATA_DASHBOARD_FAILURE",
  FETCH_DATA_REKAP_DASHBOARD_REQUEST: "FETCH_DATA_REKAP_DASHBOARD_REQUEST",
  FETCH_DATA_REKAP_DASHBOARD_SUCCESS: "FETCH_DATA_REKAP_DASHBOARD_SUCCESS",
  FETCH_DATA_REKAP_DASHBOARD_FAILURE: "FETCH_DATA_REKAP_DASHBOARD_FAILURE",
  FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_REQUEST: "FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_REQUEST",
  FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_SUCCESS: "FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_SUCCESS",
  FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_FAILURE: "FETCH_DATA_REKAP_BUSINESSMAN_DASHBOARD_FAILURE",
  FETCH_DATA_REKAP_TC_DASHBOARD_REQUEST: "FETCH_DATA_REKAP_TC_DASHBOARD_REQUEST",
  FETCH_DATA_REKAP_TC_DASHBOARD_SUCCESS: "FETCH_DATA_REKAP_TC_DASHBOARD_SUCCESS",
  FETCH_DATA_REKAP_TC_DASHBOARD_FAILURE: "FETCH_DATA_REKAP_TC_DASHBOARD_FAILURE",
  FETCH_DATA_REKAP_UMKM_DASHBOARD_REQUEST: "FETCH_DATA_REKAP_UMKM_DASHBOARD_REQUEST",
  FETCH_DATA_REKAP_UMKM_DASHBOARD_SUCCESS: "FETCH_DATA_REKAP_UMKM_DASHBOARD_SUCCESS",
  FETCH_DATA_REKAP_UMKM_DASHBOARD_FAILURE: "FETCH_DATA_REKAP_UMKM_DASHBOARD_FAILURE",
  FETCH_DATA_MONITORING_DASHBOARD_REQUEST: "FETCH_DATA_MONITORING_DASHBOARD_REQUEST",
  FETCH_DATA_MONITORING_DASHBOARD_SUCCESS: "FETCH_DATA_MONITORING_DASHBOARD_SUCCESS",
  FETCH_DATA_MONITORING_DASHBOARD_FAILURE: "FETCH_DATA_MONITORING_DASHBOARD_FAILURE",
  FETCH_DATA_PROGRAM_DASHBOARD_REQUEST: "FETCH_DATA_PROGRAM_DASHBOARD_REQUEST",
  FETCH_DATA_PROGRAM_DASHBOARD_SUCCESS: "FETCH_DATA_PROGRAM_DASHBOARD_SUCCESS",
  FETCH_DATA_PROGRAM_DASHBOARD_FAILURE: "FETCH_DATA_PROGRAM_DASHBOARD_FAILURE",

  
  FETCH_STAKEHOLDER_REKAP_REQUEST: "FETCH_STAKEHOLDER_REKAP_REQUEST",
  FETCH_STAKEHOLDER_REKAP_SUCCESS: "FETCH_STAKEHOLDER_REKAP_SUCCESS",
  FETCH_STAKEHOLDER_REKAP_FAILURE: "FETCH_STAKEHOLDER_REKAP_FAILURE",
}

export const businessmanConstants = {
  FETCH_BUSINESSMANS_REQUEST: "FETCH_BUSINESSMANS_REQUEST",
  FETCH_BUSINESSMANS_SUCCESS: "FETCH_BUSINESSMANS_SUCCESS",
  FETCH_BUSINESSMANS_FAILURE: "FETCH_BUSINESSMANS_FAILURE"
}

export const category_industryConstants = {
  FETCH_CATEGORY_INDUSTRY_REQUEST: "FETCH_CATEGORY_INDUSTRY_REQUEST",
  FETCH_CATEGORY_INDUSTRY_SUCCESS: "FETCH_CATEGORY_INDUSTRY_SUCCESS",
  FETCH_CATEGORY_INDUSTRY_FAILURE: "FETCH_CATEGORY_INDUSTRY_FAILURE",
}

export const taskCategoryConstants = {
  FETCH_TASK_CATEGORY_REQUEST: "FETCH_TASK_CATEGORY_REQUEST",
  FETCH_TASK_CATEGORY_SUCCESS: "FETCH_TASK_CATEGORY_SUCCESS",
  FETCH_TASK_CATEGORY_FAILURE: "FETCH_TASK_CATEGORY_FAILURE",
}

export const fltwilayahConstants = {
  FETCH_FLTPROVINCE_REQUEST: "FETCH_FLTPROVINCE_REQUEST",
  FETCH_FLTPROVINCE_SUCCESS: "FETCH_FLTPROVINCE_SUCCESS",
  FETCH_FLTPROVINCE_FAILURE: "FETCH_FLTPROVINCE_FAILURE",
  FETCH_FLTCITY_REQUEST: "FETCH_FLTCITY_REQUEST",
  FETCH_FLTCITY_SUCCESS: "FETCH_FLTCITY_SUCCESS",
  FETCH_FLTCITY_FAILURE: "FETCH_FLTCITY_FAILURE",
  FETCH_FLTDISTRICT_REQUEST: "FETCH_FLTDISTRICT_REQUEST",
  FETCH_FLTDISTRICT_SUCCESS: "FETCH_FLTDISTRICT_SUCCESS",
  FETCH_FLTDISTRICT_FAILURE: "FETCH_FLTDISTRICT_FAILURE",
  FETCH_FLTSUBDISTRICT_REQUEST: "FETCH_FLTSUBDISTRICT_REQUEST",
  FETCH_FLTSUBDISTRICT_SUCCESS: "FETCH_FLTSUBDISTRICT_SUCCESS",
  FETCH_FLTSUBDISTRICT_FAILURE: "FETCH_FLTSUBDISTRICT_FAILURE",
}

export const fltOkrConstants = {
  FETCH_FLTOKRLEVEL_REQUEST: "FETCH_FLTOKRLEVEL_REQUEST",
  FETCH_FLTOKRLEVEL_SUCCESS: "FETCH_FLTOKRLEVEL_SUCCESS",
  FETCH_FLTOKRLEVEL_FAILURE: "FETCH_FLTOKRLEVEL_FAILURE",
  FETCH_FLTOKRPAKET_REQUEST: "FETCH_FLTOKRPAKET_REQUEST",
  FETCH_FLTOKRPAKET_SUCCESS: "FETCH_FLTOKRPAKET_SUCCESS",
  FETCH_FLTOKRPAKET_FAILURE: "FETCH_FLTOKRPAKET_FAILURE",
  FETCH_FLTOKRKELOMPOK_REQUEST: "FETCH_FLTOKRKELOMPOK_REQUEST",
  FETCH_FLTOKRKELOMPOK_SUCCESS: "FETCH_FLTOKRKELOMPOK_SUCCESS",
  FETCH_FLTOKRKELOMPOK_FAILURE: "FETCH_FLTOKRKELOMPOK_FAILURE",
  FETCH_FLTOKRKATEGORI_REQUEST: "FETCH_FLTOKRKATEGORI_REQUEST",
  FETCH_FLTOKRKATEGORI_SUCCESS: "FETCH_FLTOKRKATEGORI_SUCCESS",
  FETCH_FLTOKRKATEGORI_FAILURE: "FETCH_FLTOKRKATEGORI_FAILURE",
  FETCH_FLTLISTOKR_REQUEST: "FETCH_FLTLISTOKR_REQUEST",
  FETCH_FLTLISTOKR_SUCCESS: "FETCH_FLTLISTOKR_SUCCESS",
  FETCH_FLTLISTOKR_FAILURE: "FETCH_FLTLISTOKR_FAILURE",
}

export const programConstants = {
  FETCH_PROGRAMKOLABORATOR_REQUEST: "FETCH_PROGRAMKOLABORATOR_REQUEST",
  FETCH_PROGRAMKOLABORATOR_SUCCESS: "FETCH_PROGRAMKOLABORATOR_SUCCESS",
  FETCH_PROGRAMKOLABORATOR_FAILURE: "FETCH_PROGRAMKOLABORATOR_FAILURE",
  FETCH_PROGRAM_REQUEST: "FETCH_PROGRAM_REQUEST",
  FETCH_PROGRAM_SUCCESS: "FETCH_PROGRAM_SUCCESS",
  FETCH_PROGRAM_FAILURE: "FETCH_PROGRAM_FAILURE",
  PROGRAM_POST_REQUEST: "PROGRAM_POST_REQUEST",
  PROGRAM_POST_SUCCESS: "PROGRAM_POST_SUCCESS",
  PROGRAM_POST_FAILURE: "PROGRAM_POST_FAILURE",
  PROGRAM_EDIT_REQUEST: "PROGRAM_EDIT_REQUEST",
  PROGRAM_EDIT_SUCCESS: "PROGRAM_EDIT_SUCCESS",
  PROGRAM_EDIT_FAILURE: "PROGRAM_EDIT_FAILURE",
  PROGRAM_DELETE_REQUEST: "PROGRAM_DELETE_REQUEST",
  PROGRAM_DELETE_SUCCESS: "PROGRAM_DELETE_SUCCESS",
  PROGRAM_DELETE_FAILURE: "PROGRAM_DELETE_FAILURE",
  PROGRAM_RESTORE_REQUEST: "PROGRAM_RESTORE_REQUEST",
  PROGRAM_RESTORE_SUCCESS: "PROGRAM_RESTORE_SUCCESS",
  PROGRAM_RESTORE_FAILURE: "PROGRAM_RESTORE_FAILURE",

  FETCH_PROGRAM_PARTICIPANT_REQUEST: "FETCH_PROGRAM_PARTICIPANT_REQUEST",
  FETCH_PROGRAM_PARTICIPANT_SUCCESS: "FETCH_PROGRAM_PARTICIPANT_SUCCESS",
  FETCH_PROGRAM_PARTICIPANT_FAILURE: "FETCH_PROGRAM_PARTICIPANT_FAILURE",

  FETCH_PROGRAM_NO_RESOURCE_REQUEST: "FETCH_PROGRAM_NO_RESOURCE_REQUEST",
  FETCH_PROGRAM_NO_RESOURCE_SUCCESS: "FETCH_PROGRAM_NO_RESOURCE_SUCCESS",
  FETCH_PROGRAM_NO_RESOURCE_FAILURE: "FETCH_PROGRAM_NO_RESOURCE_FAILURE",

  PROGRAM_UPDATE_BATCH_REQUEST: "PROGRAM_UPDATE_BATCH_REQUEST",
  PROGRAM_UPDATE_BATCH_SUCCESS: "PROGRAM_UPDATE_BATCH_SUCCESS",
  PROGRAM_UPDATE_BATCH_FAILURE: "PROGRAM_UPDATE_BATCH_FAILURE",

  FETCH_PROGRAM_UMKM_BINAAN_REQUEST: "FETCH_PROGRAM_UMKM_BINAAN_REQUEST",
  FETCH_PROGRAM_UMKM_BINAAN_SUCCESS: "FETCH_PROGRAM_UMKM_BINAAN_SUCCESS",
  FETCH_PROGRAM_UMKM_BINAAN_FAILURE: "FETCH_PROGRAM_UMKM_BINAAN_FAILURE",
}

export const importDataConstants = {
  IMPORT_DATA_REQUEST: "IMPORT_DATA_REQUEST",
  IMPORT_DATA_SUCCESS: "IMPORT_DATA_SUCCESS",
  IMPORT_DATA_FAILURE: "IMPORT_DATA_FAILURE",
  
  FETCH_DATA_STAKEHOLDER_REQUEST: "FETCH_DATA_STAKEHOLDER_REQUEST",
  FETCH_DATA_STAKEHOLDER_SUCCESS: "FETCH_DATA_STAKEHOLDER_SUCCESS",
  FETCH_DATA_STAKEHOLDER_FAILURE: "FETCH_DATA_STAKEHOLDER_FAILURE",
  
  DATA_STAKEHOLDER_POST_REQUEST: "DATA_STAKEHOLDER_POST_REQUEST",
  DATA_STAKEHOLDER_POST_SUCCESS: "DATA_STAKEHOLDER_POST_SUCCESS",
  DATA_STAKEHOLDER_POST_FAILURE: "DATA_STAKEHOLDER_POST_FAILURE",
  
  DATA_STAKEHOLDER_DELETE_REQUEST: "DATA_STAKEHOLDER_DELETE_REQUEST",
  DATA_STAKEHOLDER_DELETE_SUCCESS: "DATA_STAKEHOLDER_DELETE_SUCCESS",
  DATA_STAKEHOLDER_DELETE_FAILURE: "DATA_STAKEHOLDER_DELETE_FAILURE",
}


export const stakeholderProgramConstants = {
  FETCH_STAKEHOLDER_PROGRAM_REQUEST: "FETCH_STAKEHOLDER_PROGRAM_REQUEST",
  FETCH_STAKEHOLDER_PROGRAM_SUCCESS: "FETCH_STAKEHOLDER_PROGRAM_SUCCESS",
  FETCH_STAKEHOLDER_PROGRAM_FAILURE: "FETCH_STAKEHOLDER_PROGRAM_FAILURE",
  STAKEHOLDER_PROGRAM_POST_REQUEST: "STAKEHOLDER_PROGRAM_POST_REQUEST",
  STAKEHOLDER_PROGRAM_POST_SUCCESS: "STAKEHOLDER_PROGRAM_POST_SUCCESS",
  STAKEHOLDER_PROGRAM_POST_FAILURE: "STAKEHOLDER_PROGRAM_POST_FAILURE",
  STAKEHOLDER_PROGRAM_EDIT_REQUEST: "STAKEHOLDER_PROGRAM_EDIT_REQUEST",
  STAKEHOLDER_PROGRAM_EDIT_SUCCESS: "STAKEHOLDER_PROGRAM_EDIT_SUCCESS",
  STAKEHOLDER_PROGRAM_EDIT_FAILURE: "STAKEHOLDER_PROGRAM_EDIT_FAILURE",
  STAKEHOLDER_PROGRAM_DELETE_REQUEST: "STAKEHOLDER_PROGRAM_DELETE_REQUEST",
  STAKEHOLDER_PROGRAM_DELETE_SUCCESS: "STAKEHOLDER_PROGRAM_DELETE_SUCCESS",
  STAKEHOLDER_PROGRAM_DELETE_FAILURE: "STAKEHOLDER_PROGRAM_DELETE_FAILURE",
  STAKEHOLDER_PROGRAM_RESTORE_REQUEST: "STAKEHOLDER_PROGRAM_RESTORE_REQUEST",
  STAKEHOLDER_PROGRAM_RESTORE_SUCCESS: "STAKEHOLDER_PROGRAM_RESTORE_SUCCESS",
  STAKEHOLDER_PROGRAM_RESTORE_FAILURE: "STAKEHOLDER_PROGRAM_RESTORE_FAILURE",

  FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_REQUEST: "FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_REQUEST",
  FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_SUCCESS: "FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_SUCCESS",
  FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_FAILURE: "FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_FAILURE",

  FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_REQUEST: "FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_REQUEST",
  FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_SUCCESS: "FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_SUCCESS",
  FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_FAILURE: "FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_FAILURE",

  STAKEHOLDER_PROGRAM_UPDATE_BATCH_REQUEST: "STAKEHOLDER_PROGRAM_UPDATE_BATCH_REQUEST",
  STAKEHOLDER_PROGRAM_UPDATE_BATCH_SUCCESS: "STAKEHOLDER_PROGRAM_UPDATE_BATCH_SUCCESS",
  STAKEHOLDER_PROGRAM_UPDATE_BATCH_FAILURE: "STAKEHOLDER_PROGRAM_UPDATE_BATCH_FAILURE",

  STAKEHOLDER_PROGRAM_PESERTA_POST_REQUEST: "STAKEHOLDER_PROGRAM_PESERTA_POST_REQUEST",
  STAKEHOLDER_PROGRAM_PESERTA_POST_SUCCESS: "STAKEHOLDER_PROGRAM_PESERTA_POST_SUCCESS",
  STAKEHOLDER_PROGRAM_PESERTA_POST_FAILURE: "STAKEHOLDER_PROGRAM_PESERTA_POST_FAILURE",
  STAKEHOLDER_PROGRAM_PESERTA_DELETE_REQUEST: "STAKEHOLDER_PROGRAM_PESERTA_DELETE_REQUEST",
  STAKEHOLDER_PROGRAM_PESERTA_DELETE_SUCCESS: "STAKEHOLDER_PROGRAM_PESERTA_DELETE_SUCCESS",
  STAKEHOLDER_PROGRAM_PESERTA_DELETE_FAILURE: "STAKEHOLDER_PROGRAM_PESERTA_DELETE_FAILURE",
}

export const stakeholderParticipantConstants = {
  FETCH_STAKEHOLDER_PARTICIPANT_REQUEST: "FETCH_STAKEHOLDER_PARTICIPANT_REQUEST",
  FETCH_STAKEHOLDER_PARTICIPANT_SUCCESS: "FETCH_STAKEHOLDER_PARTICIPANT_SUCCESS",
  FETCH_STAKEHOLDER_PARTICIPANT_FAILURE: "FETCH_STAKEHOLDER_PARTICIPANT_FAILURE",
  
}

export const agencyConstants = {
  FETCH_AGENCIES_REQUEST: "FETCH_AGENCIES_REQUEST",
  FETCH_AGENCIES_SUCCESS: "FETCH_AGENCIES_SUCCESS",
  FETCH_AGENCIES_FAILURE: "FETCH_AGENCIES_FAILURE",
  FETCH_AGENCY_REQUEST: "FETCH_AGENCY_REQUEST",
  FETCH_AGENCY_SUCCESS: "FETCH_AGENCY_SUCCESS",
  FETCH_AGENCY_FAILURE: "FETCH_AGENCY_FAILURE",
  UPDATE_STATUS_AGENCY_REQUEST: "UPDATE_STATUS_AGENCY_REQUEST",
  UPDATE_STATUS_AGENCY_SUCCESS: "UPDATE_STATUS_AGENCY_SUCCESS",
  UPDATE_STATUS_AGENCY_FAILURE: "UPDATE_STATUS_AGENCY_FAILURE",
  SUBMIT_REQUIRED_DATA_REQUEST: "SUBMIT_REQUIRED_DATA_REQUEST",
  SUBMIT_REQUIRED_DATA_SUCCESS: "SUBMIT_REQUIRED_DATA_SUCCESS",
  SUBMIT_REQUIRED_DATA_FAILURE: "SUBMIT_REQUIRED_DATA_FAILURE",
  APPROVE_REEVALUATE_DATA_REQUEST: "APPROVE_REEVALUATE_DATA_REQUEST",
  APPROVE_REEVALUATE_DATA_SUCCESS: "APPROVE_REEVALUATE_DATA_SUCCESS",
  APPROVE_REEVALUATE_DATA_FAILURE: "APPROVE_REEVALUATE_DATA_FAILURE"
}

export const legalEntityConstants = {
  FETCH_LEGAL_ENTITIES_REQUEST: "FETCH_LEGAL_ENTITIES_REQUEST",
  FETCH_LEGAL_ENTITIES_SUCCESS: "FETCH_LEGAL_ENTITIES_SUCCESS",
  FETCH_LEGAL_ENTITIES_FAILURE: "FETCH_LEGAL_ENTITIES_FAILURE",
}