import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => {
    return <Route {...rest} component={(props) => {
        const token = window.localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem("user"))

        if(token && new Date(user?.expired_date) > new Date()){
            return <Component {...props} />
        }else{
            localStorage.clear()
            return <Redirect to={`/signin`} />
        }
    }} />
}

export default PrivateRoute;