export const setNumberFormat = (val) => {
  return val>0?val.toLocaleString():0;
};
export const setStatus = (val) => {
  switch (val) {
    case '-':
      return '-'
    break;    
    case 'scheduled':
      return 'Dijadwalkan'
    break;    
    case 'suspend':
      return 'Ditangguhkan'
    break;    
    case 'complete':
      return 'Selesai'
    break;  
    default:
      return val.charAt(0).toUpperCase() + val.slice(1);
    break;
  }
}

export const setType = (val,t) => {
  switch (val) {
    case 'pinjaman':
      return t('loan_funding')
    break;    
    case 'hibah':
      return t('grant_funding')
    break;    
    case 'kompetisi':
      return t('competition_funding')
    break;    
    case 'pameran':
      return t('event')
    break;    
    case 'pelatihan':
      return t('trainig')
    break;    
    case 'pengadaan':
      return t('procurement')
    break;    
    default:
      return '';
    break;
  }
}

export const isBase64 = (str) => {
  var base64Matcher = new RegExp("^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$");
  return base64Matcher.test(str);
}