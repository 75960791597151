import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Card, CardBody, Alert } from "reactstrap";
import Input from '../../../components/UI/Input';
import { forget, verificationOTP, resetPassword } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import './style.scss';
import Swal from "sweetalert2";


import bg from "../../../assets/appon/login-bg.png";
import logoDark from "../../../assets/appon/logo.png";
import { useTranslation } from 'react-i18next';

/**
* @author
* @function Forget
**/

const Forget = (props) => {
    const { t } = useTranslation();
    const [phone, setPhone] = useState('');
    const [OTP, setOTP] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const forget_res = useSelector(state => state.forget);
    const [formState, setFromState] = useState(1);
    const [userId, setUserId] = useState('');
    
    const dispatch = useDispatch();

    const userForget = (e) => {
      e.preventDefault();

      const user = {
        phone,
      }

      dispatch(forget(user))
      .then((res) => {
        setUserId(res)
          setFromState(2)
      })
      .catch((err) => {
        Swal.fire({
          text: err,
          icon: 'error',
          confirmButtonText: t('closed')
        })
      })
    }

  const userVerificationOTP = (e) => {
    e.preventDefault();

      const user = {
        OTP,userId
      }

      dispatch(verificationOTP(user))
      .then((res) => {
          setFromState(3)
      })
      .catch((err) => {
        Swal.fire({
          text: err,
          icon: 'error',
          confirmButtonText: t('closed')
        })
      })      
  }

  const userResetPassword = (e) => {
    e.preventDefault();

      const user = {
        password, password_confirmation,phone,OTP
      }

      dispatch(resetPassword(user))
      .then((res) => {
          window.location = "/signin";
      })
      .catch((err) => {
        Swal.fire({
          text: err,
          icon: 'error',
          confirmButtonText: t('closed')
        })
      })      
  }

  function renderForm() {
    if(formState === 1){
      return (
        <div>
          <span className="font-size-12 mt-2 text-center">
            Kirim OTP SMS ke Nomor yang sudah Anda daftarkan
          </span>
          <p className="text-muted text-center">
            {/* Sign in to continue to Appon. */}
          </p>
          

          <Form className="form-login" onSubmit={userForget}>
              <Input 
                  placeholder="Nomor Handphone"
                  value={phone}
                  type="number"
                  onChange={(e) => setPhone(e.target.value)}
              />
              <span className="mt-2 text-center text-red">
                *Jangan beritahukan Kode OTP kepada orang lain
              </span>
              <Button className="btn-login mt-4" type="submit">
                  {forget_res.loading? "Loading" : "Kirim"}
              </Button>
            </Form>   
        </div>
      );
    }else if (formState === 2){
      return (
        <div>
          <span className="font-size-12 mt-2 text-center">
            Konfirmasi Kode OTP 
          </span>
          <p className="text-muted text-center">
            {/* Sign in to continue to Appon. */}
          </p>
          

          <Form className="form-login" onSubmit={userVerificationOTP}>
              <Input 
                  placeholder="Kode OTP"
                  value={OTP}
                  type="number"
                  maxLength={4}
                  onChange={(e) => setOTP((e.target.value.length > 4 ? OTP : e.target.value))}
              />
              
              <Button className="btn-login mt-4" type="submit">
                  {forget_res.loading? "Loading" : "Kirim"}
              </Button>
            </Form>   
        </div>
      );
    }else if (formState === 3){
      return (
        <div>
          <span className="font-size-12 mt-2 text-center">
            Atur Ulang Password
          </span>
          <p className="text-muted text-center">
            {/* Sign in to continue to Appon. */}
          </p>
          

          <Form className="form-login" onSubmit={userResetPassword}>
              <Input 
                  placeholder="Password"
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
              />
              <Input 
                  placeholder="Konfirmasi Password"
                  value={password_confirmation}
                  type="password"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
              <Button className="btn-login mt-4" type="submit">
                  {forget_res.loading? "Loading" : "Kirim"}
              </Button>
            </Form>   
        </div>
      );
    }
  }

    return (
      <div className="login-wrapper">
      <div
          className="accountbg"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${bg})`
          }}
        ></div>
        <div className="wrapper-page account-page-full">
          <Card className="shadow-none">
            <div className="card-block">
              <div className="account-box">
                <div className="card-box shadow-none p-4">
                  <div className="p-2">
                    <div className="text-center mt-4">
                      <Link to="/">
                        <img className="auth-logo" src={logoDark} height="22" alt="logo" />
                      </Link>
                    </div>
                    <div className="mt-2">
                      {renderForm()}
                    </div>
                    <div className="mt-5 pt-4 text-center">
                      <p>
                        © {new Date().getFullYear()} AppOn.id
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    )    
}

export default Forget