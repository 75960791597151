import React, { useEffect, useRef, useState } from "react";
import {FaAngleRight } from 'react-icons/fa'
import Modal from "../../../components/UI/Modal";
import "./style.scss"
import {setStatus, setVerif, setNumberFormat} from "../fnc_var";
import moment from 'moment';
import { useTranslation } from "react-i18next";

const FormDetail = ({ isShow, handleClose, data }) => {
  const { t } = useTranslation();
  const ownership = {
    pt: "PT",
    cv: "CV",
    firma: "Firma",
    ud: "UD",
    koperasi: "Koperasi",
    yayasan: "Yayasan",
    individual: "Perorangan",
  };
  const employees = {
    less_than_5: "Kurang dari 5",
    "5_to_19": "5 sampai 15",
    "20_to_99": "20 sampai 99",
    more_than_100: "Lebih dari 100",
  };
  return (
    <>
      <Modal
        modalTitle={t("details")}
        size="lg"
        show={isShow}
        handleClose={() => handleClose()}
      >
        <div className="row">
          <div className="col">
            <div className="row modal-title">
              <div className="col-12 col-md-12">
                <span className="modal-sub-title">
                  {t("entrepreneur_profile")}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("photo_id")}</span>
                  {data?.photo_identity !== null && (
                    <img
                      width={"100%"}
                      height={"auto"}
                      src={data?.photo_identity}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("id_number")}</span>
                  <span className="desk">{data?.id_number ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("owners_name")}</span>
                  <span className="desk">{data?.owner_name ?? "-"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <span className="title">{t("address")}</span>
                  <span className="desk">{data?.address ?? "-"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("email_address")}</span>
                  <span className="desk">{data?.email_address ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("contact_number")}</span>
                  <span className="desk">{data?.contact_number ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("tax_id_number_personal")}</span>
                  <span className="desk">{data?.individual_taxpayer_identification_no ?? "-"}</span>
                </div>
              </div>
            </div>

            <div className="row modal-title">
              <div className="col-12 col-md-12">
                <span className="modal-sub-title">Entrepreneur Data</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("business_photo")}</span>
                  {data?.business_photo !== null && (
                    <img
                      width={"100%"}
                      height={"auto"}
                      src={data?.business_photo}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("business_name")}</span>
                  <span className="desk">{data?.business_name ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("business_phone_number")}</span>
                  <span className="desk">{data?.business_contact_number ?? "-"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <span className="title">{t("address")}</span>
                  <span className="desk">{data?.business_address ?? "-"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">Suruhanjaya Syarikat Malaysia (SSM)</span>
                  <span className="desk">{data?.ssm ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">Local Town Council License (PBT License)</span>
                  <span className="desk">{data?.local_town_council_license ?? "-"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">Jakim Halal</span>
                  <span className="desk">{data?.jakim_halal ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">Law Firm Type</span>
                  <span className="desk">{data?.law_firm_type ?? "-"}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("tax_id_number_company")}</span>
                  <span className="desk">{data?.business_taxpayer_identification ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("business_master_number")}</span>
                  <span className="desk">{data?.business_identification_number ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("business_category")}</span>
                  <span className="desk">
                    {data?.category ?? "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">Jenis Badan Usaha</span>
                  <span className="desk">
                    {data?.business_ownership
                      ? ownership[data?.business_ownership]
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("numbers_of_employee")}</span>
                  <span className="desk">
                    {data?.number_of_employees
                      ? employees[data?.number_of_employees]
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t("turnover")}</span>
                  <span className="desk">
                    {"Rp. " + setNumberFormat(data?.business_omset ?? 0)}
                  </span>
                </div>
              </div>
            </div>

            <div className="row modal-title">
              <div className="col-12 col-md-12">
                <span className="modal-sub-title">Data Verifikasi</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t('verification_status')}</span>
                  <span className="desk">
                    {data?.status ? setVerif(data.status) : "-"}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t('verification_via')}</span>
                  <span className="desk">{data?.verify_type ?? "-"}</span>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <span className="title">{t('verified_by')}</span>
                  <span className="desk">{data?.verified_by ?? "-"}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="form-group">
                  <span className="title">{t('Verification_record')}</span>
                  <span className="desk">{data?.status_reason ?? "-"}</span>
                </div>
              </div>
            </div>

            <div className="float-right">
              <button
                className="btn btn-sm button-secondary mr-2"
                onClick={() => handleClose()}
              >
                {t('closed')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FormDetail