/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Bidangindustri(props) {
  const { t } = useTranslation();
  const stakeholder_umkm = useSelector(state => state.dashboard.stakeholder_umkm)
  var dataSet = [];
  stakeholder_umkm?.industrial_field?.data?.map((val,index) => {
    dataSet.push({ value: val, name: stakeholder_umkm?.industrial_field?.label[index] })
  })
  let bgColor = "bg-" + props.color;
  function getOption(){
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      // legend: {
      //   orient: "horizontal",
      //   bottom: "bottom",
      //   data: ["Kuliner", "Warung", "Pakaian", "Otomotif", "Elektronik","Hobi","Toko Bangunan","Furniture","Kecantikan",
      //           "Kemasan","Media","Mainan","Konveksi","Hiburan","Air Minum","Buku & Alat Tulis","Kesehatan","Mesin","Kerajinan",
      //           "Perkakas","Pertanian","Pertukangan","Pengelolaan Limbah","Logam Mulia","Periklanan","Keamanan","Pariwisata","Pendidikan",
      //           "Peternakan","Properti","Periklanan"],
      //   textStyle: {
      //     color: ["#74788d"],
      //   },
      // },
      color: ["#FCB630", "#f8b425", "#03fca5", "#ec4561", '#02a63e'],
      series: [
        {
          name: t('total_msme'),
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: dataSet,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{props.label}</h6>
          </div>
          <div className="card-content echart-bidang">
            
            <ReactEcharts style={{ height: "350px" }} option={getOption()} />
            {/* <div className="kt-widget12__progress">
                <span className="kt-widget12__on">Kuliner</span>
                <div className="progress kt-progress--sm">
                    <div className="progress-bar kt-bg-brand w90" />
                </div>
                <span className="kt-widget12__stat">350</span>
            </div>
            <div className="kt-widget12__progress">
                <span className="kt-widget12__on">Fashion</span>
                <div className="progress kt-progress--sm">
                    <div className="progress-bar kt-bg-brand w80" />
                </div>
                <span className="kt-widget12__stat">200</span>
            </div>
            <div className="kt-widget12__progress">
                <span className="kt-widget12__on">Agrikultur</span>
                <div className="progress kt-progress--sm">
                    <div className="progress-bar kt-bg-brand bg1 w60" />
                </div>
                <span className="kt-widget12__stat">150</span>
            </div>
            <div className="kt-widget12__progress">
                <span className="kt-widget12__on">Hobi</span>
                <div className="progress kt-progress--sm">
                    <div className="progress-bar kt-bg-brand bg1 w40" />
                </div>
                <span className="kt-widget12__stat">35</span>
            </div>
            <div className="kt-widget12__progress">
                <span className="kt-widget12__on">Lainnya</span>
                <div className="progress kt-progress--sm">
                    <div className="progress-bar kt-bg-brand bg3 w20" />
                </div>
                <span className="kt-widget12__stat">15</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bidangindustri;