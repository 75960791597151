const env = {};

env.baseUrl = process.env.REACT_APP_BASE_URI;
env.authUrl = process.env.REACT_APP_AUTH_URI;
env.masterUrl = process.env.REACT_APP_MASTER_URI;
env.onlinkUrl = process.env.REACT_APP_ONLINK_URI;
env.apiPrefix = process.env.REACT_APP_PREFIX;
env.client_id = process.env.REACT_APP_CLIENT_ID;
env.client_secret = process.env.REACT_APP_CLIENT_SECRET;

export default env;
