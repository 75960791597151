import React from 'react';
import { useTranslation } from "react-i18next";
import { FaStore } from "react-icons/fa";
import { useSelector } from "react-redux";

function StatCard(props) {
  const { t } = useTranslation();
  let bgColor = "bg-" + props.color;
  const _umkm = useSelector(
    (state) => state.dashboard.stakeholder_umkm?.status_umkm
  );
  const instansi = useSelector((state) => state.instansi);
  return (
    <>
      <div className="col-md-12 col-lg-12">
        <div className="row">
          <div className="col-md-3">
            <div className="card card-secondary mb-3 border-0 rounded-3">
              <span className="card-title text-white">{_umkm?.data[0]}</span>
              <span className="card-desk text-white">{t("new_data")}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-secondary border-0 rounded-3">
              <span className="card-title text-white">{_umkm?.data[1]}</span>
              <span className="card-desk text-white">{t("verified")}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-secondary mb-3 border-0 rounded-3">
              <span className="card-title text-white">{_umkm?.data[2]}</span>
              <span className="card-desk text-white">{t("unverified")}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-secondary mb-3 border-0 rounded-3">
              <span className="card-title text-white">{_umkm?.data[3]}</span>
              <span className="card-desk text-white">{t("businessmen")}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatCard;
