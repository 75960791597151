import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.scss";

/**
 * @author
 * @function Modal
 **/

const NewModal = (props) => {
  return (
    <Modal className="modal-me" size={props.size} show={props.show} onHide={props.handleClose} backdrop="static">
      <Modal.Header style={props.style ?? {}} closeButton> 
      {props.modalTitle &&
        <Modal.Title>{props.modalTitle}</Modal.Title>
      }
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      {props.footer &&
        <Modal.Footer>
          {props.buttons && (
            props.buttons.map((btn, index) => (
              <Button key={index} className={btn.class} variant={btn.color} onClick={btn.onClick}>
                {btn.label}
              </Button>
            ))
          ) }
        </Modal.Footer>
      }
    </Modal>
  );
};

export default NewModal;
