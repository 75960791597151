import { businessmanConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  businessmans: {
    data: [],
    meta: {}
  },
  meta: {
    total: 0
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case businessmanConstants.FETCH_BUSINESSMANS_REQUEST:
      state = {
        ...state,
        loading: true,
        businessmans: {
          data: [],
          meta: {}
        }
      }
      break;
    case businessmanConstants.FETCH_BUSINESSMANS_SUCCESS:
      state = {
        ...state,
        loading: false,
        businessmans: action.payload.businessmans,
        meta: action.payload.meta
      }
      break;
    case businessmanConstants.FETCH_BUSINESSMANS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
  }

  return state;
}