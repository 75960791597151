import React from 'react'

export default function Loading() {
  return(
    <div>
      <div style={{
        position: 'fixed',
        background: '#000',
        width: '100%',
        height: '100%',
        opacity: 0.5,
        zIndex: 999999
      }}>
      </div>
      <div style={{ 
        background: '#fff',
        padding: '60px 100px',
        borderRadius: 20,
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999999
      }}>
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  )
}