/* eslint-disable default-case */
import { stakeholderProgramConstants,stakeholderParticipantConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  program: [],
  programNoResource: [],
  meta: {
    total: 0
  },
  ProgramParticipant: {
    data: [],
    meta: {
      current_page: 1,
      per_page: 0,
      last_page: 0
    }
  },
  dpwParticipant: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case stakeholderParticipantConstants.FETCH_STAKEHOLDER_PARTICIPANT_REQUEST:
      state = {
        ...state,
        dpwParticipant:[]
      }
      break;
    case stakeholderParticipantConstants.FETCH_STAKEHOLDER_PARTICIPANT_SUCCESS:
      state = {
        ...state,
        dpwParticipant: action.payload.dpwParticipant
      }
      break;
    case stakeholderParticipantConstants.FETCH_STAKEHOLDER_PARTICIPANT_FAILURE:
      state = {
        ...state,
        error: action.payload.error
      }
      break;
      case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_POST_REQUEST:
        state = {
          ...state,
          loading: true
        }
      break;
      case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_POST_SUCCESS:
        state = {
          ...state,
          loading: false,
          message: action.payload.message
        }
        break;
      case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_POST_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
      break;
    case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_SUCCESS:
      state = {
        ...state,
        loading: false,
        program: action.payload.program,
        meta: action.payload.meta
      }
      break;
    case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_POST_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_POST_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message
      }
      break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_POST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_EDIT_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message
      }
      break;
      case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_EDIT_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
      break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_SUCCESS:
      let program = []
      
      state.program.map((newProgram, i) => {
        if (newProgram.id != action.payload.id) { 
          program.push(newProgram)
        }
      })
      state = {
        ...state,
        loading: false,
        program: program,
      }

      break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_RESTORE_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_RESTORE_SUCCESS:
      let program1 = []
      
      state.program.map((newProgram, i) => {
        if (newProgram.id != action.payload.id) { 
          program1.push(newProgram)
        }
      })

      state = {
        ...state,
        loading: false,
        program: program1,
      }

      break;
    case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_RESTORE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    
      case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_REQUEST:
        state = {
          ...state,
          loading: true
        }
        break;
      case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_SUCCESS:
        state = {
          ...state,
          loading: false,
          ProgramParticipant: action.payload.ProgramParticipant,
        }
        break;
      case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
        break;

        case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_REQUEST:
          state = {
            ...state,
            loading: true
          }
          break;
        case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_SUCCESS:
          state = {
            ...state,
            loading: false,
            programNoResource: action.payload.programNoResource,
            meta: action.payload.meta
          }
          break;
        case stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_FAILURE:
          state = {
            ...state,
            loading: false,
            error: action.payload.error
          }
          break;
        case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_NO_RESOURCE_EDIT_REQUEST:
          state = {
            ...state,
            loading: true
          }
          break;
        case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_NO_RESOURCE_EDIT_SUCCESS:
          state = {
            ...state,
            loading: false
          }
          break;
        case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_NO_RESOURCE_EDIT_FAILURE:
          state = {
            ...state,
            loading: false,
            error: action.payload.error
          }
          break;
          case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_DELETE_REQUEST:
            state = {
              ...state,
              loading: true
            }
          break;
          case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_DELETE_SUCCESS:
            state = {
              ...state,
              loading: false,
              message: action.payload.message
            }
            break;
            case stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_DELETE_FAILURE:
              state = {
                ...state,
                loading: false,
                error: action.payload.error
              }
            break;
  }

  return state;
}