/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';

function Peserta(props) {
  const data_program = useSelector(state => state.dashboard.data_program)

  var tot = 0;
  var stat = ["progress", "suspend", "complete"];
  data_program?.dataByProgram?.map((val,index) => {
    if(stat.indexOf(val.status)!=-1){
      tot=tot+val.value;
    }
  })
  let bgColor = "bg-" + props.color;
  function getOption(){
    return {
      xAxis: {
        type: 'category',
        data: ['On Progress', 'Ditangguhkan', 'Selesai']
      },
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} Peserta",
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [{
            value : data_program?.dataByStatus?.find(item => item.status === 'progress')?.value??0,
            itemStyle: {
              color: '#FFA127'}
          },{
            value : data_program?.dataByStatus?.find(item => item.status === 'suspend')?.value??0,
            itemStyle: {
              color: '#F1334D'}
          },{
            value : data_program?.dataByStatus?.find(item => item.status === 'complete')?.value??0,
            itemStyle: {
              color: '#FCB630'}
          }],
          type: 'bar'
        }
      ]
    }
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{tot+' Program'}</h6>
          </div>
          <div className="card-content echart-bidang">
            
            <ReactEcharts style={{ height: "350px" }} option={getOption()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Peserta;