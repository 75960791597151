/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaCogs,FaTrashAlt,FaEye,FaUserTie, FaSearch, FaArrowLeft } from 'react-icons/fa'
import './table.css';
import { Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import FormUmkm from "../DataUmkm/Umkm/formDetail";
import FormDetail from "../Stakeholder/RekapData/formDetail";
import DataTable from '../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgramPeserta,deletePeserta } from "../../actions/stakeholder-program.actions";
import ModalUMKM from "./mdlPeserta";
import { setType, setNumberFormat } from "../Stakeholder/fnc_var"
import { useTranslation } from "react-i18next";

const Detail = ({ program_id, handleBack }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);
  const [showFormUmkm, setShowFormUmkm] = useState(false);
  const [showUpdatePeserta, setShowUpdatePeserta] = useState(false);
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [selectedUmkm, setSelectedUmkm] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const loading = useSelector((state) => state.stakeholderProgram.loading);
  const ProgramParticipant = useSelector(
    (state) => state.stakeholderProgram.ProgramParticipant
  );
  const [search, setSearch] = useState("");
  const ownership = {
    "-": "-",
    pt: "PT",
    cv: "CV",
    firma: "Firma",
    ud: "UD",
    koperasi: "Koperasi",
    yayasan: "Yayasan",
    individual: "Perorangan",
  };

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = ProgramParticipant?.data.map((i, index) => {
    return {
      col1: ((page - 1) * perPage + (index + 1)).toString(),
      col2: i?.profil?.business_name,
      col3: i?.profil?.name,
      col4: i?.profil?.business_tlp,
      col5: i?.profil?.nik,
      col6: i?.profil?.business_category?.category ?? "-",
      col7: ownership[i?.profil?.business_ownership ?? "-"],
      col8: i?.profil?.number_of_employee ?? "-",
      col9: "Rp. " + setNumberFormat(i?.profil?.business_omset ?? 0),
      col10: i?.id,
    };
  });

  const data = React.useMemo(() => list, [ProgramParticipant?.data]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: "col1",
        width: "5%",
        textAlign: "center",
      },
      {
        Header: t("msme_name"),
        accessor: "col2",
        width: "10%",
        Cell: ({ row: { original } }) => (
          <div className="td-usaha">
            <span className={`span-icon-${original.col5}`}>
              <FaUserTie className="me-1" />
            </span>
            <span className="span-text">{original.col2}</span>
          </div>
        ),
      },
      {
        Header: t("owners_name"),
        accessor: "col3",
        width: "10%",
      },
      {
        Header: t('business_contact'),
        accessor: "col4",
        width: "10%",
      },
      {
        Header: t("business_category"),
        accessor: "col5",
        width: "10%",
        textAlign: "center",
      },
      {
        Header: t("business_category"),
        accessor: "col6",
        textAlign: "center",
      },
      {
        Header: t('type_business_entity'),
        accessor: "col7",
        width: "10%",
        textAlign: "center",
      },
      {
        Header: t("numbers_of_employee"),
        accessor: "col8",
        width: "10%",
        textAlign: "center",
      },
      {
        Header: t("turnover"),
        accessor: "col9",
        width: "15%",
        textAlign: "center",
      },
      {
        Header: t("details"),
        accessor: "col10",
        Cell: (item) => (
          <div className="btn-setting">
            <Dropdown className="dpw-setting">
              <Dropdown.Toggle variant="setting" id="dropdown-basic">
                <FaCogs className="me-1" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    handleFormDetail(
                      item.cell.value,
                      item.customState.listumkm
                    );
                  }}
                >
                  <FaEye />
                  {t("details")}
                </Dropdown.Item>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    handleDelete(item.cell.value);
                  }}
                >
                  <FaTrashAlt />
                  {t('delete')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        width: "5%",
        textAlign: "center",
      },
    ],
    [ProgramParticipant?.data, t]
  );

  const handleFormDetail = (id, list) => {
    console.log(id, list);
    const _data = list.find((item) => item.id === id);
    setSelectedData(_data?.profil);
    setShowFormDetail(true);
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "",
      text: "Apakah anda Yakin ingin menghapus penerima program tersebut?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: t('delete_confirmation_yes'),
      cancelButtonColor: "#d33",
      cancelButtonText: t('cancelled'),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePeserta(id));
        setCount(count + 1);
      }
    });
  };

  useEffect(() => {
    dispatch(getProgramPeserta(program_id, page, perPage, search));
  }, [program_id, page, perPage, search, count]);

  return (
    <>
      <div className="col">
        <div className="row">
          <div className="col-wrapper">
            <div className="text-start">
              <button
                onClick={() => handleBack()}
                className="btn btn-sm btn-outline-secondary btn-back"
              >
                <FaArrowLeft className="me-1" />
                kembali
              </button>
            </div>
            <div className="text-end"></div>
          </div>

          <div className="col-wrapper">
            <div className="text-start">
              <div className="search-wrapper">
                <input
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  id="search-input"
                  placeholder="Cari Pengusaha.."
                  type="text"
                />
                <button className="btn btn-sm btn-outline-secondary mt-1">
                  <FaSearch className="me-1" />
                </button>
              </div>
            </div>
            <div className="text-end"></div>
          </div>

          <div className="col-wrapper">
            <div className="text-start">
              <button
                className="btn btn-detail"
                onClick={() => {
                  setShowUpdatePeserta(true);
                }}
              >
                Add Recipient Program
              </button>
            </div>
            <div className="text-end"></div>
          </div>
        </div>

        <DataTable
          data={data}
          customState={{ listumkm: ProgramParticipant?.data ?? [] }}
          columns={columns}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={ProgramParticipant?.meta.last_page}
          loading={loading}
        />
      </div>
      <FormUmkm
        isShow={showFormUmkm}
        data={selectedUmkm}
        handleClose={() => setShowFormUmkm(false)}
      />
      <FormDetail
        isShow={showFormDetail}
        data={selectedData}
        handleClose={() => setShowFormDetail(false)}
      />
      <ModalUMKM
        isShow={showUpdatePeserta}
        selectedData={program_id}
        // handleSave={(val)=>{
        //   setProgram({...program, ['program_filter_okrs']: val});
        //   setErrorTemp([]);
        //   setShowUpdateOkr(false)
        // }}
        handleClose={(val) => {
          // val?.okr.length==0 && setProgram({...program, ['program_filter_okrs']: []});
          if (val) {
            setCount(count + 1);
          }
          setShowUpdatePeserta(false);
        }}
      />
    </>
  );
};

export default Detail;
