import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./index.css";
import My from "./assets/my.gif";
import Uk from "./assets/uk.gif";
import Select, { components } from "react-select";

const languageMap = {
  en: { label: "English", dir: "ltr", active: true },
  my: { label: "Malaysia", dir: "ltr", active: false },
};

const countries = [
  { value: "en", label: "", icon: Uk },
  { value: "my", label: "", icon: My },
];

const LanguageSelect = () => {
  const selected = localStorage.getItem("i18nextLng") || "en";
  const [selectedCountry, setSelectedCountry] = useState(
    countries.filter((o) => o.value === selected)[0] || {
      value: "en",
      label: "",
      icon: Uk,
    }
  );

  const handleChange = (value) => {
    setSelectedCountry(value);
    i18next.changeLanguage(value.value);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={selectedCountry.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <img src={props.data.icon} alt="logo" className="country-logo" />
      {props.data.label}
    </components.Option>
  );

  return (
    <div>
      <Select
        value={selectedCountry}
        options={countries}
        onChange={handleChange}
        styles={{
          singleValue: (base) => ({
            ...base,
            display: "flex",
            alignItems: "center",
          }),
        }}
        components={{
          Option,
          SingleValue,
        }}
      />
    </div>
  );
};

export default LanguageSelect;
