import React from 'react';
import { Form } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import "./style.css";

/**
* @author
* @function Input
**/

const Input = (props) => {
  let input = null;
  switch(props.type){
    case 'select':
      input = <Form.Group className={props.classGroupName}>
                {props.label && <Form.Label  className="span-tittle">{props.label}</Form.Label>}
                <select
                  className={`form-control form-control-sm ${props.className}`}
                  name={props.name}
                  value={props.value}
                  onChange={props.onChange}
                >
                  {props.placeholder && <option value="">{props.placeholder}</option>}                  
                  {
                    props.options.length > 0 ?
                    props.options.map((option, index) =>
                      <option key={index} value={option.value} multiple={option.multiple} selected={option.selected?option.selected:(option.value===props.value)} disabled={option.disabled}>{option.name}</option>
                    ) : null
                  }
                </select>
                {(props.error&&props.error!==null) &&                
                  <div className="is-invalid">
                    <span>
                      {Array.from(props.error).map((val) => {
                            return val;
                        })}
                    </span>
                  </div>
                }
            </Form.Group>
      break;
      case 'currency':
        input = <Form.Group>
                  {props.label && <Form.Label className="span-tittle">{props.label}</Form.Label>}
                  <CurrencyInput
                    id={props.name}
                    name={props.name}
                    placeholder={props.placeholder} 
                    defaultValue={props.default}
                    decimalsLimit={props.decimal}
                    onValueChange={props.onChange}
                    className="input-curr"
                    disabled={props.disabled}
                    groupSeparator= ','
                    decimalSeparator= '.'
                  />
                  <Form.Text className="text-muted">
                    {props.errorMessage}
                  </Form.Text>
                  {(props.error&&props.error!==null) &&                
                    <div className="is-invalid">
                    <span>
                      {Array.from(props.error).map((val) => {
                            return val;
                        })}
                    </span>
                    </div>
                  }
              </Form.Group>
    break;
    case 'file':
      input = <Form.Group>
                {props.label && <Form.Label className="span-tittle">{props.label}</Form.Label>}
                <Form.Control 
                    className={props.className}
                    type={props.type} 
                    accept="image/*"
                    placeholder={props.placeholder} 
                    name={props.name}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    {...props}
                />
                <Form.Text className="text-muted">
                  {props.errorMessage}
                </Form.Text>
                {(props.error&&props.error!==null) &&                
                  <div className="is-invalid">
                  <span>
                    {Array.from(props.error).map((val) => {
                          return val;
                      })}
                  </span>
                  </div>
                }
            </Form.Group>
    break;
    case 'textarea':
      input = <Form.Group>
                {props.label && <Form.Label className="span-tittle">{props.label}</Form.Label>}
                <Form.Control 
                    className={props.className}
                    as="textarea" 
                    rows={props.rows}
                    placeholder={props.placeholder} 
                    name={props.name}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    {...props}
                />
                <Form.Text className="text-muted">
                  {props.errorMessage}
                </Form.Text>
                {(props.error&&props.error!==null) &&                
                  <div className="is-invalid">
                  <span>
                    {Array.from(props.error).map((val) => {
                          return val;
                      })}
                  </span>
                  </div>
                }
            </Form.Group>
    break;
    case 'text':
    case 'number':
    default:
      input = <Form.Group>
                {props.label && <Form.Label className="span-tittle">{props.label}</Form.Label>}
                <Form.Control 
                    className={props.className}
                    type={props.type} 
                    placeholder={props.placeholder} 
                    name={props.name}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    {...props}
                />
                <Form.Text className="text-muted">
                  {props.errorMessage}
                </Form.Text>
                {(props.error&&props.error!==null) &&                
                  <div className="is-invalid">
                  <span>
                    {Array.from(props.error).map((val) => {
                          return val;
                      })}
                  </span>
                  </div>
                }
            </Form.Group>
  }


  return input;

 }

export default Input