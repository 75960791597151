import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from '../../../components/UI/Table/Table';
import Input from "../../../components/UI/Input";
import Modal from "../../../components/UI/Modal";
import MdlOkrList from "./mdlOkrList";
import Swal from "sweetalert2";
import "./style.scss"
import { 
  FaTrash, FaPlusCircle} from 'react-icons/fa'
import axios from "../../../helpers/axios";
import { useTranslation } from "react-i18next";

const UpdOkr = ({ isShow, handleClose, handleSave, selectedData, viewState}) => {
  const { t } = useTranslation();
  const [showListOkr, setShowListOkr] = useState(false);
  const [listOkr, setListOkr] = useState([]);
  const [count, setCount] = useState(0);
  
  useEffect(() => { //state Editing
    if(selectedData?.length>0){
      setListOkr(selectedData);
    }
  }, [selectedData])

  const list_okr = listOkr.map((i, index) => {
    return {
      col1: (index + 1).toString(),
      col2: i.okr_objective.objective_name,
      col3: i.okr_indicator.indicator_name,
      col4: i.okr_task.task_level,
      col5: i.okr_task.task_name,
      col6: i.okr_task.task_point,
      col7: i.okr_category.category_name,
      col8: i.okr_task.id,
    };
  });
  const data_okr = React.useMemo(() => list_okr, [listOkr]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: 'col1',
        width: '5%',
        textAlign: 'center'
      },
      {
        Header: t('category'),
        accessor: 'col7',
        width:"10%",
      },
      {
        Header: t('objective'),
        accessor: 'col2',
        width:"15%",
      },
      {
        Header: t('key_success_indicators'),
        accessor: 'col3',
        width:"15%",
      },
      {
        Header: t('levels'),
        accessor: 'col4',
        width:"5%",
      },
      {
        Header: t('task'),
        accessor: 'col5',
      },
      {
        Header: t("value"),
        accessor: 'col6',
        width:"5%",
      },
      {
        Header: t('action'),
        accessor: 'col8',
        width:"5%",
        Cell: (item) => (
          !viewState &&
          <div className="btn-group">
            <button className="btn btn-sm grp-button-del" onClick={(e) => {
              removeOkr(item.cell.value,item.customState.listOkr)
            }}><FaTrash /></button>
          </div>
        )
      }
    ],
    [listOkr, t]
  );

  const onSave = () => {
    if(listOkr.length > 0){
        Swal.fire({
            title: '',
            text: t('are_you_sure'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: t('save'),
            cancelButtonColor: '#d33',
            cancelButtonText: t('re_edit'),
        
          }).then((result) => {
            if (result.isConfirmed) {
                handleSave(listOkr)
            }
          })
    }else{
      Swal.fire({
          title: '',
          text: "Anda belum memilih OKR?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: t('re_edit'),
          cancelButtonColor: '#d33',
          cancelButtonText: t('exit'),
      
        }).then((result) => {
          if (!result.isConfirmed) {
            handleClose({'okr':[]});
          }
        })
    }
  }

  function ary_sort(data) {
    return data.sort(
      function(a, b) {          
        if (a.okr_objective.id === b.okr_objective.id) {
          return a.okr_task.task_level < b.okr_task.task_level ? -1 : 1;
        }
        return a.okr_objective.id > b.okr_objective.id ? 1 : -1;
      }
    )
  }

  const addListOkr = (item) => {
    var srtItem = ary_sort(item);
    srtItem.map((val,i) => {
      const found = listOkr.find(element => element?.okr_task?.id === val.okr_task.id);
      if(!found){
        setListOkr(listOkr => [...listOkr, val]);
      }
    })
    setCount(count+1);
  }

  const removeOkr = (deleteOkrId, list_okr) => {
    const deleted_data = list_okr.find(item => item.okr_task.id === deleteOkrId)
    const list_data = list_okr.filter(item => item.okr_indicator.id === deleted_data.okr_indicator.id) //cek level satu indicator
    let found = 0;

    list_data.map((val,i) => {
      if (val.okr_task.task_level>deleted_data.okr_task.task_level) {
        found = found + 1;
      }
    })
    if(found>0){
      Swal.fire({
        title: 'Error!',
        text: 'gagal menghapus tugas ini, karena Ada level yang lebih tinggi',
        icon: 'error',
        confirmButtonColor: '#FCB630',
        confirmButtonText: 'Okay'
      })
    } else {
      const newOkr = list_okr.filter((okr) => okr.okr_task.id !== deleteOkrId);
      setListOkr(newOkr);
      setCount(count+1);
    }
  }

  return(
    <>
      <Modal modalTitle={t('okr_based_requirements')} size="xl" show={isShow} handleClose={() => { setListOkr([]); setCount(count+1); handleClose()}}>
        <label className="mdl-desk">{t('desc_okr')}</label>
        <div className="row">
          <div className="col">    
            <div className="row">
              <div className="col-md-12"> 
                {!viewState &&
                  <div className="btn-group">
                    <button className="btn btn-sm btn-listokr-add" onClick={(e) => {
                      setShowListOkr(true)
                    }}><FaPlusCircle /> Tambah OKR</button>
                  </div>
                }
                <DataTable
                  data={data_okr??[]}
                  customState={{listOkr:listOkr??[]}}
                  columns={columns}
                />
              </div> 
            </div>
            <div className="float-right">
              {!viewState?
                <>
                <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
                <button className="btn btn-sm button-green" onClick={() => onSave()}>{t('save')}</button>
                </>
                :
                <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
              }   
            </div>
          </div>
        </div>
      </Modal>
      <MdlOkrList 
        isShow={showListOkr} 
        handleSave={(val)=>{addListOkr(val); setShowListOkr(false)}}
        handleClose={() => setShowListOkr(false)}
      />
    </>
  )
}

export default UpdOkr