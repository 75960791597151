import React, { useEffect, useRef, useState } from "react";
import {FaAngleRight } from 'react-icons/fa'
import Modal from "../../../../components/UI/Modal";
import "./style.scss"
import ImgHp from "../../../../assets/appon/img_hp.png";
import { setType, setNumberFormat } from "../fnc_var"
import UpdImage from "../ProgramTambah/updImage";
import moment from 'moment';
import {setStatus} from "../fnc_var";
import { useTranslation } from "react-i18next";

const FormDetail = ({ isShow, handleClose, data }) => {
  const { t } = useTranslation();
  const [showUpdateKelas, setShowUpdateKelas] = useState(false);
  const [showJenisIndustri, setShowJenisIndustri] = useState(false);
  const [showUpdateWilayah, setShowUpdateWilayah] = useState(false);
  const [showUpdateOkr, setShowUpdateOkr] = useState(false);
  const [showUpdateKolab, setShowUpdateKolab] = useState(false);

  
  const [wilayah, setWilayah] = useState([]);
  const [industri, setIndustri] = useState([]);
  const [kelas, setKelas] = useState([]);
  const [colab, setColab] = useState([]);
  useEffect(() => {
    if(data){
      let wil = data?.program_filters?.find(item => item.filter_type === 'zone')
      if (wil) {
        setWilayah(wil?.filter_value)
      }

      let ind = data?.program_filters?.find(item => item.filter_type === 'industry')
      if (ind) {
        setIndustri(ind?.filter_value)
      }

      let kls = data?.program_filters?.find(item => item.filter_type === 'class')
      if (kls) {
        setKelas(kls?.filter_value)
      }

      if (data?.program_owners?.length>1) {
        let owners = [];
        data.program_owners.map((val,index) => {
          if(val.is_owner==0){
            owners.push({
              id: val.instansi_id,
              name: val.instansi.name,
              cover:val.instansi.cover_url
            })
          }
        })
        
        setColab(owners);
      }
    }
  }, [data])
  

  return(
    <>
      <Modal modalTitle={t('program_details')} size="lg" show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col-md-4">
            <div className="image-banner">
              {data?.program_image &&
                <img className="img-banner" src={data.program_image} alt="logo" />
              }
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('program_name')}</span>
                  <span className="desk">{data?.program_name}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('program_type')}</span>
                  <span className="desk">{data?.program_category?.name}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('early_period')}</span>
                  <span className="desk">{moment(data?.program_start).format('DD-MM-YYYY HH:mm')+' WIB'}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('end_period')}</span>
                  <span className="desk">{moment(data?.program_end).format('DD-MM-YYYY HH:mm')+' WIB'}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('participant_quota')}</span>
                  <span className="desk">{data?.participant_quota==null?t('unlimited'):t('limited')+' :'+data.participant_quota}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('budget')}</span>
                  <span className="desk">{'Rp. '+setNumberFormat(data?.participant_budget??0) + 
                    (data?.budget_show && data.budget_show>0? ' ( Ditampilkan )':' ( Tidak Ditampilkan )')}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <span className="title">{t('description')}</span>
                  {data?.program_description && 
                    <div className="desk-html" dangerouslySetInnerHTML={{ __html: data.program_description }} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="float-right">
              <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
    
  )
}

export default FormDetail