import React, { useEffect } from 'react';
import Layout from '../../../components/Base/main/main';
import StatCard from "./StatCard";
import JenisIndustri from "./JenisIndustri";
import StatusProgram from "./StatusProgram";
import Peserta from "./Peserta";
import Bidangindustri from "./Bidangindustri";
import "./style.css"
import { useDispatch, useSelector } from 'react-redux';
import { 
  getDataProgram
} from '../../../actions/dashboard.actions';
import { useTranslation } from 'react-i18next';

function Rekap() {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"))
  const dispatch = useDispatch()

  useEffect(() => {
    const instanceId = user.user_agency.id
    dispatch(getDataProgram(instanceId))
  }, [])
  
  return (
    <Layout>
      <div className="row">
        <StatCard color="redap" />
        <Bidangindustri stat={t('program_comparison')}  color="white" />
        <JenisIndustri stat={t('participants_based_on_industry_type')} label="31 Jenis" color="white" />
        <StatusProgram stat={t('program_status')} label="3 Jenis" color="white" />
        <Peserta stat={t('construction_participants')} label="3 Jenis" color="white" />
        {/*<Medsos stat={t('most_popular_social_media')} color="white" />
        <Badanhukum stat={t('legal_entity')} label="3 Jenis" color="white" />
        <Jenisusaha stat={t('business_characteristics')} label="3 Jenis" color="white" />
        <Jenisusaha2 stat={t('business_unit')} label="2 Jenis" color="white" /> */}
      </div>
    </Layout>
  );
}

export default Rekap;
