import React, { useEffect, useRef, useState } from "react";
import { FaCogs, } from 'react-icons/fa'
import { Dropdown } from "react-bootstrap";
import Layout from '../../components/Base/main/main';
import logo from "../../assets/appon/garuda.png";
import loading from "../../assets/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import { getProfil } from "../../actions/instansi.actions";
import './style.scss';

import UpdateProfil from "./updateProfil";
import { getAgencyInfo, submitRequiredData } from "../../actions/agency.actions";
import Loading from "../../components/UI/Loading";

function Profil() {
    const [updatelogo, setUpdatelogo] = useState(false);
    const [loading, setLoading] = useState(false);
    const instansi = useSelector(state => state.instansi);
    const agency = useSelector(state => state.agency.agency);
    const user = JSON.parse(localStorage.getItem("user"));
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [idCardFrontFile, setIdCardFrontFile] = useState('')
    const [idCardBackFile, setIdCardBackFile] = useState('')
    const [letterAppointmentFile, setLetterApointmentFile] = useState('')
    const [ssmSertificationFile, setSsmCertificationFile] = useState('')

    const dispatch = useDispatch()

    const handleEditLogo = () => {
        setUpdatelogo(true)
        setShowUpdateForm(true)
    }

    const _submitRequiredData = (e) => {
        e.preventDefault()
        const data = {
            idCardFrontFile, idCardBackFile, letterAppointmentFile, ssmSertificationFile
        }
        setLoading(true)
        dispatch(submitRequiredData(user.user_agency.id, data))
            .then((res) => {
                dispatch(getAgencyInfo(user.user_agency.id))
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        const instanceId = user.user_agency.id
        dispatch(getProfil(instanceId))
        dispatch(getAgencyInfo(user.user_agency.id))
    }, [])

    useEffect(() => {
        if (agency?.status == 'new') {
            window.location = '/'
        }
        console.log(agency)
    }, [agency])

    const validateFileSize = (event) => {
        const file = event.target.files[0]
        const size = file.size
        console.log(size)
        if ((size / 1024)> (1024 * 5)) {
            alert("File size must not be greater than to 5MB")
            return false
        }
        return true
    }

    return (
        <>
            {loading && <Loading />}
            <Layout>
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-6">
                            <div className="col-wrapper-profile">
                                {/* <div className="profil row">           
                                <span className="span-img-profil">
                                    {instansi.loading && <img className="img-profil" src={loading} alt="logo" />}
                                    {!instansi.loading && <img className="img-profil" src={instansi.instansi.cover_url!==null?instansi.instansi.cover_url:''} alt="logo" />}
                                </span>      
                                <div className="det-profil">    
                                    <span className="span-nama">{!instansi.loading && instansi.instansi.name}</span>
                                </div>  
                                <div className="det-setting">
                                    <Dropdown className="dpw-setting">
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <FaCogs className="me-1" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={() => handleEditLogo()}>
                                                Edit Logo
                                            </Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={() => {
                                                setShowUpdateForm(true)
                                                setUpdatelogo(false)
                                            }}>
                                                Edit Nama
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>   
                                </div>  
                            </div> */}
                                {/* <div className="row">
                                <label>Name</label>
                                <input
                                    className="form-control d-inline mb-3"
                                    type="text"
                                    value={user.user_agency.name_agency}
                                />
                            </div>        
                            <div className="row">
                                <label>Email</label>
                                <input
                                    className="form-control d-inline mb-3"
                                    type="text"
                                    value={user.email}
                                />
                            </div>        
                            <div className="row">
                                <label>Name of Representative</label>
                                <input
                                    className="form-control d-inline mb-3"
                                    type="text"
                                    value={user.user_agency.name_representative}
                                />
                            </div>        
                            <div className="row">
                                <label>PIC Number Phone</label>
                                <input
                                    className="form-control d-inline mb-3"
                                    type="text"
                                    value={user.user_agency.pic_number}
                                />
                            </div>         */}
                            </div>
                            {user.user_agency.status !== 'approve' && <div className="col-md-12">
                                <div className="row">
                                    <form className="mt-2" onSubmit={_submitRequiredData} enctype="multipart/form-data">
                                        <label>Identification Card (Front) of Representative</label>
                                        {user.user_agency.identity_card_front && <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + user.user_agency.identity_card_front} target="_blank">[Open]</a>}
                                        <input
                                            className="form-control d-inline mb-3"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    if (validateFileSize(e)) {
                                                        setIdCardFrontFile(e.target.files[0]);
                                                    } else {
                                                        e.target.value = ""
                                                    }
                                                }
                                            }}
                                            required
                                        />
                                        <label>Identification Card (Back) of Representative</label>
                                        {user.user_agency.identity_card_back && <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + user.user_agency.identity_card_back} target="_blank">[Open]</a>}
                                        <input
                                            className="form-control d-inline mb-3"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    if (validateFileSize(e)) {
                                                        setIdCardBackFile(e.target.files[0]);
                                                    } else {
                                                        e.target.value = null;
                                                    }
                                                }
                                            }}
                                            required
                                        />
                                        <label>Letter of Appointment as Representative of Agency</label>
                                        {user.user_agency.letter_appointment && <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + user.user_agency.letter_appointment} target="_blank">[Open]</a>}
                                        <input
                                            className="form-control d-inline mb-3"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    if (validateFileSize(e)) {
                                                        setLetterApointmentFile(e.target.files[0]);
                                                    } else {
                                                        e.target.value = null;
                                                    }
                                                }
                                            }}
                                            required
                                        />
                                        <label>SSM certification (if any)</label>
                                        {user.user_agency.ssm_certification && <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + user.user_agency.ssm_certification} target="_blank">[Open]</a>}
                                        <input
                                            className="form-control d-inline mb-3"
                                            type="file"
                                            onChange={(e) => {
                                                if (e.target.files) {
                                                    if (validateFileSize(e)) {
                                                        setSsmCertificationFile(e.target.files[0]);
                                                    } else {
                                                        e.target.value = null;
                                                    }
                                                }
                                            }}
                                        />

                                        <button className="btn btn-signin font-bold" type="submit">Save</button>
                                    </form>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
                <UpdateProfil data={instansi.instansi} isShow={showUpdateForm} isLogo={updatelogo} handleClose={() => setShowUpdateForm(false)} />
            </Layout>

        </>
    );
}

export default Profil;
