import { importDataConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const cekDataImport = (payload) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: importDataConstants.IMPORT_DATA_REQUEST })
        
        try {
            await axios.post(`${process.env.REACT_APP_MASTER_URI}corp/umkm/bulk`, payload)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data.status === "success") {
                        dispatch({
                            type: importDataConstants.IMPORT_DATA_SUCCESS,
                            payload: { data: data }
                        })
                        resolve(data)
                    }else{
                        dispatch({
                            type: importDataConstants.IMPORT_DATA_FAILURE,
                            payload: { error: data.message }
                        })
                        rejected(data.message)
                    }
                })
                .catch(error => { 
                    console.log('error res', error.response);
                    dispatch({
                        type: importDataConstants.IMPORT_DATA_FAILURE,
                        payload: { error: error.response.data.error }
                    })
                    rejected(error)
                })
        } catch (err) { 
            dispatch({
                type: importDataConstants.IMPORT_DATA_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const getData = (page, perPage, parm) => {
    return async (dispatch) => {
        dispatch({ type: importDataConstants.FETCH_DATA_STAKEHOLDER_REQUEST })
        const user = JSON.parse(localStorage.getItem("user"))
        const instanceId = user.user_agency.id
        var stat = '';
        // if (parm?.is_owner>=0) {
        //   stat = stat + `&is_owner=${parm.is_owner}`
        // }
        if (parm?.status) {
          let sts = `&filter[status]=${parm.status}`
          stat = stat + sts
        }
        // if (parm?.jenis) {
        //     let jns = parm.jenis==='all'?'':`&filter[program_category_id]=${parm.jenis}`
        //     stat = stat + jns;
        // }
        // if (parm?.search) {
        //   stat = stat + `&filter[search]=${parm.search}`
        // }
        
        try {
            await axios.get(`${process.env.REACT_APP_MASTER_URI}corp/umkm?instansi_id=${instanceId}&page=${page}&perPage=${perPage}${stat}`)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    const dataRes = data.result?.data??[]
                    const met = data.result;
                    delete met.data;
                    const meta = met;
    
                    dispatch({
                        type: importDataConstants.FETCH_DATA_STAKEHOLDER_SUCCESS,
                        payload: { data: dataRes, meta : meta }
                    })
                }
             })
             .catch(error => {
                dispatch({
                    type: importDataConstants.FETCH_DATA_STAKEHOLDER_FAILURE,
                    payload: { error: error }
                })
             })
        } catch (err) {
            console.log(err);
            dispatch({
                type: importDataConstants.FETCH_DATA_STAKEHOLDER_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const postData = (payload) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: importDataConstants.DATA_STAKEHOLDER_POST_REQUEST })
        try {
            const res = await axios.post(`${process.env.REACT_APP_MASTER_URI}corp/umkm`, payload)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: importDataConstants.DATA_STAKEHOLDER_POST_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                dispatch({
                    type: importDataConstants.DATA_STAKEHOLDER_POST_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: importDataConstants.DATA_STAKEHOLDER_POST_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const postDataBulk = (payload) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: importDataConstants.DATA_STAKEHOLDER_POST_REQUEST })
        try {
            const res = await axios.post(`${process.env.REACT_APP_MASTER_URI}corp/umkm/bulk`, payload)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: importDataConstants.DATA_STAKEHOLDER_POST_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                dispatch({
                    type: importDataConstants.DATA_STAKEHOLDER_POST_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: importDataConstants.DATA_STAKEHOLDER_POST_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const updateData = (payload,id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: importDataConstants.DATA_STAKEHOLDER_POST_REQUEST })
        try {
            const res = await axios.put(`${process.env.REACT_APP_MASTER_URI}corp/umkm/${id}`, payload)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: importDataConstants.DATA_STAKEHOLDER_POST_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                dispatch({
                    type: importDataConstants.DATA_STAKEHOLDER_POST_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: importDataConstants.DATA_STAKEHOLDER_POST_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const deleteData = (id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: importDataConstants.DATA_STAKEHOLDER_DELETE_REQUEST })
        try {
            const res = await axios.delete(`${process.env.REACT_APP_MASTER_URI}corp/umkm/${id}`)
            if (res.data.status === "success") {
                dispatch({
                    type: importDataConstants.DATA_STAKEHOLDER_DELETE_SUCCESS,
                    payload: { id: res.data.result.id }
                })
                resolve(res.data.status)
            } else {
                dispatch({
                    type: importDataConstants.DATA_STAKEHOLDER_DELETE_FAILURE,
                    payload: { error: res.data.message }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: importDataConstants.DATA_STAKEHOLDER_DELETE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
  })
}