import React, { useEffect, useRef, useState } from "react";
import {FaMapMarkerAlt, FaStore } from 'react-icons/fa'
import Modal from "../../../components/UI/Modal";
import moment from 'moment';
import { useTranslation } from "react-i18next";

const FormDetail = ({ isShow, handleClose, _data }) => {
  const { t } = useTranslation();
  function genJmlPegawai(val){
    switch (val) {
      case 'less_than_5':
        return 'Kurang dari 5 Orang'
      break;
      case '5_to_19':
        return '5 sampai 19 Orang'
      break;
      case '20_to_99':
        return '20 sampai 99 Orang'
      break;
      case 'more_than_100':
        return 'Lebih dari 100 Orang'
      break;
      default:
        return '-'
      break;
    }
  }
  return(
    <>
      <Modal modalTitle="Detail UMKM" size="md" show={isShow} handleClose={() => handleClose()}>        
        <div className="row">
          <div className="col">
            <div className="col-header">            
              <div className="col-detail">
                <div className="row">
                  <div className="col-md-2 col-detail-icon">       
                      {(_data?.business_logo_url == null) ?
                        <span className="span-icon"><FaStore className="me-1" /></span>
                      : <img className="img-icon" src={_data?.business_logo_url}/>}              
                  </div>
                  <div className="col-md-10 col-detail-toko">
                    <span className="span-tittle">{_data?.business_name}</span>
                    <div className="text-xs text-white taddr mb-2">
                          <FaMapMarkerAlt />
                          {
                            (_data?.latitude !== null) ?
                            <>
                              <a href={`https://maps.google.com/maps?q=${_data?.latitude},${_data?.longitude}&hl=es&z=14&amp;output=embed`} target="_blank">
                                {_data?.business_address ? _data.business_address : ''}
                              </a>  
                            </>
                            :
                            _data?.business_address ? _data.business_address : ''
                          }
                        </div>
                    <span className="span-status">{_data?.status_disabled == 0 ? 'Aktif' : 'Tidak Aktif'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-content">
              <div className="col-data">
                <span className="span-tittle">{t('industrial_sector')}</span>
                <span>{_data?.category_industry?.category}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('type_of_business')}</span>
                <span>{_data?.type_business}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('number_of_employees')}</span>
                <span>{genJmlPegawai(_data?.number_of_employees)}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('type_legal_entity')}</span>
                <span>{_data?.business_ownership}</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('last_login')}</span>
                <span>-</span>
              </div>
              <div className="col-data">
                <span className="span-tittle">{t('last_change')}</span>
                <span>-</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="float-right">
              <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
    
  )
}

export default FormDetail