import React from "react";
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { signout } from "../../../actions";
import { FaSignOutAlt,FaUserCog } from 'react-icons/fa'
import "./Navbar.css";
import avatar from "../../../assets/avatar.svg";
import LanguageSelect from "../../../languageSelect";
import { useTranslation } from "react-i18next";

const Navbar = ({ sidebarOpen, openSidebar }) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const instansi = useSelector(state => state.instansi);
  const user = localStorage.getItem('user');
  
  const logout = () => {
    dispatch(signout());
  };
  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars"></i>
      </div>
      <div className="navbar__left">
      </div>
      <div className="navbar__right">        
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className="navbar-dropdown">
            {/* <img width="30" src={instansi.instansi.cover_url} alt="avatar" className="rounded-circle"/> */}
            <span> {t("hello")}, {user && JSON.parse(user)?.name}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* <Dropdown.Item as={Link} to="/profil">
              <FaUserCog />{t("profile")}
            </Dropdown.Item> */}
            <Dropdown.Item href="#" onClick={logout}>
              <FaSignOutAlt />{t("out")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <LanguageSelect />
      </div>
    </nav>
  );
};

export default Navbar;
