import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from '../../../components/UI/Table/Table';
import { getProvince, getCity, getDistrict, getSubDistrict } from "../../../actions/filter_wilayah.actions";
import Input from "../../../components/UI/Input";
import Modal from "../../../components/UI/Modal";
import Swal from "sweetalert2";
import "./style.scss"
import { 
  FaTrash} from 'react-icons/fa'
import axios from "../../../helpers/axios";
import { useTranslation } from "react-i18next";

const UpdWilayah = ({ isShow, handleClose, handleSave, selectedData, viewState }) => {
    const { t } = useTranslation();
    const fltprovinsi = useSelector(state => state.fltwilayah.province);
    const fltcity = useSelector(state => state.fltwilayah.city);
    const fltdistrict = useSelector(state => state.fltwilayah.district);
    const fltsub_district = useSelector(state => state.fltwilayah.sub_district);
    const [province, setProvince] = useState({});
    const [city, setCity] = useState({});
    const [count, setCount] = useState(0);
    const [district, setDistrict] = useState({});
    const [subdistrict, setSubDistrict] = useState({});
    const [listArea, setListArea] = useState([]);
    const stArea = [
      {
        type:'province',
        id:0,
        name:''
      },{
        type:'city',
        id:0,
        name:''
      },{
        type:'district',
        id:0,
        name:''
      },{
        type:'subdistrict',
        id:0,
        name:''
      }
    ]
    const [area, setArea] = useState(stArea);
    const dispatch = useDispatch()

    useEffect(() => { //state Editing
      if(selectedData?.length>0){
        setListArea(selectedData);
      }
    }, [selectedData])

    useEffect(() => { 
      dispatch(getProvince())
    }, []) 

    useEffect(() => {
      setProvince([]);
      let prv = [{
        value: null,
        name: 'pilih provinsi',
        disabled:true,
        selected:true
      }];
      if (fltprovinsi.length > 0){
        fltprovinsi.map((val,i) => {
          prv.push({
            value: val.id,
            name: val.name,
          });
        })
      }
      setProvince(prv);
    }, [fltprovinsi])

    useEffect(() => {
      setCity([]);
      let cty = [{
        value: null,
        name: 'pilih kota/kabupaten',
        disabled:true,
        selected:true
      }];
      if (fltcity.length > 0){
        fltcity.map((val,i) => {
          cty.push({
            value: val.id,
            name: val.name,
          });
        })
      }
      setCity(cty);
    }, [fltcity])

    useEffect(() => {
      setDistrict([]);
      let dst = [{
        value: null,
        name: 'pilih kota/kabupaten',
        disabled:true,
        selected:true
      }];
      if (fltdistrict.length > 0){
        fltdistrict.map((val,i) => {
          dst.push({
            value: val.id,
            name: val.name,
          });
        })
      }
      setDistrict(dst);
    }, [fltdistrict])

    useEffect(() => {
      setSubDistrict([]);
      let subdst = [{
        value: null,
        name: 'pilih kelurahan/desa',
        disabled:true,
        selected:true
      }];
      if (fltsub_district.length > 0){
        fltsub_district.map((val,i) => {
          subdst.push({
            value: val.id,
            name: val.name,
          });
        })
      }
      setSubDistrict(subdst);
    }, [fltsub_district])
    
    const list_area = listArea.map((i) => {
      return {
        col1: i.name,
        col2: i.value
      };
    });
    const data_area = React.useMemo(() => list_area, [listArea]);
    const columns = React.useMemo(
      () => [
        {
          Header: t('coverage_area'),
          accessor: 'col1',
        },
        {
          Header: t('action'),
          accessor: 'col2',
          width:"5%",
          Cell: (item) => (
            !viewState &&
            <div className="btn-group">
              <button className="btn btn-sm grp-button-del" onClick={(e) => {
                removeArea(item.cell.value,item.customState.listArea)
              }}><FaTrash /></button>
            </div>
          )
        }
      ],
      [listArea, t]
    );

    const onSave = () => {
      if(listArea.length > 0){
          Swal.fire({
              title: '',
              text: t('are_you_sure'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              confirmButtonText: t('save'),
              cancelButtonColor: '#d33',
              cancelButtonText: t('re_edit'),
          
            }).then((result) => {
              if (result.isConfirmed) {
                  handleSave(listArea)
              }
            })
      }else{
        Swal.fire({
            title: '',
            text: "Anda belum memilih area jangkauan?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: t('re_edit'),
            cancelButtonColor: '#d33',
            cancelButtonText: t('exit'),
        
          }).then((result) => {
            if (!result.isConfirmed) {
              handleSave(listArea)
            }
          })
      }
    }
  
    const handleArea = (key,e) => {
      let index = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[index].text;
      let value = e.target.value;
      //mengubah selected area
      var stateCopy = area;
      stateCopy[key].id= parseInt(value);
      stateCopy[key].name= label;
      setArea(stateCopy);
      setCount(count+1);
    }

    const removeArea = (deleteAreaId, list_area) => {
      const newArea = list_area.filter((area) => area.value !== deleteAreaId);
      setListArea( newArea );
    }

    function txtList(val){
      var txt = ''
      switch (val.type) {
        case 'province':
          txt = 'Provinsi '
        break;
        case 'city':
          txt = ``
        break;
        case 'district':
          txt = `Kecamatan `
        break;
        case 'subdistrict':
          txt = `Kelurahan `
        break;    
      }
      return txt+val.name;
    }

    function addList(parm){
      //mengubah selected area
      setListArea(listArea => [...listArea, {
        type:parm.type,
        name:txtList(parm),
        value:parm.id
      }]);
      
      setCount(count+1);
    }

    const loadData = async(parm) => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}subdistricts/showbymulti/get?${parm}`)
        if (res.data.status == "success") {
            const sub_district = res.data.result
            setListArea(sub_district)
        }
      } catch (err) {
        console.log(err);
      }
    }
  return(
    <>
      <Modal modalTitle="Filter Berdasarkan Wilayah" size="lg" show={isShow} handleClose={() => handleClose()}>
        <label className="mdl-desk">{viewState?'Berikut ':'Tentukan '} wilayah spesifik yang bisa mengikuti program</label>
        <div className="row">
          <div className="col">    
            <div className="row">
              {!viewState &&
                <>
                <div className="col-md-12 grp-slc">  
                    <Input
                      type="select"
                      label="Provinsi"
                      name="provinsi"
                      options={province}
                      onChange={(e) => {
                        dispatch(getCity(e.target.value));
                        dispatch(getDistrict(-1));
                        handleArea(0,e);
                      }}
                    />
                    <button className="btn btn-sm grp-button-bulk" onClick={() => addList(area[0])}>Tambahkan Provinsi ini </button>
                </div>
                <div className="col-md-12 grp-slc">  
                    <Input
                      type="select"
                      label="Kota/Kabupaten"
                      name="kota"
                      options={city}
                      onChange={(e) => {
                        dispatch(getDistrict(e.target.value));
                        dispatch(getSubDistrict(-1));
                        handleArea(1,e)
                      }}
                    />
                    <button className="btn btn-sm grp-button-bulk"  onClick={() => addList(area[1])}>Tambahkan Kota/Kabupaten ini </button>
                </div>
                <div className="col-md-12 grp-slc">  
                    <Input
                      type="select"
                      label="Kecamatan"
                      name="kec"
                      options={district}
                      onChange={(e) => {
                        dispatch(getSubDistrict(e.target.value));
                        handleArea(2,e)
                      }}
                    />
                    <button className="btn btn-sm grp-button-bulk" onClick={() => addList(area[2])}>Tambahkan Keluarahan/Desa ini </button>
                </div>
                <div className="col-md-12 grp-slc">  
                    <Input
                      type="select"
                      label="Keluarahan/Desa"
                      name="postal"
                      multiple="multiple"
                      options={subdistrict}
                      onChange={(e) => {
                        handleArea(3,e)
                      }}
                    />  
                    <button className="btn btn-sm grp-button-bulk" onClick={() => addList(area[3])}>Tambahkan Keluarahan/Desa ini </button>
                </div>
                </>
              }
              <div className="col-md-12"> 
                <DataTable
                  data={data_area}
                  columns={columns}
                  customState={{listArea:listArea??[]}}
                />
              </div> 
            </div>
            <div className="float-right">
              {!viewState?
                <>
                <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
                <button className="btn btn-sm button-green" onClick={() => onSave()}>{t('save')}</button>
                </>
                :
                <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
              }      
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdWilayah