import React, { useEffect, useState } from 'react';
import { login } from '../../../actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import './style.scss';
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import logo from "../../../assets/logo1.svg";
import logo2 from "../../../assets/logo2.svg";
import illustationLogin from "../../../assets/illustration_login.svg";
import Loading from '../../../components/UI/Loading';
/**
* @author
* @function Signin
**/

const Signin = (props) => {
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [loadingauth, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const userLogin = (e) => {
        e.preventDefault();

        const user = {
            username, password
        }
        setLoading(true)
        dispatch(login(user))
      .then((res) => {
      })
      .catch((err) => {
        setLoading(false)
        Swal.fire({
          text: t('wrong_username_or_password'),
          icon: 'error',
          confirmButtonColor: '#FCB630',
          confirmButtonText: t('closed')
        })
      })
    }

    if (auth.authenticate) {
      if (auth.user.user_instansi[0].level === 'superadmin') {
        return <Redirect to={`/agency-submission`} />
      }
      return <Redirect to={`/`} />
    }

    return (
      <div className="min-vh-100 d-flex flex-column">
        {loadingauth && <Loading/>}
        <div className="d-flex justify-content-between px-5 py-2 mb-2" style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
          <img src={logo} />
        </div>
        <div className="d-flex flex-row flex-grow-1">
          <div className="flex-column w-100 align-items-center pt-5 d-xs-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block" style={{ background: '#fbfbfb' }} >
            <center><img src={illustationLogin} /></center>
            <center><h4>Hi, Welcome to SMEspace</h4></center>
            <center><p className="text-center">Platform for you entrepreneurs in<br /> monitoring contracts, cooperation and negotiations with fellow entrepreneurs or stakeholders</p></center>
          </div>
          <div className="flex-shrink-1 justify-content-center mt-5">
            <div className="d-flex flex-column px-5 mx-5">
              <center><img className="mb-5" src={logo2} style={{ width: 360 }} /></center>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h3>Login</h3>
                <p>Please login with your account</p>
                <form className="mt-2" onSubmit={userLogin}>
                  <label>Email</label>
                  <input 
                    className="form-control d-inline mb-3" 
                    type="text" 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label>Password</label>
                  <input 
                    className="form-control d-inline mb-5" 
                    type="password" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}/>
                  <button className="btn btn-signin font-bold" type="submit">Sign In</button>
                  <div className="d-flex flex-row mt-5">
                    <span>Don't have account?</span>&nbsp;&nbsp;
                    <a href="/signup">Register here</a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )    
}

export default Signin