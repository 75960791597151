/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from "../../components/UI/Table/Table";
import Input from "../../components/UI/Input";
import Modal from "../../components/UI/Modal";
import { setType, setNumberFormat } from "../Stakeholder/fnc_var";
import { renderOwnership, renderEmployees, renderStatus } from "../Stakeholder/fnc_var";
import { getCategory_Industry } from "../../actions/category_industry.actions";
import Swal from "sweetalert2";
import "./style.scss";
import {
  FaSearch,
  FaTrash,
  FaEye,
  FaRegMinusSquare,
  FaRegCheckSquare,
  FaRegSquare,
} from "react-icons/fa";
import {
  postDataPeserta,
  getListParticipant,
} from "../../actions/stakeholder-program.actions";
import { useTranslation } from "react-i18next";

const UpdOkr = ({ isShow, handleClose, selectedData }) => {
  const { t } = useTranslation();
  // const fltokrlevel = useSelector(state => state.fltOkr.levels);
  // const fltokrpaket = useSelector(state => state.fltOkr.paket);
  // const fltokrkelompok = useSelector(state => state.fltOkr.kelompok);
  // const fltcategoryIndustri = useSelector(state => state.fltOkr.kategori);
  const category_data = useSelector((state) => state.category_industry);
  const ownership = {
    "-": "-",
    pt: "PT",
    cv: "CV",
    firma: "Firma",
    ud: "UD",
    koperasi: "Koperasi",
    yayasan: "Yayasan",
    individual: "Perorangan",
  };
  const listOkr = useSelector(
    (state) => state.stakeholderProgram.dpwParticipant
  );
  const [tempOkr, setTempOkr] = useState([]);

  const [okrlevel, setOkrLevel] = useState({});
  const [okrpaket, setOkrPaket] = useState({});
  const [count, setCount] = useState(0);
  const [okrkelompok, setOkrKelompok] = useState({});
  const [categoryIndustri, setCategoryIndustri] = useState([]);
  const [slcOkr, setSelectOkr] = useState([]);
  const [search, setSearch] = useState("");
  const [head, setHead] = useState(<FaRegSquare size={25} />);
  const [peserta_flt, setPesertaFlt] = useState([
    "semua",
    "semua",
    "semua",
    "semua",
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isShow) {
      loadData("");
      setSelectOkr([]);
      setPesertaFlt(["semua", "semua", "semua", "semua"]);
      dispatch(getCategory_Industry());
      setHead(
        <button
          className="btn btn-sm btn-check"
          onClick={() => console.log("check all")}
        >
          <FaRegSquare size={25} />
        </button>
      );
    }
  }, [dispatch, isShow, loadData]);

  useEffect(() => {
    setCheckedHead(slcOkr, listOkr);
  }, [listOkr, slcOkr]);

  // useEffect(() => {
  //   setOkrPaket([]);
  //   let okrpkt = [{
  //     value: 'semua',
  //     name: 'Semua',
  //     selected:true
  //   }];
  //   if (fltokrpaket.length > 0){
  //     fltokrpaket.map((val,i) => {
  //       okrpkt.push({
  //         value: val.id,
  //         name: val.okr_name,
  //       });
  //     })
  //   }
  //   setOkrPaket(okrpkt);
  // }, [fltokrpaket])

  // useEffect(() => {
  //   setOkrKelompok([]);
  //   let okrklmp = [{
  //     value: 'semua',
  //     name: 'Semua',
  //     selected:true
  //   }];
  //   if (fltokrkelompok.length > 0){
  //     fltokrkelompok.map((val,i) => {
  //       okrklmp.push({
  //         value: val.id,
  //         name: val.name,
  //       });
  //     })
  //   }
  //   setOkrKelompok(okrklmp);
  // }, [fltokrkelompok])

  useEffect(() => {
    setCategoryIndustri([]);
    let okrktg = [
      {
        value: "semua",
        name: "Semua",
        selected: true,
      },
    ];
    if (category_data?.category_industry.length > 0) {
      category_data.category_industry.map((val, i) => {
        okrktg.push({
          value: val.id,
          name: val.category,
        });
      });
    }
    setCategoryIndustri(okrktg);
  }, [category_data]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list_okr = listOkr?.data?.map((i) => {
    return {
      col1: i.id,
      col2: i.nik,
      col3: i.business_name,
      col4: i.name,
      col5: i.business_tlp,
      col6: i.business_category?.category ?? "-",
      col7: ownership[i.business_ownership ?? "-"],
      col8: i.number_of_employee ?? "-",
      col9: "Rp. " + setNumberFormat(i.business_omset ?? 0),
    };
  });
  const data_okr = React.useMemo(() => list_okr, [list_okr]);
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        textAlign: "center",
        accessor: "col1",
        width: "2%",
        Cell: (item) => (
          <input type="checkbox"checked={item?.customState?.slcOkr.find((val) => val.id === item.cell.value)} onChange={(e) => {setCheckedBody(item.cell.value, item)}}/>
        )
      },
      {
        Header: t("id_number"),
        accessor: "col2",
        width: "11%",
      },
      {
        Header: t("business_name"),
        accessor: "col3",
        width: "15%",
      },
      {
        Header: t("owners_name"),
        accessor: "col4",
        width: "15%",
      },
      {
        Header: t('business_contact'),
        accessor: "col5",
        width: "5%",
      },
      {
        Header: t("business_category"),
        accessor: "col6",
      },
      {
        Header: t('type_business_entity'),
        accessor: "col7",
        width: "5%",
      },
      {
        Header: t("numbers_of_employee"),
        accessor: "col8",
        width: "5%",
      },
      {
        Header: t("turnover"),
        accessor: "col9",
        width: "11%",
      },
    ],
    [setCheckedBody, t]
  );

  const onSave = () => {
    var umkm = [];
    slcOkr.map((val, index) => {
      umkm.push(val.id);
    });
    if (umkm.length > 0) {
      dispatch(postDataPeserta({ program_id: selectedData, umkm: umkm }))
        .then((res) => {
          handleClose(true);
        })
        .catch((err) => {
          Swal.fire({
            title: "Error!",
            text: "Failed to save data, check if there is an error or incomplete data",
            icon: "error",
            confirmButtonColor: "#FCB630",
            confirmButtonText: "Okay",
          });
        });
    } else {
      Swal.fire({
        title: "Error!",
        text: "Anda belum memilih data",
        icon: "error",
        confirmButtonColor: "#FCB630",
        confirmButtonText: "Okay",
      });
    }
  };

  const handlePesertaFlt = (key, val) => {
    //mengubah selected filter
    var stateCopy = peserta_flt;
    stateCopy[key] = val;
    setPesertaFlt(stateCopy);
  };

  useEffect(() => {
    if (isShow) {
      loadData();
    }
  }, [isShow, loadData, page, perPage, search]);

  const loadData = useCallback(async () => {
    const jenis =
      peserta_flt[0] !== "semua"
        ? `&filter[business_ownership]=${peserta_flt[0]}`
        : "";
    const karyawan =
      peserta_flt[1] !== "semua"
        ? `&filter[number_of_employees]=${peserta_flt[1]}`
        : "";
    const kategori =
      peserta_flt[2] !== "semua"
        ? `&filter[business_category]=${peserta_flt[2]}`
        : "";
    const status =
      peserta_flt[3] !== "semua" ? `&filter[status]=${peserta_flt[3]}` : "";
    const src = search === "" ? "" : `&search=${search}`;
    let parm = {
      program: selectedData,
    };
    dispatch(
      getListParticipant(
        page,
        perPage,
        parm,
        `${jenis}${karyawan}${status}${kategori}${src}`
      )
    ).then((response) => {
      if (response?.data?.status == "success") {
        loadTempOkr(response?.data?.result.data);
      }
    });
  });

  function setCheckedHead(slOkr, lstOkr) {
    var svg = <FaRegSquare size={25} />;
    if (slOkr.length > 0 && slOkr.length == lstOkr.length) {
      svg = <FaRegCheckSquare size={25} />;
    } else if (slOkr.length > 0) {
      svg = <FaRegMinusSquare size={25} />;
    }
    setHead(
      <button
        className="btn btn-sm btn-check"
        onClick={() => console.log("check all")}
      >
        {svg}
      </button>
    );
  }

  function setArrayOkr(data) {
    const data_okr = {
      okr_category_group: {
        id: data.indicator.objective.category.okr_category_group_id,
        name: data.indicator.objective.category.okr_category_group?.name ?? "",
        description:
          data.indicator.objective.category.okr_category_group?.description ??
          "",
      },
      okr_category: {
        id: data.indicator.objective.category.id,
        category_name: data.indicator.objective.category.category_name,
        category_description:
          data.indicator.objective.category.category_description,
      },
      okr_objective: {
        id: data.indicator.objective.id,
        objective_name: data.indicator.objective.objective_name,
        objective_description: data.indicator.objective.objective_description,
      },
      okr_indicator: {
        id: data.indicator.id,
        indicator_name: data.indicator.indicator_name,
        indicator_description: data.indicator.indicator_description,
      },
      okr_task: {
        id: data.id,
        task_name: data.task_name,
        task_description: data.task_description,
        task_type: data.task_type,
        task_point: data.task_point,
        okr_indicator_id: data.okr_indicator_id,
        exam_id: data.exam_id,
        task_level: data.task_level,
      },
    };

    return data_okr;
  }

  function loadTempOkr(data) {
    const idmap = tempOkr?.map((item) => {
      return item.id;
    });
    var newTempOkr = tempOkr?.length > 0 ? tempOkr : [];
    let arr = [];
    if (data?.length > 0) {
      arr = data?.filter(function (item) {
        return idmap.indexOf(item.id) === -1;
      });
      arr.length > 0 &&
        arr.map((val, i) => {
          newTempOkr.push(val);
        });
    }
    setTempOkr(newTempOkr);
    setCount(count + 1);
  }

  const setCheckedBody = (params, item) => {
    var d = item?.customState?.slcOkr;
    var list = item?.customState?.tempOkr;
    var data = item?.customState?.tempOkr.find((item) => item.id === params);
    const found = d.find((element) => element.id === params);
    if (!found) {
      var list_data = list.filter((item) => item.id === data.id); //cek level satu indicator
      d.push(data);
    } else {
      var index = d.findIndex((itm) => itm.id === data.id);
      if (index === 0) {
        d.shift();
      } else {
        d.splice(index, 1);
      }
    }
    setSelectOkr(d);
    setCheckedHead(item?.customState?.slcOkr, item?.data);
  };

  return (
    <>
      <Modal
        modalTitle="Daftar Pengusaha"
        size="xl"
        show={isShow}
        handleClose={() => handleClose()}
      >
        <label className="mdl-desk">
          Berikut adalah List Pengusaha, anda dapat memilih beberapa pengusaha
          untuk menjadi penerima manfaat program
        </label>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-3">
                <div className="col-wrapper">
                  <div className="text-start">
                    <div className="search-wrapper">
                      <input
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        id="search-input"
                        placeholder="Cari Pengusaha.."
                        type="text"
                      />
                      <button className="btn btn-sm btn-outline-secondary mt-1">
                        <FaSearch className="me-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <Input
                  type="select"
                  label="Kategori Industri"
                  name="kategori"
                  options={categoryIndustri}
                  onChange={(e) => {
                    handlePesertaFlt(2, e.target.value);
                    loadData();
                  }}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="select"
                  label={t('type_business_entity')}
                  name="jenis"
                  options={renderOwnership("", true)}
                  onChange={(e) => {
                    handlePesertaFlt(0, e.target.value);
                    loadData();
                  }}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="select"
                  label={t("numbers_of_employee")}
                  name="karyawan"
                  options={renderEmployees("", true)}
                  onChange={(e) => {
                    handlePesertaFlt(1, e.target.value);
                    loadData();
                  }}
                />
              </div>
              <div className="col-md-2">
                <Input
                  type="select"
                  label={t("status")}
                  name="status"
                  options={renderStatus("", true)}
                  onChange={(e) => {
                    handlePesertaFlt(3, e.target.value);
                    loadData();
                  }}
                />
              </div>
              <div className="col-md-12">
                <DataTable
                  data={data_okr ?? []}
                  loading={false}
                  customState={{ slcOkr: slcOkr ?? [], tempOkr: tempOkr ?? [] }}
                  cstmHead={head}
                  columns={columns}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  currentpage={page}
                  perPage={perPage}
                  totalPage={listOkr?.total}
                  noPaging={true}
                />
              </div>
            </div>
            <div className="float-right">
              <>
                <button
                  className="btn btn-sm button-primary mr-2"
                  onClick={() => handleClose()}
                >
                  {t('cancelled')}
                </button>
                <button
                  className="btn btn-sm button-green"
                  onClick={() => onSave()}
                >
                  Tambahkan
                </button>
              </>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdOkr;
