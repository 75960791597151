import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import Input from "../../../components/UI/Input";
import ErrorRes from "../../../components/UI/ErrorRes";
import Switch from "../../../components/UI/Switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { postProgram, editProgram } from "../../../actions/program.actions";
import ImgHp from "../../../assets/appon/img_hp.png";

import UpdKelas from "./updKelas";
import UpdImage from "./updImage";
import JenisIndustri from "./JenisIndustri";
import UpdWilayah from "./updWilayah";
import UpdOkr from "./updOkr";
import UpdKolab from "./updKolaborator";

import { 
  FaAngleRight} from 'react-icons/fa'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import Moment from 'moment';
import { useTranslation } from "react-i18next";

const FormProgram = ({ handleBack, editState }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.auth)
  const error = useSelector(state => state.program.error);
  const [errorTemp, setErrorTemp] = useState([]);
  const [count, setCount] = useState(0);
  const date_end = new Date();
  //inisialisasi formData
  const program_form = {
    program_name        : editState?.program_name??'',
    program_image       : editState?.program_image??'',
    cb_collab           : editState?.program_owners?.length>1?true:false,
    program_owners      : [{
      user_id: user?.user?.id,
      instansi_id: user?.user?.user_instansi?user?.user?.user_instansi[0].instansi_id:0,
      is_owner:1
    }],
    program_category_id : editState?.program_category?.id??null,
    program_description : editState?.program_description??null,
    program_start       : new Date(editState?.program_start??new Date()),
    program_end         : new Date(editState?.program_end??new Date(date_end.getTime() + 10*60000)),
    kuota               : editState?.participant_quota==null?'takterbatas':'terbatas',
    participant_quota   : editState?.participant_quota??null,
    budget_show         : editState?.budget_show>0?'tampil':'tidaktampil',
    participant_budget  : editState?.participant_budget??0,
    cb_jenis            : editState?.program_filters.find(item => item.filter_type === 'industry')?true:false,
    jenis_industri      : [],
    cb_wilayah          : editState?.program_filters.find(item => item.filter_type === 'zone')?true:false,
    wilayah             : [],
    cb_kelas            : editState?.program_filters.find(item => item.filter_type === 'class')?true:false,
    kelas               : [],
    cb_okr              : true, //wajib minimal memiliki 1 okr
    program_filters     : [],
    program_filter_okrs : editState?.program_filter_okrs??[],
    program_filter_kolab: [],
    status              : editState?.status??'draft',

  };

  let wil = editState?.program_filters.find(item => item.filter_type === 'zone')
  if (wil) {
    program_form.wilayah = wil?.filter_value
  }

  let ind = editState?.program_filters.find(item => item.filter_type === 'industry')
  if (ind) {
    program_form.jenis_industri = ind?.filter_value
  }

  let kls = editState?.program_filters.find(item => item.filter_type === 'class')
  if (kls) {
    program_form.kelas = kls?.filter_value
  }

  if (program_form.cb_collab) {
    let owners = [];
    editState &&
    editState.program_owners.map((val,index) => {
      if(val.is_owner==0){
        owners.push({
          id: val.instansi_id,
          name: val.instansi.name,
          cover:val.instansi.cover_url
        })
      }
    })
    program_form.program_filter_kolab = owners;
  }

  //......... end of inisialisasi formData

  const stat = [{
    value:'',
    name:'Pilih Status',
    disabled:true,
    selected:true
  },{
    value:'draft',
    name: t('draft')
  },
  {
    value:'scheduled',
    name: t('scheduled')
  },
  {
    value:'progress',
    name: t('on_progress')
  },
  {
    value:'complete',
    name: t('done')
  },
  {
    value:'suspend',
    name: t('on_hold')
  }]

  const jenis = [{
    value:'',
    name: t('select_program_type'),
    disabled:true,
    selected:true
  },{
    value:'1',
    name: t('loan_funding')
  },
  {
    value:'2',
    name: t('grant_funding')
  },
  {
    value:'3',
    name: t('competition_funding')
  },
  {
    value:'4',
    name: t('event')
  },
  {
    value:'5',
    name: t('trainig')
  },
  {
    value:'6',
    name: t('procurement')
  }]

  const [program, setProgram] = useState(program_form) 
  const [loading, setLoading] = useState(false);
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [showUpdateKelas, setShowUpdateKelas] = useState(false);
  const [showJenisIndustri, setShowJenisIndustri] = useState(false);
  
  const [showUpdateWilayah, setShowUpdateWilayah] = useState(false);
  const [showUpdateOkr, setShowUpdateOkr] = useState(false);
  const [showUpdateKolab, setShowUpdateKolab] = useState(false);

  const dispatch = useDispatch()
  const [editorState, setEditorState] = useState(null);
  useEffect(() => {
    if(editState?.program_description){
      const blocksFromHTML = convertFromHTML(editState?.program_description);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [])

  useEffect(() => {
    if (program.program_end<program.program_start) {
      Swal.fire({
        title: t('error'),
        text: t('finish_time_cannot_be_less_than_start_time'),
        icon: 'error',
        confirmButtonColor: '#FCB630',
        confirmButtonText: t('closed')
      })
      
      setProgram({...program, 'program_end': new Date(program.program_start.getTime() + 10*60000)});
    }
  }, [program, program.program_end, t])

  useEffect(() => {
    if (program.program_end<program.program_start) {      
      setProgram({...program, 'program_end': new Date(program.program_start.getTime() + 10*60000)});
    }
  }, [program, program.program_start])
  
  const handleChange = e => {
    const {name, value} = e.target;
    setProgram({...program, [name]: value});
  }

  const handleChangeSw = e => {
    const {name, value} = e.target;
    const val = program[name] == false?true:false;
    setProgram({...program, [name]: val});
  }

  const handleCancel = () => {
    if (editState) { //state editing
      Swal.fire({
        title: '',
        text: t('back_to_program'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: t('closed'),
        cancelButtonColor: '#d33',
        cancelButtonText: t('re_edit'),
    
      }).then((result) => {
        if (result.isConfirmed) {
          handleBack()
        }
      })      
    } else {
      Swal.fire({
        title: '',
        text: t('form_reloaded'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: t('delete_confirmation_yes'),
        cancelButtonColor: '#d33',
        cancelButtonText: t('re_edit'),
    
      }).then((result) => {
        if (result.isConfirmed) {
            setProgram(program_form)
        }
      })  
    }
  }

  const handleSaveAll = () => {
    var newProgram = {};
    newProgram.program_name       = program['program_name'];
    const content_desk = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    newProgram.program_description= content_desk;
    newProgram.program_category_id= program['program_category_id'];
    if(program['program_image'].indexOf('base64') !== -1){
      newProgram.program_image      = program['program_image'];
    }
    newProgram.program_start      = Moment(program['program_start']).format('YYYY-MM-DD HH:mm:00');
    newProgram.program_end        = Moment(program['program_end']).format('YYYY-MM-DD HH:mm:00');
    newProgram.participant_budget = program['participant_budget'];
    newProgram.participant_quota  = program['kuota']=='terbatas'?program['participant_quota']:null;
    newProgram.budget_show        = program['budget_show']=='tampil'?true:false;
    newProgram.status             = program['status'];

    //program owner
    var flt_owners = [];
    if (editState) {
      let own = editState?.program_owners.find(item => item.is_owner === 1)
      flt_owners.push({
        user_id: own.user_id,
        instansi_id: own.instansi_id,
        is_owner:1
      })
    } else {
      flt_owners = program['program_owners']
    }

    if(program['cb_collab']&&program['program_filter_kolab']?.length>0){
      program['program_filter_kolab'].map((val,index) => {
        flt_owners.push({
          user_id: null,
          instansi_id: val.id,
          is_owner:0
        })
      })
    }
    newProgram.program_owners      = flt_owners;

    //program filters
    var program_filters = [];
    var flt_zone = [];
    if(program['cb_wilayah']&&program['wilayah']?.length>0){
      flt_zone = {
        filter_type  : "zone",
        filter_value : program['wilayah']
      }
      program_filters.push(flt_zone);
    }

    var flt_industry = [];
    if(program['cb_jenis']&&program['jenis_industri']?.length>0){
      flt_industry = {
        filter_type  : "industry",
        filter_value : program['jenis_industri']
      }
      program_filters.push(flt_industry);
    }

    var flt_class = [];
    if(program['cb_kelas']&&program['kelas']?.length>0){
      var kls = [];
      program['kelas'].map((val,index) => {
        kls.push(val.id)
      })
      flt_class = {
        filter_type  : "class",
        filter_value : kls
      }
      program_filters.push(flt_class);
    }    

    newProgram.program_filters             = program_filters;
    
    //program okr
    var flt_okr = [];
    if(program['cb_okr']&&program['program_filter_okrs']?.length>0){
      var okrList = program['program_filter_okrs'];
      okrList.map((val,i) => {
        flt_okr.push({
          id : val.okr_task.id,
          okr_category_group_id : val.okr_category_group.id,
          okr_category_id : val.okr_category.id,
          okr_indicator_id : val.okr_indicator.id,
          okr_objective_id : val.okr_objective.id,
          okr_task_id : val.okr_task.id
        })
      })
    }
    newProgram.program_filter_okrs         = flt_okr;
    Swal.fire({
      title: '',
      text: t('are_you_sure'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: t('save'),
      cancelButtonColor: '#d33',
      cancelButtonText: t('re_edit'),
  
    }).then((result) => {
      if (result.isConfirmed) {
        //cek list okr, minimal pilih 1
        if(program['program_filter_okrs']?.length==0){
          setErrorTemp({...errorTemp, 'program_okr': [t('at_least_1_okr')]});
          
          Swal.fire({
            title: t('error'),
            text: t('failed_save_program'),
            icon: 'error',
            confirmButtonColor: '#FCB630',
            confirmButtonText: t('closed')
          })
          return;
        } else {
          setLoading(true);
          if (!editState) {
            dispatch(postProgram(newProgram))
            .then((res) => {      
              setLoading(false);      
              Swal.fire({
                title: t('success'),
                text: t('program_registered'),
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: t('closed'),
                cancelButtonColor: '#d33',
                cancelButtonText: t('create_new_program'),
              }).then((res) => {
                if (result.isConfirmed) {
                  window.location = "/program-data";
                }else{
                  setProgram(program_form);
                }
              });
            })
            .catch((err) => {
              setLoading(false);
              var msg = '';
              if(err instanceof Array){
                msg = 'Gagal membuat Program baru, cek apakah ada data yang belum lengkap <br>';
                Object.keys(err).map((keyName, txt) => {
                  err[keyName].map((val, i) => {
                    msg = msg + '<span style="font-size:12px;color:#053DC8"> - '+val+'</span>' +'<br>'
                  })
                });
              } else {
                msg = t('something_went_wrong');
              }

              Swal.fire({
                title: t('error'),
                html: msg,
                icon: 'error',
                confirmButtonColor: '#FCB630',
                confirmButtonText: t('closed')
              })
            }
            )
          } else {
            dispatch(editProgram(newProgram,editState?.id))
            .then((res) => {
              setLoading(false);        
              Swal.fire({
                title: t('success'),
                text: t('program_modified'),
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: t('closed'),
                cancelButtonColor: '#d33',
                cancelButtonText: t('create_new_program'),
              }).then((res) => {
                if (result.isConfirmed) {
                  window.location = "/program-data";
                }else{
                  setProgram(program_form);
                }
              });
            })
            .catch((err) => {
              setLoading(false);
              Swal.fire({
                title: 'Error!',
                text: 'Gagal mengubah program, cek apakah ada data yang belum lengkap',
                icon: 'error',
                confirmButtonColor: '#FCB630',
                confirmButtonText: t('closed')
              })
            }
            )
          }
        }
          
      }
    })
  }

  return (
    <>
    <Layout loading={loading}>
    <div className="row table-view">
      <div className="col">
        <div className="row">
          <div className="col-md-4">
            <div className="det-content1">
              <div className="det-tittle">
                  <span className="span-tittle">
                      {t('banner_program')}
                  </span>
              </div>
              <div className="det-image">
                <img className="img d-block" src={ImgHp} alt="appon image" />
                {program?.program_image?
                  <img className="img-banner" src={program.program_image} alt="logo" />:<div className="img-banner" />}   
                  <ErrorRes
                    error={error?.program_image}
                  />
              </div>
              
              <div className="det-detail">
                <button className="btn btn-detail" onClick={() => {setShowUpdateImage(true) }}>
                  {t('upload_banner')}
                </button>
                <div className="det-txt">
                  <span className="span-type">
                    tipe file : jpg, jpeg, png
                  </span>
                  <span className="span-type">
                    ukuran : 540x675px (maksimal 1 MB)
                  </span>
                  <span className="span-ket">
                      Banner ini akan tampil dan dilihat calon peserta pada fitur Program di apliaksi AppOn
                  </span>
                </div>
              </div>
            </div>
          </div>   
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                  <Input
                    type="input"
                    label={t('program_name')}
                    name="program_name"
                    error={error?.program_name}
                    value={program.program_name}
                    onChange={handleChange}
                  />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('collaboration_program')}</span>
                  <div className="collect-group">
                    <Switch
                      name="cb_collab"
                      value={program.cb_collab}
                      onChange={handleChangeSw}
                    />
                    <button className="btn btn-collect" disabled={!program.cb_collab} onClick={() => {setShowUpdateKolab(true) }}>
                      <div className="collect-text">
                        <span className="btn-title">Kolaborator, mitra atau rekan yang akan berkolaborasi dalam program ini</span>
                        <span className="btn-desk">{program?.program_filter_kolab?.length??0} instansi berkolaborasi</span>
                      </div>
                      <FaAngleRight />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                  <Input
                    type="select"
                    label={t('program_type')}
                    name="program_category_id"
                    error={error?.program_category_id}
                    options={jenis}
                    value={program.program_category_id}
                    onChange={handleChange}
                  />
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('description')}</span>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'history'],
                      inline: { inDropdown: false },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="dt-picker">
                  <span className="span-desk">{t('early_period')}</span>
                  <DatePicker
                    dateFormat="dd MMMM yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={program.program_start}
                    onChange={(date) => {
                      setProgram({...program, 'program_start': date});
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="dt-picker">
                  <span className="span-desk">{t('end_period')}</span>
                  <DatePicker
                    dateFormat="dd MMMM yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={program.program_end}
                    onChange={(date) => {
                      setProgram({...program, 'program_end': date});
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('participant_quota')}</span>
                  <label key="kuota1" className="rad-kuota">
                    <input 
                        type="radio"
                        className="rad-green"
                        checked={program.kuota == "takterbatas"? true: false}
                        name="kuota"
                        onChange={handleChange}
                        value="takterbatas" />
                    {t('unlimited')}
                  </label>
                  <label key="kuota2" className="rad-kuota">
                    <input 
                        type="radio"
                        className="rad-green"
                        checked={program.kuota == "terbatas"? true: false}
                        name="kuota"
                        onChange={handleChange}
                        value="terbatas" />
                    {t('limited')}
                    <Input
                      type="number"
                      placeholder={t('maximum')}
                      name="participant_quota"
                      disabled={program.kuota !== "terbatas"}
                      value={program.participant_quota}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('estimated_amount')}</span>
                  <div className="total-group">
                    <span>{t('total_estimate')} RM</span>
                    <Input
                      type="currency"
                      placeholder={t('total_estimate') +".."}
                      name="participant_budget"
                      error={error?.participant_budget}
                      value={program.participant_budget}
                      decimal={2}
                      default={0}
                      onChange={(val)=>setProgram({...program, 'participant_budget': val})}
                    />
                  </div>
                  <label key="anggaran1" className="rad-kuota">
                    <input 
                        type="radio" 
                        checked={program.budget_show == "tampil"? true: false}
                        name="budget_show"
                        onChange={handleChange}
                        value="tampil" />
                    {t('show_information_participants')}
                  </label>
                  <label key="anggaran2" className="rad-kuota">
                    <input 
                        type="radio" 
                        checked={program.budget_show == "tidaktampil"? true: false}
                        name="budget_show"
                        onChange={handleChange}
                        value="tidaktampil" />
                    {t('dont_show_information_participants')}
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <span className="span-desk">{t('program_requirements')}</span><br />
                <span className="span-detail">{t('desc_program_requirements')}</span>
                <div className="row row-detail">
                  <div className="col-md-12">
                    <div className="form-group">
                      <span className="span-desk">{t('requirements_before_joining_the_program')}</span>
                      <span className="span-detail">{t('desc_requirements_before')}:</span>
                      <div className="collect-group">
                        <Switch
                          name="cb_jenis"
                          value={program.cb_jenis}
                          onChange={handleChangeSw}
                        />
                        <button className="btn btn-collect"  disabled={!program.cb_jenis} onClick={() => {setShowJenisIndustri(true) }}>
                          <div className="collect-text">
                            <span className="btn-title">Jenis Industri</span>
                            <span className="btn-desk">{program?.jenis_industri?.length??0} jenis industri dipilih</span>
                          </div>
                          <FaAngleRight />
                        </button>
                      </div>
                      <div className="collect-group">
                        <Switch
                          name="cb_wilayah"
                          value={program.cb_wilayah}
                          onChange={handleChangeSw}
                        />
                        <button className="btn btn-collect"  disabled={!program.cb_wilayah} onClick={() => {setShowUpdateWilayah(true) }}>
                          <div className="collect-text">
                            <span className="btn-title">Wilayah</span>
                            <span className="btn-desk">{program?.wilayah?.length??0} Wilayah terpilih</span>
                          </div>
                          <FaAngleRight />
                        </button>
                      </div>
                      <div className="collect-group">
                        <Switch
                          name="cb_kelas"
                          value={program.cb_kelas}
                          onChange={handleChangeSw}
                        />
                        <button className="btn btn-collect"  disabled={!program.cb_kelas} onClick={() => {setShowUpdateKelas(true) }}>
                          <div className="collect-text">
                            <span className="btn-title">Kelas</span>
                            <span className="btn-desk">{program?.kelas?.length??0} kelas dipilih</span>
                          </div>
                          <FaAngleRight />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <span className="span-desk">{t('requirements_after_joining_the_program')}</span>
                      <span className="span-detail">{t('desc_requirements_before')}</span>
                      <div className="collect-group">
                        {/* <Switch
                          name="cb_okr"
                          value={program.cb_okr}
                          alwaysOn={true}
                        /> */}
                        <button className="btn btn-collect" disabled={!program.cb_okr} onClick={() => {setShowUpdateOkr(true) }}>
                          <div className="collect-text">
                            <span className="btn-title">OKR</span>
                            <span className="btn-desk">{program?.program_filter_okrs?.length??0} OKR dipilih</span>
                            <ErrorRes
                              error={errorTemp?.program_okr}
                            />
                          </div>
                          <FaAngleRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
              </div>
              <div className="col-md-12">
                  <Input
                    type="select"
                    label={t("status")}
                    name="status"
                    options={stat}
                    value={program.status}
                    onChange={handleChange}
                  />
              </div> 
              <div className="col-md-12">
                <div className="btn-ctrl-form">
                  <button className="btn btn-batal"  onClick={() => handleCancel()}>
                    {t('cancelled')}
                  </button>
                  <button className="btn btn-simpan"  onClick={() => handleSaveAll()}>
                    {t('save')}
                  </button>
                </div>
              </div>             
            </div>               
          </div>   
        </div>   
      </div>
    </div>      
    <JenisIndustri
      isShow={showJenisIndustri}
      selectedData={program.jenis_industri}
      handleSave={(val)=>{
        setShowJenisIndustri(false)
        setProgram({...program, 'jenis_industri': val})
        setCount(count+1)
      }}
      onClose={()=>{
        setShowJenisIndustri(false)
      }}
    />
    </Layout>
    <UpdKelas 
      isShow={showUpdateKelas}
      selectedData={program.kelas}
      handleSave={(val)=>{setProgram({...program, 'kelas': val}); setShowUpdateKelas(false)}}
      handleClose={() => setShowUpdateKelas(false)}
    />
    <UpdWilayah 
      isShow={showUpdateWilayah}
      selectedData={program.wilayah}
      handleSave={(val)=>{setProgram({...program, 'wilayah': val}); setShowUpdateWilayah(false)}}
      handleClose={() => setShowUpdateWilayah(false)}
    />
    <UpdOkr 
      isShow={showUpdateOkr} 
      selectedData={program.program_filter_okrs}
      handleSave={(val)=>{
        setProgram({...program, 'program_filter_okrs': val});
        setErrorTemp([]);
        setShowUpdateOkr(false)
      }}
      handleClose={(val) => {
        val?.okr.length==0 && setProgram({...program, 'program_filter_okrs': []});
        setShowUpdateOkr(false)}
      }
    />
    <UpdImage 
      isShow={showUpdateImage} 
      handleSave={(val)=>{setProgram({...program, 'program_image': val}); setShowUpdateImage(false)}}
      handleClose={() => setShowUpdateImage(false)}
    />
    <UpdKolab 
      isShow={showUpdateKolab} 
      selectedData={program.program_filter_kolab}
      handleSave={(val)=>{setProgram({...program, 'program_filter_kolab': val}); setShowUpdateKolab(false)}}
      handleClose={() => setShowUpdateKolab(false)}
    />
    </>
  );
}

export default FormProgram;
