import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from '../../../components/UI/Table/Table';
import Input from "../../../components/UI/Input";
import Modal from "../../../components/UI/Modal";
import Swal from "sweetalert2";
import "./style.scss"
import { 
  FaTrash} from 'react-icons/fa'
import axios from "../../../helpers/axios";
import ImgHp from "../../../assets/appon/iconweb.png";
import { useTranslation } from "react-i18next";

const UpdKolaborator = ({ isShow, handleClose, handleSave, selectedData, viewState }) => {
  const { t } = useTranslation();
  const [listKolab, setListKolab] = useState([]);
  const owner = useSelector(state => state.instansi.instansi);
  const [count, setCount] = useState(0);
  const [nohp, setNoHp] = useState(null);
  const [instansi, setInstansi] = useState([]);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => { //state Editing
    if(selectedData?.length>0){
      setListKolab(selectedData)
    }else{
      setListKolab([])
    }
  }, [selectedData]) 
  
  const list_kolab = listKolab.map((i) => {
    return {
      col1: i.cover,
      col2: i.name,
      col3: i.id
    };
  });
  const data_kolab = React.useMemo(() => list_kolab, [listKolab]);
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'col1',
        width:"8%",
        Cell: ({value}) => (
          <div className="kolab-td">
            <img style={{width:30,height:30}} className="img d-block" src={value} alt="instansi_img" />
          </div>            
        )
      },
      {
        Header: t("program_collaborators"),
        accessor: 'col2'
      },
      {
        Header: t('action'),
        accessor: 'col3',
        width:"5%",
        Cell: (item) => (
            !viewState&&
            <div className="btn-setting1">
              <button className="btn btn-sm grp-button-del" onClick={(e) => {
                removeKolab(item.cell.value,item.customState.listKolab)
              }}><FaTrash /></button>
            </div>
        )
      }
    ],
    [listKolab, t]
  );

  const searchInstansi = async(no) => {
    setInstansi([]);
    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}/instansis/byno?no=${no}`)
      if (res.data.status == "success") {
          const instansi = res.data.result
          if(listKolab.find(item => item.id === instansi.id)){
            setInstansi({exist: t('instance_added_collaborators')})
          } else if(instansi.id==owner.id) { 
            setInstansi({exist:t('already_owner_program')})
          } else {
            setInstansi({data:instansi})
          }
      } else if (res.data.status == "error") {
          setInstansi({error:res.data.message})
      }
    } catch (err) {
      console.log(err);
    }
  }

  function addList(data){      
    setListKolab(listKolab => [...listKolab, {
      id:data.id,
      name:data.name,
      cover:data.cover_url
    }]);
    setInstansi([]);
    setCount(count+1);
  }

  function removeKolab(deleteId, list_kolab) {
    const newKolab = list_kolab.filter((kolab) => kolab.id !== deleteId);
    setListKolab( newKolab );
  }

  const onSave = () => {
    if(listKolab.length > 0){
        Swal.fire({
            title: '',
            text: t('are_you_sure'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: t('save'),
            cancelButtonColor: '#d33',
            cancelButtonText: t('re_edit'),
        
          }).then((result) => {
            if (result.isConfirmed) {
                handleSave(listKolab)
            }
          })
    }else{
      Swal.fire({
          title: '',
          text: "Anda belum memilih kolaborator?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: t('re_edit'),
          cancelButtonColor: '#d33',
          cancelButtonText: t('exit'),
      
        }).then((result) => {
          if (!result.isConfirmed) {
            handleClose();
          }
        })
    }
  }
  return(
    <>
      <Modal modalTitle={!viewState?"Tambahkan Kolaborator":"Detail Kolaborator"} size="md" show={isShow} handleClose={() => handleClose()}>
        <label className="mdl-desk">{viewState?'Berikut':'Cari dan undang'} mitra atau rekan yang akan berkolaborasi dalam program ini {viewState?'':'dengan menggunakan nomor HP'}</label>
        <div className="row">
          <div className="col">    
            <div className="row">
              {!viewState &&
              <>
              <div className="col-md-12 grp-slc">  
                <Input
                  type="input"
                  placeholder="cari dengan no hp/telp"
                  label="Cari instansi"
                  name="instansi"
                  value={nohp}
                  onChange={(e) => {
                    setNoHp(e.target.value)
                  }}
                />
                <button className="btn btn-sm grp-button-green btn-cari" onClick={(e) => searchInstansi(nohp)}>Cari </button>
              </div>
              <div className="col-md-12 kolab-search"> 
                  {instansi?.data &&
                    <>
                    <label>Hasil Pencarian :</label>
                    <div className="kolab-content">
                      <img className="img d-block" src={instansi?.data?.cover_url} alt="instansi_img" />
                      <label>{instansi?.data?.name}</label>
                      <button className="btn btn-sm grp-button-green" onClick={() => addList(instansi?.data)}>Tambahkan instansi ini </button>
                    </div>
                    </>
                  }
                  {instansi?.error &&
                    <label className="kolab-error">{instansi?.error}</label>
                  }
                  {instansi?.exist &&
                    <label className="kolab-exist">{instansi?.exist}</label>
                  }
              </div>
              </>
              }
              <div className="col-md-12"> 
                <DataTable
                  data={data_kolab}
                  columns={columns}
                  customState={{listKolab:listKolab??[]}}
                />
              </div> 
            </div>
            <div className="float-right">
              {!viewState?
                <>
                <button className="btn btn-sm button-primary mr-2" onClick={() => handleClose()}>{t('cancelled')}</button>
                <button className="btn btn-sm button-green" onClick={() => onSave()}>{t('save')}</button>
                </>
                :
                <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
              }              
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdKolaborator