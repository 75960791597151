export const setNumberFormat = (val) => {
  return val>0?val.toLocaleString():0;
};

export const setStatus = (val) => {
  switch (val) {
    case 'duplicate_excel':
      return 'Duplikat, data di file excel'
    break;    
    case 'duplicate_db':
      return 'Duplikat, data ini sudah ada di aplikasi'
    break;    
    case 'error':
      return 'Error, Ubah data di file excel anda'
    break;  
    default:
      return 'Data Normal'
    break;
  }
}

export const setVerif = (val) => {
  switch (val) {
    case 'linked':
      return 'Wirausaha (AppOn)'
    break;    
    case 'verified':
      return 'Terverifikasi'
    break;    
    case 'cancel':
      return 'Tidak Terverifikasi'
    break;  
    default:
      return 'Baru'
    break;
  }
}

export const typeVerifikasi = (val) => {
    const type = [{
      value:'',
      name:'Verification Via',
      disabled:true,
      selected:true
    },{
      value:'phone_call',
      name:'Phone Call',
      selected: val ==='phone_call'
    },
    {
      value:'whatsapp',
      name:'WhatsApp',
      selected:val==='whatsapp'
    },
    {
      value:'email',
      name:'Email',
      selected:val==='email'
    }]
    return type
}

export const statusData = (activeTab,val) => {
    var type = [{
      value:'new',
      name:'New',
      selected:val==='new'?true:false
    },{
      value:'approved',
      name:'Approved',
      selected:val==='verified'?true:false
    },
    {
      value:'reject',
      name:'failed',
      selected:val==='linked'?true:false
    }]

    // eslint-disable-next-line default-case
    switch (activeTab) {
      case 'new':
        delete type[2]
      break;
      case 'verified':
      case 'linked':
        delete type[0]
        delete type[3]
      break;
      case 'cancel':
        delete type[1]
        delete type[2]
      break;
    }
    return type;
}

export const renderCategory = (data, selected, text) => {
  const type = [{
    value:'',
    name: text,
    disabled:true,
    selected:true
  }]
  if (data?.category_industry?.length>0) {
    data.category_industry.map((val,index) => {
      type.push({
        value: val.id,
        name: val.category,
        selected: val.id===selected?true:false
      })
    })
  }

  return type
}

// export const renderOwnership = (selected) => {
//   const type = [{
//       value:'',
//       name:'Pilih tipe badan hukum',
//       disabled:true,
//       selected:true
//     },{
//       value:'pt',
//       name:'PT',
//       selected:selected==='pt'?true:false
//     },{
//       value:'cv',
//       name:'CV',
//       selected:selected==='cv'?true:false
//     },{
//       value:'firma',
//       name:'Firma',
//       selected:selected==='firma'?true:false
//     },{
//       value:'ud',
//       name:'UD',
//       selected:selected==='ud'?true:false
//     },{
//       value:'koperasi',
//       name:'Koperasi',
//       selected:selected==='koperasi'?true:false
//     },{
//       value:'yayasan',
//       name:'Yayasan',
//       selected:selected==='yayasan'?true:false
//     },{
//       value:'individual',
//       name:'Perorangan',
//       selected:selected==='individual'?true:false
//     }
//   ]
//   return type
// }


export const renderOwnership = (selected,all=false) => {
  const type = [{
      value:all?'semua':'',
      name:all?'Semua':'Pilih tipe badan hukum',
      disabled:all?false:true,
      selected:true
    },{
      value:'pt',
      name:'PT',
      selected:selected==='pt'?true:false
    },{
      value:'cv',
      name:'CV',
      selected:selected==='cv'?true:false
    },{
      value:'individual',
      name:'Perorangan',
      selected:selected==='individual'?true:false
    }
  ]
  return type
}

export const renderStatus = (selected,all=false) => {
  const type = [{
      value:all?'semua':'',
      name:all?'Semua':'Pilih Status',
      disabled:all?false:true,
      selected:true
    },{
      value:'verified',
      name:'Terverifikasi',
      selected:selected==='verified'?true:false
    },{
      value:'linked',
      name:'Wirausaha',
      selected:selected==='linked'?true:false
    }
  ]
  return type
}

export const renderEmployees = (selected,all=false) => {
  const type = [{
      value: all ? 'semua' : '',
      name: all ? 'Semua' : 'Pilih jumlah karyawan' ,
      disabled: all ? false : true,
      selected: true
    },{
      value: 'less_than_5',
      name: 'Kurang dari 5',
      selected: selected === 'less_than_5' ? true : false
    },{
      value: '5_to_19',
      name: '5 sampai 19',
      selected: selected === '5_to_19' ? true : false
    },{
      value: '20_to_99',
      name: '20 sampai 99',
      selected: selected === '20_to_99' ? true : false
    },{
      value: 'more_than_100',
      name: 'Lebih dari 100',
      selected:selected === 'more_than_100' ? true : false
    }
  ]
  return type
}
