import { instansiConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getProfil = (id) => {
    return async (dispatch) => {
        dispatch({ type: instansiConstants.FETCH_INSTANSI_REQUEST })

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}instansis/${id}?with_onlink=true`)

            if (res.data.status == "success") {
                const instansi = res.data.result
                dispatch({
                    type: instansiConstants.FETCH_INSTANSI_SUCCESS,
                    payload: { instansi }
                })
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: instansiConstants.FETCH_INSTANSI_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const editInstansi = (form) => {
    return async (dispatch) => {
        dispatch({ type: instansiConstants.INSTANSI_EDIT_REQUEST })
        try {
            const res = await axios.put(`${process.env.REACT_APP_MASTER_URI}instansis/${form.id}`, form)
            console.log('hasil edit', res);
            if (res.data.status == "success") {
                const instansi = res.data.result
                dispatch({
                    type: instansiConstants.INSTANSI_EDIT_SUCCESS,
                    payload: {instansi}
                })
            } else {
                dispatch({
                    type: instansiConstants.INSTANSI_EDIT_FAILURE,
                    payload: { error: res.data.message }
                })
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: instansiConstants.INSTANSI_EDIT_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}