import React, { useEffect, useRef, useState } from "react";
import {FaAngleRight } from 'react-icons/fa'
import Modal from "../../../components/UI/Modal";
import "./style.scss"
import ImgHp from "../../../assets/appon/img_hp.png";
import { setType, setNumberFormat } from "../fnc_var"
import UpdKelas from "../ProgramTambah/updKelas";
import UpdImage from "../ProgramTambah/updImage";
import JenisIndustri from "../ProgramTambah/JenisIndustri";
import UpdWilayah from "../ProgramTambah/updWilayah";
import UpdOkr from "../ProgramTambah/updOkr";
import UpdKolab from "../ProgramTambah/updKolaborator";
import moment from 'moment';
import { useTranslation } from "react-i18next";

const FormDetail = ({ isShow, handleClose, data }) => {
  const { t } = useTranslation();
  const [showUpdateKelas, setShowUpdateKelas] = useState(false);
  const [showJenisIndustri, setShowJenisIndustri] = useState(false);
  const [showUpdateWilayah, setShowUpdateWilayah] = useState(false);
  const [showUpdateOkr, setShowUpdateOkr] = useState(false);
  const [showUpdateKolab, setShowUpdateKolab] = useState(false);
  
  const [wilayah, setWilayah] = useState([]);
  const [industri, setIndustri] = useState([]);
  const [kelas, setKelas] = useState([]);
  const [colab, setColab] = useState([]);
  useEffect(() => {
    if(data){
      let wil = data?.program_filters?.find(item => item.filter_type === 'zone')
      if (wil) {
        setWilayah(wil?.filter_value)
      }

      let ind = data?.program_filters?.find(item => item.filter_type === 'industry')
      if (ind) {
        setIndustri(ind?.filter_value)
      }

      let kls = data?.program_filters?.find(item => item.filter_type === 'class')
      if (kls) {
        setKelas(kls?.filter_value)
      }

      if (data?.program_owners?.length>1) {
        let owners = [];
        data.program_owners.map((val,index) => {
          if(val.is_owner==0){
            owners.push({
              id: val.instansi_id,
              name: val.instansi.name,
              cover:val.instansi.cover_url
            })
          }
        })
        
        setColab(owners);
      }
    }
  }, [data])
  

  return(
    <>
      <Modal modalTitle={t('program_details')} size="lg" show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col-md-4">
            <div className="image-banner">
              <img className="img d-block" src={ImgHp} alt="appon image" />
              {data?.program_image&&
                <img className="img-banner" src={data.program_image} alt="logo" />
              }
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('program_name')}</span>
                  <span className="desk">{data?.program_name}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('program_type')}</span>
                  <span className="desk">{data?.program_category?.name}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('early_period')}</span>
                  <span className="desk">{moment(data?.program_start).format('DD-MM-YYYY HH:mm')+' WIB'}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('end_period')}</span>
                  <span className="desk">{moment(data?.program_end).format('DD-MM-YYYY HH:mm')+' WIB'}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('participant_quota')}</span>
                  <span className="desk">{data?.participant_quota==null?t('unlimited'):t('limited')+' :'+data.participant_quota}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <span className="title">{t('budget')}</span>
                  <span className="desk">{'Rp. '+setNumberFormat(data?.participant_budget??0) + 
                    (data?.budget_show && data.budget_show>0? ' ( Ditampilkan )':' ( Tidak Ditampilkan )')}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <span className="title">{t('description')}</span>
                  {data?.program_description && 
                    <div className="desk-html" dangerouslySetInnerHTML={{ __html: data.program_description }} />
                  }
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('collaboration_program')}</span>
                  <div className="collect-group">
                    <button className="btn btn-collect" disabled={colab.length>0?false:true} onClick={() => {setShowUpdateKolab(true)}}>
                      <div className="collect-text">
                        <span className="btn-title">Kolaborator, mitra atau rekan yang akan berkolaborasi dalam program ini</span>
                        <span className="btn-desk">{colab.length??0} instansi berkolaborasi</span>
                      </div>
                      <FaAngleRight />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('requirements_before_joining_the_program')}</span>
                  <div className="collect-group">
                    <button className="btn btn-collect" disabled={industri.length>0?false:true} onClick={() => {setShowJenisIndustri(true)}}>
                      <div className="collect-text">
                        <span className="btn-title">Jenis Industri</span>
                        <span className="btn-desk">{industri.length??0} jenis industri dipilih</span>
                      </div>
                      <FaAngleRight />
                    </button>
                  </div>
                  <div className="collect-group">
                    <button className="btn btn-collect" disabled={wilayah.length>0?false:true} onClick={() => {setShowUpdateWilayah(true)}}>
                      <div className="collect-text">
                        <span className="btn-title">Wilayah</span>
                        <span className="btn-desk">{wilayah.length??0} wilayah dipilih</span>
                      </div>
                      <FaAngleRight />
                    </button>
                  </div>
                  <div className="collect-group">
                    <button className="btn btn-collect" disabled={kelas.length>0?false:true} onClick={() => {setShowUpdateKelas(true)}}>
                      <div className="collect-text">
                        <span className="btn-title">Kelas</span>
                        <span className="btn-desk">{kelas.length??0} kelas UMKM dipilih</span>
                      </div>
                      <FaAngleRight />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="span-desk">{t('requirements_after_joining_the_program')}</span>
                  <div className="collect-group">
                    <button className="btn btn-collect" disabled={data?.program_filter_okrs?.length>0?false:true} onClick={() => {setShowUpdateOkr(true)}}>
                      <div className="collect-text">
                        <span className="btn-title">OKR</span>
                        <span className="btn-desk">{data?.program_filter_okrs?.length??0} OKR dipilih</span>
                      </div>
                      <FaAngleRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="float-right">
              <button className="btn btn-sm button-secondary mr-2" onClick={() => handleClose()}>{t('closed')}</button>
            </div>
          </div>
        </div>
      </Modal>
      <JenisIndustri 
        isShow={showJenisIndustri}
        selectedData={industri}
        viewState={true}
        onClose={() => setShowJenisIndustri(false)}
      />
      <UpdKelas 
        isShow={showUpdateKelas}
        selectedData={kelas}
        viewState={true}
        handleClose={() => setShowUpdateKelas(false)}
      />
      <UpdWilayah 
        isShow={showUpdateWilayah}
        selectedData={wilayah}
        viewState={true}
        handleClose={() => setShowUpdateWilayah(false)}
      />
      <UpdOkr 
        isShow={showUpdateOkr} 
        selectedData={data?.program_filter_okrs}
        viewState={true}
        handleClose={() => setShowUpdateOkr(false)}
      />
      <UpdKolab 
        isShow={showUpdateKolab} 
        selectedData={colab}
        viewState={true}
        handleClose={() => setShowUpdateKolab(false)}
      />
    </>
    
  )
}

export default FormDetail