import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaCogs, FaPencilAlt, FaEye, FaTrashAlt, FaTrashRestoreAlt } from 'react-icons/fa'
import { Dropdown } from "react-bootstrap";
import { Tabs, Tab } from 'react-bootstrap';
import './style.scss';
import ErrorRes from "../../../components/UI/ErrorRes";
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgram, deleteProgram, restoreProgram } from "../../../actions/program.actions";
import { cekDataImport, postDataBulk } from "../../../actions/stakeholder.actions";
import moment from 'moment';
import {setStatus} from "../fnc_var";
import Swal from "sweetalert2";
import UpdData from "./updData";
import { useTranslation } from "react-i18next";

function ImportData() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("normal");
  const [selectedProgram, setSelectedProgram] = useState({});
  const program = useSelector((state) => state.program);
  const _dataImport = useSelector((state) => state.stakeholder?.dataImport);
  const user = useSelector((state) => state.auth);
  const instansiId = user?.user?.user_instansi
    ? user?.user?.user_instansi[0].instansi_id
    : 0;
  const [error, setError] = useState([]);
  const [dataImport, setDataImport] = useState([]);
  const [dataNormal, setDataNormal] = useState([]);
  const [dataWarning, setDataWarning] = useState([]);
  const [dataError, setDataError] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [showFormDetail, setShowFormDetail] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [showUpdateData, setShowUpdateData] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = dataImport?.map((i, index) => {
    return {
      col1: index + 1,
      col2: i.id_number,
      col3: i.business_name,
      col4: i.owner_name,
      col5: i.business_contact_number,
      col6: i.business_category,
      col7: i.ket,
    };
  });

  const data = React.useMemo(() => list, [dataImport, t]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: "col1",
        width: "5%",
        textAlign: "center",
      },
      {
        Header: t("id_number"),
        accessor: "col2",
        width: "15%",
      },
      {
        Header: t("business_name"),
        accessor: "col3",
      },
      {
        Header: t("owners_name"),
        accessor: "col4",
        width: "15%",
        textAlign: "center",
      },
      {
        Header: t("business_contact"),
        accessor: "col5",
        width: "15%",
        textAlign: "center",
      },
      {
        Header: t("business_category"),
        accessor: "col6",
        width: "15%",
        textAlign: "center",
        className: "capitalize",
      },
      {
        Header: t("status"),
        accessor: "col7",
        width: "20%",
      },
    ],
    [dataImport, t]
  );

  useEffect(() => {
    console.log('user', user)
  }, [user])

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (activeTab) {
      case "normal":
        setDataImport(dataNormal);
        break;
      case "warning":
        setDataImport(dataWarning);
        break;
      case "error":
        setDataImport(dataError);
        break;
    }
  }, [activeTab]);

  const handleFormDetail = (id, data, state) => {
    const data_program = data.find((item) => item.id === id);
    setSelectedProgram(data_program);
    switch (state) {
      case "edit":
        setShowFormEdit(true);
        break;
      case "view":
        setShowFormDetail(true);
        break;
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "",
      text: t('delete_confirmation'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: t('delete_confirmation_yes'),
      cancelButtonColor: '#d33',
      cancelButtonText: t('cancelled'),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProgram(id));
      }
    });
  };

  const dataRefresh = () => {
    setDataImport([]);
    setDataNormal([]);
    setDataWarning([]);
    setDataError([]);
  };

  const dataFormated = (data) => {
    let results = [];

    const keys = {
      id_number: 'ID Number',	
      owner_name: 'Owner Name',	
      address: 'Address', 
      email_address: 'Email Address',
      contact_number: 'Contact Number', 
      individual_taxpayer_identification_no: 'Individual Taxpayer Identification No.',
      business_photo: 'Business Photo',	
      business_name: 'Business Name',
      business_contact_number: 'Business Contact Number', 
      business_address: 'Business Address',
      business_taxpayer_identification: 'Business Taxpayer Identification',
      business_identification_number: 'Business Identification Number',
      business_location: 'Business Location',
      business_category: 'Business Category',
      ssm: 'Suruhanjaya Syarikat Malaysia (SSM)',
      local_town_council_license: 'Local Town Council License (PBT License)',
      jakim_halal: 'JAKIM Halal',
      kkm: 'Kementerian Ksihatan Malaysia (KKM)',
      business_category: 'Business Category',	
      law_firm_type: 'Law firm type',
      total_employee: 'Total Employee',
      turnover: 'Turnover (RM)',
      legal_entity: 'Legal Entity',
      verification_status: 'Verification Status',
      verification_via: 'Verification via',
      verified_by: 'Verified by',
      verified_note: 'Verified note'
    };
    
    data.forEach((item) => {
      const newItem = {
        id_photo: null,
        id_number: item[keys['id_number']],
        owner_name: item[keys['owner_name']],
        address: item[keys['address']],
        email_address: item[keys['email_address']],
        contact_number: item[keys['contact_number']],
        individual_taxpayer_identification_no: item[keys['individual_taxpayer_identification_no']],
        business_photo: item[keys['business_photo']],
        business_name: item[keys['business_name']],
        business_contact_number: item[keys['business_contact_number']],
        business_address: item[keys['business_address']],
        business_taxpayer_identification: item[keys['business_taxpayer_identification']],
        business_identification_number: item[keys['business_identification_number']],
        business_location: item[keys['business_location']],
        business_category: item[keys['business_category']],
        legal_entity: item[keys['legal_entity']],
        ssm: item[keys['ssm']],
        local_town_council_license: item[keys['local_town_council_license']],
        jakim_halal: item[keys['jakim_halal']],
        kkm: item[keys['kkm']],
        law_firm_type: item[keys['law_firm_type']],
        total_employee: item[keys['total_employee']],
        turnover: item[keys['turnover']],
        verification_status: item[keys['verification_status']],
        verification_via: item[keys['verification_via']] ?? '',
        verified_by: item[keys['verified_by']] ?? '',
        verified_note: item[keys['verified_note']] ?? '',
        agency_id: user?.user?.user_agency?.id, 
      }
      results.push(newItem);
    });

    return results;
  }

  const validateDataFormated = (data) => {
    let dataValid = [];
    let dataWarning = [];
    let dataError = [];

    data.forEach((item, index) => {
      let emptyFields = [];
      let duplicateFields = [];

      if (item.id_number === undefined || item.id_number === null || item.id_number === '') {
        emptyFields.push('ID Number');
      }

      if (item.owner_name === undefined || item.owner_name === null || item.owner_name === '') {
        emptyFields.push('Owner Name');
      }
      
      if (item.business_name === undefined || item.business_name === null || item.business_name === '') {
        emptyFields.push('Business Name');
      }
      
      if (item.business_contact_number === undefined || item.business_contact_number === null || item.business_contact_number === '') {
        emptyFields.push('Business Contact Number');
      }

      if (emptyFields.length > 0) {
        data[index].ket = emptyFields.join(', ') + ' cannot be empty';
        dataError.push(item);
      } else {
        for (let i = 0; i < data.length; i++) {
          if (i === index) {
            continue;
          }

          const searchItem = data[i];

          if (item.id_number === searchItem.id_number) {
            duplicateFields.push('ID Number');
          }

          if (item.owner_name === searchItem.owner_name) {
            duplicateFields.push('Owner Name');
          }

          if (item.business_name === searchItem.business_name) {
            duplicateFields.push('Business Name');
          }

          if (item.business_contact_number === searchItem.business_contact_number) {
            duplicateFields.push('Business Contact Number');
          }

          if (duplicateFields.length > 0) {
            data[i].ket = duplicateFields.join(', ') + ' is duplicate';
            dataWarning.push(item);
            break;
          }
        }
      }

      if (emptyFields.length === 0 && duplicateFields.length === 0) {
        dataValid.push(item);
      }
    });
    
    setLoading(false);

    return { dataValid, dataWarning, dataError }
  }

  const handleImportData = (data) => {
    const _dataFormated = dataFormated(data);
    const validDataFormated = validateDataFormated(_dataFormated);

    setDataImport(validDataFormated.dataValid);
    setDataNormal(validDataFormated.dataValid);
    setDataWarning(validDataFormated.dataWarning);
    setDataError(validDataFormated.dataError);

    return;
    // setLoading(true);   
    var newDataNormal = []      
    var newDataWarning = []      
    var newDataError = []
    
    data.map((val, index) => {
      var obj = {};
      obj.nik = '' +val['Nomer Induk Kependudukan (NIK)'];
      obj.name = val['Nama Lengkap'];
      obj.address = val['Alamat Lengkap'];
      obj.no_tlp = '' + val['Nomer Telepon'];
      obj.email = val['Email'];
      obj.jenis_kelamin = val['Jenis Kelamin']==='Pria'?'l':(val['Jenis Kelamin']=='Wanita')?'p':'l';

      obj.business_name = val['Nama Bisnis'];
      obj.business_tlp = '' + val['Nomer Telepon Bisnis'];
      obj.business_address = val['Alamat Bisnis'];

      obj.business_type = val['Jenis Badan Usaha'];
      obj.business_category = val['Kategori Industri'];
      obj.status_data = 'normal';
      obj.ket = 'Data Normal';

      // validasi data kosong
      var ket1 = '';
      var ket3 = '';
      if([null,undefined ].includes(obj.nik)){ket1 += 'No Ktp, '}
      if([null,undefined ].includes(obj.business_name)){ket1 += 'Nama Bisnis, '}
      if([null,undefined ].includes(obj.business_tlp)){ket1 += 'Nomer Telepon Bisnis, '}
      if(obj.nik.length<16){ ket3='No KTP harus 16 digits' }

      if(ket3.length>0){
        obj.status_data = 'error';
        obj.ket = ket3;
        
        newDataError.push(obj)  
      } else if (ket1.length>0) {          
        ket1 = ket1.substring(0, ket1.length - 2) + ' tidak boleh kosong'          
        
        obj.status_data = 'error';
        obj.ket = ket1;
        
        newDataError.push(obj)  
      } else {
        newDataNormal.push(obj)
      }     
    });

    //cek duplikat data
    let seen = new Set();
    newDataNormal.some(function(val, index) {
      var ket2 = '';
      if (seen.size === seen.add(val.id_number).size) {
        ket2 += 'ID Number, '
      }
      if (seen.size === seen.add(val.business_name).size) {
        ket2 += 'Nama Bisnis, '
      }
      if (seen.size === seen.add(val.business_contact_number).size) {
        ket2 += 'Nomer Telepon Bisnis, '
      }

      if (ket2.length>0) {
        ket2 = 'Duplikat '+ ket2.substring(0, ket2.length - 2) + ' di Excel'
        
        if (!(['error'].includes(newDataNormal[index].status_data))) {            
          newDataNormal[index].status_data = 'warning';
          newDataNormal[index].ket = ket2;    
          
          newDataWarning.push(newDataNormal[index])
          delete newDataNormal[index]
        }
      }
    });
    newDataNormal = newDataNormal.filter(function( element ) {
      return element !== undefined;
    });
    //cek Database
    var cekData = {}
    cekData.instansi_id = instansiId;
    cekData.created_by = '-';
    cekData.isCheck = true;
    cekData.data = newDataNormal;
    dispatch(cekDataImport(cekData))
      .then((res) => {                
        setDataNormal(newDataNormal)
        setDataWarning(newDataWarning)
        setDataError(newDataError)
        setDataImport(newDataNormal)
        setLoading(false);
      })
      .catch((err) => {
        var eror = err.response?.data.error
        console.log('err',eror);
        if(eror){
          Object.keys(eror).map((keyName, txt) => {
            var fields = keyName.split('.');
            // console.log(newDataNormal[fields[1]]);
            eror[keyName].map((val, i) => {          
              newDataNormal[fields[1]].status_data = 'warning';
              newDataNormal[fields[1]].ket = val;   
              newDataWarning.push(newDataNormal[fields[1]])
            })
            delete newDataNormal[fields[1]]
          });

          newDataNormal = newDataNormal.filter(function( element ) {
            return element !== undefined;
          });
          
          setDataNormal(newDataNormal)
          setDataWarning(newDataWarning)
          setDataError(newDataError)
          setDataImport(newDataNormal)
          setLoading(false);
        } else {
          setLoading(false);
          Swal.fire({
            title: 'Error!',
            text: 'Gagal Mengimport data, ada yang salah dengan sistem',
            icon: 'error',
            confirmButtonColor: '#FCB630',
            confirmButtonText: 'Okay'
          })
        }
      }
    )
}

  const handleSaveData = () => {
    var newData = {};
    newData.instansi_id = instansiId;
    newData.created_by = "admin";
    newData.data = dataNormal;

    if (dataNormal?.length > 0) {
      setLoading(true);
      dispatch(postDataBulk(newData))
        .then((result) => {
          setLoading(false);
          Swal.fire({
            title: "Success!",
            text: "Your data has been successfully imported",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Okay",
            cancelButtonColor: "#d33",
            cancelButtonText: t('create_new_program'),
          }).then((res) => {
            if (result.isConfirmed) {
              window.location = "/stakeholder-rekap";
            } else {
              window.location = "/stakeholder-rekap";
              setDataImport([]);
              setDataNormal([]);
            }
          });
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: "Import failed, check for errors or incomplete data",
            icon: "error",
            confirmButtonColor: "#FCB630",
            confirmButtonText: "Okay",
          });
        });
    } else {
      setLoading(false);
      Swal.fire({
        title: "Error!",
        text: t("something_went_wrong"),
        icon: "error",
        confirmButtonColor: "#FCB630",
        confirmButtonText: "Okay",
      });
    }
  };

  return (
    <>
      <Layout loading={loading}>
        <div className="row table-title">
          <span className="title">{t("businessmen_data")}</span>
          <span className="desk">
            {t("businessmen_data")} {">"} {t("import_data")}
          </span>
        </div>
        <div className="row table-view">
          <div className="col">
            <div className="table-tittle">
              {dataImport.length > 0 ? (
                <span className="title">{t("data_check_import")}</span>
              ) : (
                <button
                  className="btn btn-import"
                  onClick={() => {
                    setShowUpdateData(true);
                  }}
                >
                  {t("import_data")}
                </button>
              )}
            </div>
            <div className="col-tab-wrapper col-tab-mrgn">
              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="mb-1"
              >
                <Tab
                  eventKey="normal"
                  title={`${t("correct_data")} ${
                    dataNormal.length > 0 ? "(" + dataNormal.length + ")" : ""
                  }`}
                  className="text-green"
                />
                <Tab
                  eventKey="warning"
                  title={`${t("wrong_data")} ${
                    dataWarning.length > 0 ? "(" + dataWarning.length + ")" : ""
                  }`}
                  className="text-yellow"
                />
                <Tab
                  eventKey="error"
                  title={`${t("error_data")} ${
                    dataError.length > 0 ? "(" + dataError.length + ")" : ""
                  }`}
                  className="text-red"
                />
              </Tabs>
            </div>
            <DataTable data={data} columns={columns} loading={loading} />

            <div className="col-tab-action">
              {dataImport.length > 0 && (
                <>
                  {!(dataWarning.length > 0 || dataError.length > 0) ? (
                    <>
                      <button
                        className="btn btn-sm button-green mr-2"
                        onClick={() => {
                          handleSaveData();
                        }}
                      >
                        {t("save")}
                      </button>
                      <button
                        className="btn btn-sm button-primary "
                        onClick={() => {
                          dataRefresh();
                        }}
                      >
                        {t("cancelled")}
                      </button>
                    </>
                  ) : (
                    <div className="col-span-error">
                      <span className="title">{t("cannot_be_imported")}</span>
                      <span className="desk">
                        {t("cannot_be_imported_text")}
                      </span>
                      <button
                        className="btn btn-sm button-primary "
                        onClick={() => {
                          dataRefresh();
                        }}
                      >
                        {t("upload_again")}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
      <UpdData
        isShow={showUpdateData}
        handleSave={(val) => {
          setShowUpdateData(false);
          setLoading(true);
          handleImportData(val);
        }}
        handleClose={() => setShowUpdateData(false)}
      />
    </>
  );
}

export default ImportData;
