/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import { RadialBarChart, RadialBar, Legend } from "recharts";
import { useSelector } from 'react-redux';

const style = {
  bottom: 0,
  left: 150,
  lineHeight: "24px",
};

export default function App(props) {
  const data_monitoring = useSelector(state => state.dashboard.data_monitoring)

  var data = [];
  data_monitoring?.absorpti_on_budget?.map((val,index) => {
    data.push({
      name: val.label,
      value: val.value,
      fill: `#${Math.floor(Math.random()*16777215).toString(16)}`
      ,
    })
  })

  console.log('data',data);

  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg border-0 rounded-3 shadow "}>
        <div style={{
          padding:20
        }}>
          <div className="card-header1">
            <h5>{props.stat}</h5>
          </div>
          <div className="card-content">
            <RadialBarChart
              width={500}
              height={300}
              cx={150}
              cy={150}
              innerRadius={20}
              outerRadius={140}
              barSize={10}
              data={data}
            >
              <RadialBar
                minAngle={15}
                label={{ position: 'insideStart', fill: '#fff' }}
                background
                clockWise
                dataKey="value"
              />
              <Legend
                iconSize={10}
                width={300}
                height={140}
                layout="horizontal"
                verticalAlign="middle"
                align="center"
                wrapperStyle={{
                  bottom: 0,
                  left: 180,
                  lineHeight: "24px",
                }}
              />
            </RadialBarChart>
          </div>
        </div>
      </div>
    </div>
  );
}
