import React from 'react';
import { useTable, usePagination } from 'react-table';
import "./style.scss";
import { FaAngleDoubleLeft,FaAngleLeft,FaAngleRight,FaAngleDoubleRight } from 'react-icons/fa'
import { useTranslation } from "react-i18next";

function Table({
  setPerPage,
  setPage,
  columns,
  customState,
  data,
  loading,
  cstmHead,
  currentpage,
  perPage,
  totalPage,
  noPaging,
}) {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // canPreviousPage,
    // canNextPage,
    pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      customState,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state, currentpage]
        );
      },
      initialState: { pageIndex: currentpage }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: totalPage,
    },
    usePagination
  );

  return (
    <>
      <table
        {...getTableProps()}
        className="table table-fixed table-responsive table-custom1"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.slice(0, 1).map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: {
                      textAlign: column.textAlign,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width,
                    },
                  })}
                  className="px-1 py-4 bg-red-100 capitalize w-96"
                >
                  {cstmHead ? cstmHead : column.render("Header")}
                </th>
              ))}
              {headerGroup.headers.slice(1).map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: {
                      textAlign: column.textAlign,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width,
                    },
                  })}
                  className="py-4 bg-red-100 capitalize w-1/6"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading && (
            <center>
              <div className="spinner-cstm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </center>
          )}
          {!loading &&
            (page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps({
                            style: {
                              textAlign: cell.column.textAlign,
                              minWidth: cell.column.minWidth,
                              maxWidth: cell.column.maxWidth,
                              width: cell.column.width,
                            },
                          })}
                          className={"truncate p-1 border-b-2"}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={headerGroups[0].headers.length}
                  className="td-greyout"
                >
                  {t("no_data_found")}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {perPage && ( //jika terdapat pagination
        <div className="flex table-pagination justify-between mb-3">
          {!noPaging && (
            <button
              onClick={() => {
                setPage(1);
              }}
              disabled={currentpage === 1}
            >
              <FaAngleDoubleLeft />
            </button>
          )}{" "}
          <button
            onClick={() => {
              setPage((s) => (s === 0 ? 0 : s - 1));
            }}
            disabled={currentpage === 1}
          >
            <FaAngleLeft />
          </button>{" "}
          <button
            onClick={() => {
              setPage((s) => s + 1);
            }}
            disabled={currentpage === totalPage}
          >
            <FaAngleRight />
          </button>{" "}
          {!noPaging && (
            <button
              onClick={() => {
                setPage(totalPage);
              }}
              disabled={currentpage === totalPage}
            >
              <FaAngleDoubleRight />
            </button>
          )}{" "}
          <span>
            {t("page") + " "}
            <strong>
              {pageIndex} {t("page_of")} {pageOptions.length}
            </strong>{" "}
          </span>
          {!noPaging && (
            <span className="page">
              | {t("jump_to_page")} :{" "}
              <input
                type="number"
                defaultValue={pageIndex}
                min="1"
                max={totalPage}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) : 1;
                  setPage(page);
                }}
                className="w-10"
              />
            </span>
          )}{" "}
          <select
            value={perPage}
            onChange={(e) => {
              // setPageSize(Number(e.target.value));
              setPerPage(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t("show")} {pageSize} {t("per_page")}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}

export default Table;
