import { stakeholderProgramConstants, stakeholderParticipantConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getProgram = (page, perPage, parm) => {
    return async (dispatch) => {
        dispatch({ type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_REQUEST })
        const user = JSON.parse(localStorage.getItem("user"))
        const instanceId = user.user_instansi[0].instansi_id
        var stat = '';
        if (parm?.status) {
          let sts = parm.status==='all'?'':`&filter[status]=${parm.status}`
              sts = parm.status==='deleted'?`&deleted=true`:sts //tab deleted
          stat = stat + sts
        }
        if (parm?.jenis) {
            let jns = parm.jenis==='all'?'':`&filter[program_category_id]=${parm.jenis}`
            stat = stat + jns;
        }
        if (parm?.search) {
          stat = stat + `&filter[search]=${parm.search}`
        }
        try {
            await axios.get(`${process.env.REACT_APP_MASTER_URI}corp/program?with_resource=1&filter[instansi_id]=${instanceId}&page=${page}&perPage=${perPage}${stat}`)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    const program = data.result?.data??[]
                    const met = data.result;
                    delete met.data;
                    const meta = met;
    
                    dispatch({
                        type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_SUCCESS,
                        payload: { program, meta }
                    })
                }
             })
             .catch(error => {
                dispatch({
                    type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_FAILURE,
                    payload: { error: error }
                })
             })
        } catch (err) {
            console.log(err);
            dispatch({
                type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const postProgram = (program) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_POST_REQUEST })
        try {
            const res = 
            await axios.post(`${process.env.REACT_APP_MASTER_URI}corp/program`, program)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_POST_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                console.log('errorr ',error.response);
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_POST_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_POST_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const editProgram = (program,id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_EDIT_REQUEST })
        console.log('data baru ',program);
        try {
            const res = 
            await axios.put(`${process.env.REACT_APP_MASTER_URI}corp/program/${id}`, program)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_EDIT_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_EDIT_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_EDIT_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const deleteProgram = (id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_REQUEST })
        try {
            const res = await axios.delete(`${process.env.REACT_APP_MASTER_URI}corp/program/${id}`)
            console.log(res);
            if (res.data.status === "success") {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_SUCCESS,
                    payload: { id: id }
                })
                resolve(res.data.status)
            } else {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_FAILURE,
                    payload: { error: res.data.message }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
  })
}
export const restoreProgram = (id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_RESTORE_REQUEST })
        try {
            const res = await axios.put(`${process.env.REACT_APP_MASTER_URI}corp/program/${id}/restore`)
            console.log(res);
            if (res.data.status === "success") {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_RESTORE_SUCCESS,
                    payload: { id: id }
                })
                resolve(res.data.status)
            } else {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_RESTORE_FAILURE,
                    payload: { error: res.data.message }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_DELETE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
  })
}

export const getProgramNoResource = (type = null) => {
    return async (dispatch) => {
        dispatch({ type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_REQUEST})

        const user = JSON.parse(localStorage.getItem("user"))
        const instanceId = user.user_agency.id

        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}corp/program/?with_resource=0&instansi_id[]=${instanceId}${type !== null ? `&filter[status]=${type}` : '' }`)

            if (res.data.status === "success") {
                const programNoResource = res.data.result.data ?? []
                const meta = res.data.result.meta ?? []

                dispatch({
                    type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_SUCCESS,
                    payload: { programNoResource, meta }
                })
            }
        } catch (err) {
            console.log(err);
            dispatch({
                type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_NO_RESOURCE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getProgramPeserta = (program_id ,page, perPage, search, status='') => {
    return async (dispatch) => {
        if(program_id !== null){
            dispatch({ type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_REQUEST})
            var prgid = parseInt(program_id)>0?`filter[corp_program_id]=${parseInt(program_id)}&`:'';
            try {
                const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}corp/program/participant/?${prgid}search[profil]=${search}${status !== '' ? '&filter[status]=' +status : '' }&page=${parseInt(page)}&perPage=${parseInt(perPage)}`)    
                if (res.data.status === "success") {
                    var ProgramParticipant = res.data.result
                    if(!ProgramParticipant?.data){
                        ProgramParticipant = {
                            data: [],
                            meta: {
                              current_page: 1,
                              per_page: 0,
                              last_page: 0
                            }
                          }
                    }
    
                    dispatch({
                        type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_SUCCESS,
                        payload: { ProgramParticipant }
                    })
                }
            } catch (err) {
                console.log(err);
                dispatch({
                    type: stakeholderProgramConstants.FETCH_STAKEHOLDER_PROGRAM_PARTICIPANT_FAILURE,
                    payload: { error: i18next.t("something_went_wrong") }
                })
            }
        }
    }
}

export const updateBatchProgram = (payload) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_UPDATE_BATCH_REQUEST })
        try {
            const res = await axios.put(`${process.env.REACT_APP_MASTER_URI}corp/program/participant/update-batch`, payload)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status === "success") {
                    dispatch({
                        type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_UPDATE_BATCH_SUCCESS,
                        payload: { program: data.result }
                    })
                    resolve('success')
                }
             })
             .catch(error => {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_UPDATE_BATCH_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_UPDATE_BATCH_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const getListParticipant = (page, perPage, program, parm) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderParticipantConstants.FETCH_STAKEHOLDER_PARTICIPANT_REQUEST })

        const user = JSON.parse(localStorage.getItem("user"))
        const instanceId = user.user_agency.id
        var stat = '';
        if (program?.program) {
            let jns = program.program==='all'?'':`&notInProgram=${program.program}`
            stat = stat + jns;
        }
        if (parm!=='') {
            stat = stat + parm;   
        }
        console.log(stat);
        try {
            const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}corp/umkm?filter[instansi_id]=${instanceId}&page=${page}&perPage=${perPage}${stat}`)
            if (res.data.status === "success") {
                const dpwParticipant = res?.data?.result
                dispatch({
                    type: stakeholderParticipantConstants.FETCH_STAKEHOLDER_PARTICIPANT_SUCCESS,
                    payload: { dpwParticipant:dpwParticipant }
                })
                resolve(res)
            } else {
                dispatch({
                    type: stakeholderParticipantConstants.FETCH_STAKEHOLDER_PARTICIPANT_FAILURE,
                    payload: { error: i18next.t("something_went_wrong") }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: stakeholderParticipantConstants.FETCH_STAKEHOLDER_PARTICIPANT_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const postDataPeserta = (peserta) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_POST_REQUEST })
        try {
            const res = 
            await axios.post(`${process.env.REACT_APP_MASTER_URI}corp/program/participant`, peserta)
            .then(response => {
                return response.data
             })
             .then(data => {
                 if (data.status == "success") {
                    dispatch({
                        type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_POST_SUCCESS,
                        payload: { message: data.message }
                    })
                    resolve(data.message)
                }
             })
             .catch(error => {
                console.log('errorr ',error.response);
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_POST_FAILURE,
                    payload: { error: error.response.data.error }
                })
                rejected(error.response.data.error)
             })
        } catch (err) {
            dispatch({
                type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_POST_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
    })
}

export const deletePeserta = (id) => async (dispatch) => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_DELETE_REQUEST })
        try {
            const res = await axios.delete(`${process.env.REACT_APP_MASTER_URI}corp/program/participant/${id}`)
            console.log(res);
            if (res.data.status === "success") {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_DELETE_SUCCESS,
                    payload: { id: id }
                })
                resolve(res.data.status)
            } else {
                dispatch({
                    type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_DELETE_FAILURE,
                    payload: { error: res.data.message }
                })
                rejected(res.data.message)
            }
        } catch (err) {
            dispatch({
                type: stakeholderProgramConstants.STAKEHOLDER_PROGRAM_PESERTA_DELETE_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
            rejected(i18next.t("something_went_wrong"))
        }
  })
}