import React, { useEffect } from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

function generateColor(val) {
  var color = "";
  if (val < 50) {
    color = "bf3";
  } else if (val >= 50 && val < 100) {
    color = "bf1";
  } else if (val >= 100) {
    color = "bf4";
  }
  return color;
}
function genColor(val, sm) {
  var color = "";
  let md = sm / 2;
  let sd = sm / 3;
  if (val >= sm) {
    color = "#FCB630";
  } else if (val < sm && val >= md) {
    color = "#053DC8";
  } else if (val <= sd) {
    color = "#ffa127";
  }
  return color;
}
function Badanhukum(props) {
  const { t } = useTranslation();
  let bgColor = "bg-" + props.color;
  const data = useSelector((state) => state.dashboard.stakeholder_umkm?.detail);
  const legalEntities = useSelector((state) => state.dashboard.stakeholder_umkm?.legal_entities ?? []);
  let dataY = [];
  let dataX = [];
  let sum = 0;
  if (data?.jumlah_pegawai) {
    Object.keys(data.jumlah_pegawai).map(function (key, index) {
      if (data.jumlah_pegawai[key] > sum) {
        sum = data.jumlah_pegawai[key];
      }
    });
    Object.keys(data.jumlah_pegawai).map(function (key, index) {
      switch (key) {
        case "less_than_5":
          dataY[3] = "< 5 " + t("people");
          dataX[3] = {
            value: data.jumlah_pegawai[key],
            itemStyle: { color: genColor(data.jumlah_pegawai[key], sum) },
          };
          break;
        case "5_to_19":
          dataY[2] = "5-19 " + t("people");
          dataX[2] = {
            value: data.jumlah_pegawai[key],
            itemStyle: { color: genColor(data.jumlah_pegawai[key], sum) },
          };
          break;
        case "20_to_99":
          dataY[1] = "20-99 " + t("people");
          dataX[1] = {
            value: data.jumlah_pegawai[key],
            itemStyle: { color: genColor(data.jumlah_pegawai[key], sum) },
          };
          break;
        case "more_than_100":
          dataY[0] = "> 100 " + t("people");
          dataX[0] = {
            value: data.jumlah_pegawai[key],
            itemStyle: { color: genColor(data.jumlah_pegawai[key], sum) },
          };
          break;
      }
    });
  }

  function getOption() {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{b} <br/>{c} {a}",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        top: 10,
        bottom: 30,
        left: 70,
        right: 40,
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: dataY,
        axisLabel: {
          show: true,
          textStyle: {
            fontSize: 10,
          },
          rich: {
            flag: {
              fontSize: 5,
              padding: 2,
            },
          },
        },
        axisTick: {
          alignWithLabel: true,
        },
      },
      series: [
        {
          name: "UMKM",
          data: dataX,
          type: "bar",
          inverse: false,
          label: {
            show: true,
            precision: 1,
            position: "right",
            valueAnimation: true,
            fontFamily: "poppins",
          },
        },
      ],
    };
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className="row">
        <div className="col-md-12">
          <div
            className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}
          >
            <div className="card-body">
              <div className="card-header1">
                <h5>{props.stat}</h5>
                <h6>{props.label}</h6>
              </div>
              <div className="card-content">
                {legalEntities.map((item) => 
                (<div className="kt-widget12__progress">
                  <span>{item.name_en}</span>
                  <span
                    className={
                      "kt-widget12__stat2 bf3"
                    }
                  >
                    {item.total}
                  </span>
                </div>))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div
            className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}
          >
            <div className="card-body">
              <div className="card-header1">
                <h5>{t("numbers_of_employee")}</h5>
                <h6>{""}</h6>
              </div>
              <div className="card-content">
                <ReactEcharts
                  style={{ height: "170px" }}
                  option={getOption()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Badanhukum;