import { agencyConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getAgencies = ({status, page = 1, perPage = 10}) => {
    return async (dispatch) => {
        dispatch({ type: agencyConstants.FETCH_AGENCIES_REQUEST })
       
        try {
            axios.get(`${process.env.REACT_APP_AUTH_URI}userAgency?status=${status}&page=${page}&perPage=${perPage}`)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data.status == "success") {
                        const agencies = data.result?.data ?? [] 

                        dispatch({
                            type: agencyConstants.FETCH_AGENCIES_SUCCESS,
                            payload: { 
                                data: agencies 
                            }
                        })
                    }
                })
        } catch (err) {
            console.log(err);
            dispatch({
                type: agencyConstants.FETCH_AGENCIES_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const getAgencyInfo = (id) => {
    return async (dispatch) => {
        dispatch({ type: agencyConstants.FETCH_AGENCY_REQUEST })
       
        try {
            axios.get(`${process.env.REACT_APP_AUTH_URI}userAgencyInfo/${id}`)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data.status == "success") {
                        const agency = data?.result ?? {}
                        const user = JSON.parse(localStorage.getItem("user"));
                        user.user_agency = agency
                        localStorage.setItem("user", JSON.stringify(user))

                        dispatch({
                            type: agencyConstants.FETCH_AGENCY_SUCCESS,
                            payload: { 
                                data: agency 
                            }
                        })
                    }
                })
        } catch (err) {
            console.log(err);
            dispatch({
                type: agencyConstants.FETCH_AGENCY_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const updateStatusAgency = (id, status, reason = null) => {
    return async (dispatch) => {
        dispatch({ type: agencyConstants.UPDATE_STATUS_AGENCY_REQUEST })

        try {
            axios.put(`${process.env.REACT_APP_AUTH_URI}/userAgency/${id}`, { status, reason })
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data.status == "success") {
                        dispatch({
                            type: agencyConstants.UPDATE_STATUS_AGENCY_SUCCESS,
                            payload: {
                                message: data.message
                            }
                        })
                    }
                })
        } catch (err) {
            console.log(err);
            dispatch({
                type: agencyConstants.UPDATE_STATUS_AGENCY_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}

export const submitRequiredData = (id, data) => async dispatch => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: agencyConstants.SUBMIT_REQUIRED_DATA_REQUEST })

        const body = new FormData()
        body.append('identity_card_front', data.idCardFrontFile)
        body.append('identity_card_back', data.idCardBackFile)
        body.append('letter_appointment', data.letterAppointmentFile)
        body.append('ssm_certification', data.ssmSertificationFile)

        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            axios.post(`${process.env.REACT_APP_AUTH_URI}/updateUserAgencyRequiredData/${id}`, body, config)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data.status == "success") {
                        dispatch({
                            type: agencyConstants.SUBMIT_REQUIRED_DATA_SUCCESS,
                            payload: { 
                                message: data.message
                            }
                        })
                        resolve(data.message)
                    }
                })
        } catch (err) {
            console.log(err);
            dispatch({
                type: agencyConstants.SUBMIT_REQUIRED_DATA_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    })
}

export const approveReevaluateData = (id, data) => async dispatch => {
    return new Promise(async (resolve, rejected) => {
        dispatch({ type: agencyConstants.APPROVE_REEVALUATE_DATA_REQUEST })

        const body = new FormData()
        body.append('name_agency', data.nameAgency)
        body.append('name_representative', data.nameRepresentative)
        body.append('email', data.email)
        body.append('pic_number', data.picNumber)
        body.append('identity_card_front', data.idCardFrontFile)
        body.append('identity_card_back', data.idCardBackFile)
        body.append('letter_appointment', data.letterAppointmentFile)
        body.append('ssm_certification', data.ssmSertificationFile)

        try {
            axios.post(`${process.env.REACT_APP_AUTH_URI}/approveReevaluateData/${id}`, body)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data.status == "success") {
                        dispatch({
                            type: agencyConstants.APPROVE_REEVALUATE_DATA_SUCCESS,
                            payload: { 
                                message: data.message
                            }
                        })
                        resolve(data.message)
                    }
                })
        } catch (err) {
            console.log(err);
            dispatch({
                type: agencyConstants.APPROVE_REEVALUATE_DATA_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    })
}
