import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaStore } from 'react-icons/fa'
import { useSelector } from 'react-redux';

function StatCard(props) {
  const { t } = useTranslation();
  let bgColor = "bg-" + props.color;
  const _umkm = useSelector(state => state.dashboard.total_umkm)
  const instansi = useSelector(state => state.instansi)
  return (
    <>
    {instansi?.instansi?.zone_id != null ?
      <div className="col-md-6 stat-card1">
        <div className="row">
            <div className="col-md-12">
              <div className="card card-hg card-hg-mr mb-3 border-0 rounded-3 shadow">
                <span className="card-title text-white">{_umkm?.total}</span>
                <span className="card-desk text-white">{t('total_entrepreneurship')} </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card card-hg-kn mb-3 border-0 rounded-3 shadow">
                <span className="card-title text-white">{_umkm?.umkm_nonbinaan}</span>
                <span className="card-desk text-white">{t('program_participants')} </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card card-hg card-hg-hj mb-3 border-0 rounded-3 shadow">
                    <span className="card-title text-white">{_umkm?.umkm_binaan}</span>
                    <span className="card-desk text-white">{t('program_winner')} </span>
              </div>
            </div>
        </div>
      </div>
    :
      <div className="col-md-6 stat-card1">
        <div className="row">
            <div className="col-md-12">
              <div className="card card-hg card-hg-hj mb-3 border-0 rounded-3 shadow">
                    <span className="card-title text-white">{_umkm?.total}</span>
                    <span className="card-desk text-white">{t('program_winner')} </span>
              </div>
            </div>
        </div>
      </div>
    }
    </>
  );
}

export default StatCard;
