/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

function Jenisindustri(props) {
  const { t } = useTranslation();
  let bgColor = "bg-" + props.color;
  const data = useSelector(state => state.dashboard.data_program)
  useEffect(() => {
    getOption()
  }, [data])

  var dataSet = [];
  data?.dataJenisIndustri?.map((val,index) => {
    dataSet.push({ value: val.value, name: val.name })
  })
  function getOption(){
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        bottom: "bottom",
        data: dataSet,
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#FCB630", "#f8b425", "#ec4561", "#20c997","#28a745"],
      series: [
        {
          name: t('total_msme'),
          type: "pie",
          radius: "55%",
          bottom: "20%",
          center: ["50%", "60%"],
          data: dataSet,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
          </div>
          <div className="card-content echart-bidang">            
            <ReactEcharts style={{ height: "350px" }} option={getOption()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jenisindustri;
