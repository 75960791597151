/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';

function StatCard(props) {
  const data_program = useSelector(state => state.dashboard.data_program)
  var dataSet = [];
  var dataName = [];
  data_program?.dataByCategory?.map((val,index) => {
    dataSet.push({ value: val.value, name: val.name })
    dataName.push(val.name)
  })
  let bgColor = "bg-" + props.color;
  function getOption(){
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "horizontal",
        bottom: "bottom",
        data: dataName,
        textStyle: {
          color: ["#74788d"],
        },
      },
      color: ["#FCB630", "#f8b425", "#ec4561", "#20c997","#28a745"],
      series: [
        {
          name: "Kategori",
          type: "pie",
          radius: "55%",
          bottom: "20%",
          center: ["50%", "60%"],
          data: dataSet,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
        <div className="card-body">
          <div className="card-header1">
            <h5>{props.stat}</h5>
            <h6>{props.label}</h6>
          </div>
          <div className="card-content echart-bidang">
            
            <ReactEcharts style={{ height: "350px" }} option={getOption()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatCard;