import { programConstants } from "../actions/constants"

const initState = {
  error: null,
  message: '',
  loading: false,
  program: [],
  programNoResource: [],
  meta: {
    total: 0
  },
  ProgramParticipant: {
    data: [],
    meta: {
      current_page: 1,
      per_page: 0,
      last_page: 0
    }
  },
  ProgramUMKMBinaan: {
    data: [],
    meta: {
      current_page: 1,
      per_page: 0,
      last_page: 0
    }
  },
}

export default (state = initState, action) => {
  switch (action.type) {
    case programConstants.FETCH_PROGRAM_REQUEST:
      state = {
        ...state,
        loading: true
      }
      break;
    case programConstants.FETCH_PROGRAM_SUCCESS:
      state = {
        ...state,
        loading: false,
        program: action.payload.program,
        meta: action.payload.meta
      }
      break;
    case programConstants.FETCH_PROGRAM_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
      break;
    case programConstants.PROGRAM_POST_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case programConstants.PROGRAM_POST_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message
      }
      break;
    case programConstants.PROGRAM_POST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    case programConstants.PROGRAM_EDIT_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case programConstants.PROGRAM_EDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message
      }
      break;
      case programConstants.PROGRAM_EDIT_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
      break;
    case programConstants.PROGRAM_DELETE_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case programConstants.PROGRAM_DELETE_SUCCESS:
      let program = []
      
      state.program.map((newProgram, i) => {
        if (newProgram.id != action.payload.id) { 
          program.push(newProgram)
        }
      })

      state = {
        ...state,
        loading: false,
        program: program,
      }

      break;
    case programConstants.PROGRAM_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    case programConstants.PROGRAM_RESTORE_REQUEST:
      state = {
        ...state,
        loading: true
      }
    break;
    case programConstants.PROGRAM_RESTORE_SUCCESS:
      let program1 = []
      
      state.program.map((newProgram, i) => {
        if (newProgram.id != action.payload.id) { 
          program1.push(newProgram)
        }
      })

      state = {
        ...state,
        loading: false,
        program: program1,
      }

      break;
    case programConstants.PROGRAM_RESTORE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error
      }
    break;
    
      case programConstants.FETCH_PROGRAM_PARTICIPANT_REQUEST:
        state = {
          ...state,
          loading: true
        }
        break;
      case programConstants.FETCH_PROGRAM_PARTICIPANT_SUCCESS:
        state = {
          ...state,
          loading: false,
          ProgramParticipant: action.payload.ProgramParticipant,
        }
        break;
      case programConstants.FETCH_PROGRAM_PARTICIPANT_FAILURE:
        state = {
          ...state,
          loading: false,
          error: action.payload.error
        }
        break;

        case programConstants.FETCH_PROGRAM_NO_RESOURCE_REQUEST:
          state = {
            ...state,
            loading: true
          }
          break;
        case programConstants.FETCH_PROGRAM_NO_RESOURCE_SUCCESS:
          state = {
            ...state,
            loading: false,
            programNoResource: action.payload.programNoResource,
            meta: action.payload.meta
          }
          break;
        case programConstants.FETCH_PROGRAM_NO_RESOURCE_FAILURE:
          state = {
            ...state,
            loading: false,
            error: action.payload.error
          }
          break;
        case programConstants.PROGRAM_NO_RESOURCE_EDIT_REQUEST:
          state = {
            ...state,
            loading: true
          }
          break;
        case programConstants.PROGRAM_NO_RESOURCE_EDIT_SUCCESS:
          state = {
            ...state,
            loading: false
          }
          break;
        case programConstants.PROGRAM_NO_RESOURCE_EDIT_FAILURE:
          state = {
            ...state,
            loading: false,
            error: action.payload.error
          }
          break;

          case programConstants.FETCH_PROGRAM_UMKM_BINAAN_REQUEST:
            state = {
              ...state,
              loading: true
            }
            break;
          case programConstants.FETCH_PROGRAM_UMKM_BINAAN_SUCCESS:
            state = {
              ...state,
              loading: false,
              ProgramUMKMBinaan: action.payload.ProgramUMKMBinaan,
            }
            break;
          case programConstants.FETCH_PROGRAM_UMKM_BINAAN_FAILURE:
            state = {
              ...state,
              loading: false,
              error: action.payload.error
            }
            break;
  }

  return state;
}