import React, { useEffect, useState } from "react";
import Modal from "../../components/UI/Modal";
import "./style.scss"
import { useTranslation } from "react-i18next";
import FormReevaluate from "./formReevaluate";
import { approveReevaluateData, getAgencyInfo } from "../../actions/agency.actions";
import { useDispatch } from "react-redux";
import Loading from "../../components/UI/Loading";

const FormDetail = ({ isShow, data, handleClose, handleUpdateStatus }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState()
  const [nameAgency, setNameAgency] = useState()
  const [nameRepresentative, setNameRepresentative] = useState()
  const [picNumber, setPicNumber] = useState()
  const [showReevaluateModal, setShowReevaluateModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [idCardFrontFile, setIdCardFrontFile] = useState('')
  const [idCardBackFile, setIdCardBackFile] = useState('')
  const [letterAppointmentFile, setLetterApointmentFile] = useState('')
  const [ssmSertificationFile, setSsmCertificationFile] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    setEmail(data?.user?.email ?? '')
    setNameAgency(data.name_agency)
    setNameRepresentative(data.name_representative)
    setPicNumber(data.pic_number)
  }, [data])

  const handleApprove = () => {
    const reevaluateData = {
      nameAgency,
      nameRepresentative,
      email,
      picNumber,
      idCardFrontFile,
      idCardBackFile,
      letterAppointmentFile,
      ssmSertificationFile
    }
    setLoading(true)
    dispatch(approveReevaluateData(data.id, reevaluateData))
      .then((res) => {
        setLoading(false)
        handleClose()
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <Loading />}
      <Modal modalTitle={'Detail Data'} size="lg" show={isShow} handleClose={() => handleClose()}>
        <div className="row">
          <div className="col-md-12">
            <form className="mt-2" enctype="multipart/form-data">
              <label>Email</label>
              <input
                className="form-control d-inline mb-3"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={data.status !== 'reevaluate'}
              />
              <label>Name of Agency</label>
              <input
                className="form-control d-inline mb-3"
                type="text"
                value={nameAgency}
                onChange={(e) => setNameAgency(e.target.value)}
                disabled={data.status !== 'reevaluate'}
              />
              <label>Name of Representative</label>
              <input
                className="form-control d-inline mb-3"
                type="text"
                value={nameRepresentative}
                onChange={(e) => setNameRepresentative(e.target.value)}
                disabled={data.status !== 'reevaluate'}
              />
              <label>PIC Contact Number</label>
              <input
                className="form-control d-inline mb-3"
                type="text"
                value={picNumber}
                onChange={(e) => setPicNumber(e.target.value)}
                disabled={data.status !== 'reevaluate'}
              />
              <div className="col">
                <label>Identification Card (Front) of Representative</label>&nbsp;
                <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + data.identity_card_front} target="_blank">[Open]</a>
                {data.status === 'reevaluate' && <input
                  className="form-control d-inline mb-3"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      setIdCardFrontFile(e.target.files[0]);
                    }
                  }}
                />}
              </div>
              <div className="col">
                <label>Identification Card (Back) of Representative</label>&nbsp;
                <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + data.identity_card_back} target="_blank">[Open]</a>
                {data.status === 'reevaluate' && <input
                  className="form-control d-inline mb-3"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      setIdCardBackFile(e.target.files[0]);
                    }
                  }}
                />}
              </div>
              <div className="col">
                <label>Letter of Appointment as Representative of Agency</label>&nbsp;
                <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + data.letter_appointment} target="_blank">[Open]</a>
                {data.status === 'reevaluate' && <input
                  className="form-control d-inline mb-3"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      setLetterApointmentFile(e.target.files[0]);
                    }
                  }}
                />}
              </div>
              <div className="col">
                <label>SSM certification (if any)</label>&nbsp;
                <a href={`${process.env.REACT_APP_AUTH_URI}/images/` + data.ssm_certification} target="_blank">[Open]</a>
                {data.status === 'reevaluate' && <input
                  className="form-control d-inline mb-3"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      setSsmCertificationFile(e.target.files[0]);
                    }
                  }}
                />}
              </div>
            </form>
          </div>
        </div>
        {(data.status === 'new' || data.status === 'reevaluate') && <div className="row">
          <div className="col mt-5">
            <div className="float-right">
              <button className="btn btn-sm button-primary mr-2" onClick={() => { handleApprove() }}>Approve</button>
              {data.status === 'new' && <button className="btn btn-sm button-secondary mr-2" onClick={() => {
                setShowReevaluateModal(true);
                handleClose()
              }}>Reevaluate</button>}
              <button className="btn btn-sm btn-danger mr-2" onClick={() => handleUpdateStatus(data, 'failed')}>Reject</button>
            </div>
          </div>
        </div>}
        {data.status === 'failed' && <div className="row">
          <div className="col mt-5">
            <div className="float-right">
              <button className="btn btn-sm button-primary mr-2" onClick={() => { handleUpdateStatus(data, 'new', 'reuse') }}>Reuse</button>
            </div>
          </div>
        </div>}
      </Modal>
      <FormReevaluate
        isShow={showReevaluateModal}
        data={data}
        handleClose={() => setShowReevaluateModal(false)}
        handleUpdateStatus={handleUpdateStatus}
      />
    </>

  )
}

export default FormDetail