import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaCogs, FaPencilAlt, FaEye, FaTrashAlt, FaTrashRestoreAlt } from 'react-icons/fa'
import { Dropdown } from "react-bootstrap";
import { Tabs, Tab } from 'react-bootstrap';
import './style.scss';
import FormDet from "./formDetail";
import FormEdit from "../ProgramTambah";
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgram, deleteProgram, restoreProgram } from "../../../actions/program.actions";
import moment from 'moment';
import {setStatus} from "../fnc_var";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function ProgramData() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('all');
  const [selectedProgram, setSelectedProgram] = useState({})
  const program = useSelector(state => state.program)
  const user = useSelector(state => state.auth)
  const dispatch = useDispatch()
  
  const [showFormDetail, setShowFormDetail] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = program?.program?.map((i, index) => {
    return {
      col1: (((page - 1) * perPage) + (index + 1)).toString(),
      col2: i.program_name,
      col3: i.program_category?.name,
      col4: moment(i.program_start).format('DD-MM-YYYY HH:mm')+' WIB',
      col5: moment(i.program_end).format('DD-MM-YYYY HH:mm')+' WIB',
      col6: setStatus(i.status),
      col7: i.participant_quota??'-',
      col8: i.program_participans.length??'-',
      col9: i.id,
    };
  });
  
  const data = React.useMemo(() => list, [program?.program]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: 'col1',
        width: '5%',
        textAlign: 'center'
      },
      {
        Header: t('program_name'),
        accessor: 'col2'
      },
      {
        Header: t('program_type'),
        accessor: 'col3',
        width: '18%',
      },
      {
        Header: t('start_type'),
        accessor: 'col4',
        width: '10%',
        textAlign: 'center'
      },
      {
        Header: t('end_time'),
        accessor: 'col5',
        width: '10%',
        textAlign: 'center'
      },
      {
        Header: t("status"),
        accessor: 'col6',
        width: '8%',
        textAlign: 'center',
        className:'capitalize'
      },
      {
        Header: t("quota"),
        accessor: 'col7',
        width: '8%',
        textAlign: 'center'
      },
      {
        Header: t("number_of_participants"),
        accessor: 'col8',
        width: '8%',
        textAlign: 'center'
      },
      {
        Header: t("details"),
        accessor: 'col9',
        Cell: (item) => (
          <div className="btn-setting">
            <Dropdown className="dpw-setting">
              <Dropdown.Toggle variant="setting" id="dropdown-basic">
                <FaCogs className="me-1" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {item.customState.activeTab=='deleted'?
                  <Dropdown.Item href="#" onClick={() => {
                      handleRestore(item.cell.value)
                    }}>
                      <FaTrashRestoreAlt />
                      Pulihkan
                  </Dropdown.Item>
                :
                <>
                  <Dropdown.Item href="#" onClick={() => {
                    handleFormDetail(item.cell.value,item.customState.listProgram,'edit')
                  }}>
                    <FaPencilAlt />
                    Ubah
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => {
                    handleFormDetail(item.cell.value,item.customState.listProgram,'view')
                  }}>
                    <FaEye />
                    {t("details")}
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => {
                    handleDelete(item.cell.value)
                  }}>
                    <FaTrashAlt />
                    {t('delete')}
                  </Dropdown.Item>
                </>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ),
        width: '5%',
        textAlign: 'center'
      }
    ],
    [program, t]
  );

  useEffect(() => {
    let parm = {
      status:activeTab,
      is_owner:1
    }
    dispatch(getProgram(page, perPage, parm))
  }, [page, perPage, activeTab])

  const handleFormDetail = (id,data,state) => { 
    const data_program = data.find(item => item.id === id);
    setSelectedProgram(data_program);
    switch (state) {
      case 'edit':
        setShowFormEdit(true);
      break;
      case 'view':
        setShowFormDetail(true);
      break;
    }
  }

  const handleDelete = (id) => { 
    Swal.fire({
      title: '',
      text: t('delete_confirmation'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: t('delete_confirmation_yes'),
      cancelButtonColor: '#d33',
      cancelButtonText: t('cancelled'),
  
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProgram(id));
      }
    }) 
  }

  const handleRestore = (id) => { 
    Swal.fire({
      title: '',
      text: t('restore_confirmation'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: t('delete_confirmation_yes'),
      cancelButtonColor: '#d33',
      cancelButtonText: t('cancelled'),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(restoreProgram(id));
      }
    }) 
  }

  return (
    <>
    {!showFormEdit?
      <>
      <Layout>
      <div className="row table-title">
        <span className="title">Data Program</span>
        <span className="desk">Program {'>'} Data Program</span>
      </div>
      <div className="row table-view">
        <div className="col"> 
            <div className="col-tab-wrapper col-tab-mrgn">
              <Tabs
                  id="controlled-tab-example"
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                  className="mb-1"
                  >
                  <Tab eventKey="all" title={t('all')} />
                  <Tab eventKey="draft" title={t('draft')} />
                  <Tab eventKey="scheduled" title={t('scheduled')} />
                  <Tab eventKey="progress" title={t('on_progress')} />
                  <Tab eventKey="suspend" title={t('on_hold')} />
                  <Tab eventKey="complete" title={t('done')} />
                  <Tab eventKey="deleted" title={t('delete')} />
              </Tabs>
            </div>
            <DataTable
              data={data}
              customState={{listProgram:program?.program??[],activeTab:activeTab}}
              columns={columns}
              loading={program.loading}
              setPage={setPage}
              setPerPage={setPerPage}
              currentpage={page}
              perPage={perPage}
              totalPage={program?.meta?.last_page}
            />
        </div>
      </div>      
      </Layout>
      <FormDet 
        isShow={showFormDetail}
        data={selectedProgram}
        handleClose={() => setShowFormDetail(false)}
      />
      </>
    :
      <FormEdit 
        editState={selectedProgram} 
        handleBack={() => setShowFormEdit(false)}
      />
    }
    </>
  );
}

export default ProgramData;
