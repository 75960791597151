import { businessmanConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getBusinessmans = (page, perPage, search) => {
  return async (dispatch) => {
    dispatch({ type: businessmanConstants.FETCH_BUSINESSMANS_REQUEST })

    const user = JSON.parse(localStorage.getItem("user"))
    const instanceId = user.user_agency.id
    
    try {
      const res = await axios.get(`${process.env.REACT_APP_MASTER_URI}chart/umkm-binaan/${instanceId}?perPage=${perPage}&page=${page}&sortBy=created_at&sort=DESC&search=${search}`)

      if (res.data.status === "success") {
        const businessmans = res.data.result

        dispatch({
          type: businessmanConstants.FETCH_BUSINESSMANS_SUCCESS,
          payload: { businessmans }
        })
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: businessmanConstants.FETCH_BUSINESSMANS_FAILURE,
        payload: { error: i18next.t("something_went_wrong") }
      })
    }
  }
}