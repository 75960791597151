/* eslint-disable default-case */
import React from 'react';
import ReactEcharts from "echarts-for-react"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function generateColor(val){
  var color = '';
  if (val < 50){
    color = 'bf3'; 
  }else if (val >= 50 && val < 100){
    color = 'bf1'; 
  }else if (val >= 100){
    color = 'bf4'; 
  }  
  return color;
}
function genColor(val,sm){
  var color = '';
  let md = sm/2;
  let sd = sm/3;
  if (val >= sm){
    color = '#FCB630'; 
  }else if (val < sm && val >= md){
    color = '#053DC8'; 
  }else if (val <= sd){
    color = '#ffa127'; 
  }  
  return color;
}
function Badanhukum(props) {
  const { t } = useTranslation();
  let bgColor = "bg-" + props.color;
  const data = useSelector(state => state.dashboard.data_recap_trainers_count)
  let dataY = [];
  let dataX = [];
  let sum = 0;
  if (data?.jumlah_pegawai) {
    Object.keys(data.jumlah_pegawai).map(function(key, index) {
      if(data.jumlah_pegawai[key]>sum){
        sum=data.jumlah_pegawai[key];
      }
    });
    Object.keys(data.jumlah_pegawai).map(function(key, index) {
      switch (key) {
        case 'less_than_5':
          dataY[3] = '< 5 Orang';
          dataX[3] = { value: data.jumlah_pegawai[key], itemStyle: {color: genColor(data.jumlah_pegawai[key],sum)}};
        break;
        case '5_to_19':
          dataY[2] = '5-19 Orang';
          dataX[2] = { value: data.jumlah_pegawai[key], itemStyle: {color: genColor(data.jumlah_pegawai[key],sum)}};
        break;
        case '20_to_99':
          dataY[1] = '20-99 Orang';
          dataX[1] = { value: data.jumlah_pegawai[key], itemStyle: {color: genColor(data.jumlah_pegawai[key],sum)}};
        break;
        case 'more_than_100':
          dataY[0] = '> 100 Orang';
          dataX[0] = { value: data.jumlah_pegawai[key], itemStyle: {color: genColor(data.jumlah_pegawai[key],sum)}};
        break;
      }
    });
  }

  function getOption(){
    return {
        tooltip: {
          trigger: "item",
          formatter: "{b} <br/>{c} {a}",
          axisPointer: {    
              type: 'shadow'
          }
        },
        grid: {
            top: 10,
            bottom: 30,
            left: 70,
            right: 40
        },
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: dataY,
            axisLabel: {
                show: true,
                textStyle: {
                    fontSize: 10
                },
                rich: {
                    flag: {
                        fontSize: 5,
                        padding: 2
                    }
                }
            },
            axisTick: {
                alignWithLabel: true
            }
        },
        series: [{
            name: 'UMKM',
            data: dataX,
            type: 'bar',
            inverse: false,
            label: {
                show: true,
                precision: 1,
                position: 'right',
                valueAnimation: true,
                fontFamily: 'poppins'
            }
        }]
    }
  }
  return (
    <div className="col-md-6 col-lg-6">
      <div className="row">
        <div className="col-md-12">
          <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
            <div className="card-body">
              <div className="card-header1">
                <h5>{props.stat}</h5>
                <h6>{props.label}</h6>
              </div>
              <div className="card-content">
                <div className="kt-widget12__progress">
                  <span>Enterprise(Sole Proprietorship)</span>
                  <span className={"kt-widget12__stat2 "+ generateColor(data?.bidang_hukum?.pt)}>{data?.bidang_hukum?.pt}</span>
                </div>
                <div className="kt-widget12__progress">
                  <span>Enterprise(Partnership) </span>
                  <span className={"kt-widget12__stat2 "+ generateColor(data?.bidang_hukum?.cv)}>{data?.bidang_hukum?.cv}</span>
                </div>
                <div className="kt-widget12__progress">
                  <span>Private Limited Company</span>
                  <span className={"kt-widget12__stat2 "+ generateColor(data?.bidang_hukum?.individual)}>{data?.bidang_hukum?.individual}</span>
                </div>
                <div className="kt-widget12__progress">
                  <span>Company Limited by Guarantee(CLBG) </span>
                  <span className={"kt-widget12__stat2 "+ generateColor(data?.bidang_hukum?.individual)}>{data?.bidang_hukum?.individual}</span>
                </div>
                <div className="kt-widget12__progress">
                  <span>Unlimited Company</span>
                  <span className={"kt-widget12__stat2 "+ generateColor(data?.bidang_hukum?.individual)}>{data?.bidang_hukum?.individual}</span>
                </div>
                <div className="kt-widget12__progress">
                  <span>Foreign Company</span>
                  <span className={"kt-widget12__stat2 "+ generateColor(data?.bidang_hukum?.individual)}>{data?.bidang_hukum?.individual}</span>
                </div>
                <div className="kt-widget12__progress">
                  <span>Limited Liability Partnership</span>
                  <span className={"kt-widget12__stat2 "+ generateColor(data?.bidang_hukum?.individual)}>{data?.bidang_hukum?.individual}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className={"card card-hg mb-3 border-0 rounded-3 shadow " + bgColor}>
            <div className="card-body">
              <div className="card-header1">
                <h5>{t('number_of_employees')}</h5>
                <h6>{'4 Jenis'}</h6>
              </div>
              <div className="card-content">
                <ReactEcharts style={{ height: "170px" }} option={getOption()} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Badanhukum;