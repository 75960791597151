import { authConstants } from "./constants";
import axios from "../helpers/axios_login";
import env from '../config/env';
import i18next from "i18next";

export const login = (user) => {
    return async (dispatch) => {
        dispatch({ type: authConstants.LOGIN_REQUEST });
        const res = await axios.post(`/oauth/token`, {
            'grant_type' : 'password',
            'client_id' : env.client_id,
            'client_secret' : env.client_secret,
            'username' : user.username,
            'password': user.password
        });

        if(res.status === 200){
            const token = res.data.result.access_token;
            let user = res.data.result.user;

            const roleModulesFiltered = user.role_modules.filter(data => data.role_id == 9)

            if (roleModulesFiltered.length > 0) {

                var expires_in = new Date();
                var current_time = new Date();
                expires_in.setSeconds(current_time.getSeconds() + res.data.result.expires_in);
                user.expired_date = expires_in;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));

                setTimeout(async function(){ 
                    const res2 = await axios.get(`${process.env.REACT_APP_MASTER_URI}instansis/${user.user_instansi[0].id}`)
                    if (res2.data.status === "success") {
                        const cooperation_status = res2.data.result.cooperation_status;
                        localStorage.setItem('cooperation_status', cooperation_status);
                    }
                }, 1000);
            
                dispatch({
                    type: authConstants.LOGIN_SUCCESS,
                    payload: {
                        token, user
                    }
                });
            } else {
                dispatch({
                    type: authConstants.LOGIN_FAILURE,
                    payload: { error: "Akses ditolak" }
                });

            }
        }else{
            if(res.status === 400){
                dispatch({
                    type: authConstants.LOGIN_FAILURE,
                    payload: { error: res }
                });
            }
        }
    }
}

export const register = (data) => async dispatch => {
    return new Promise(async (resolve, rejected) => {
        try {

            dispatch({ type: authConstants.REGISTER_REQUEST });
            const res = await axios.post(`/registerAgency`, {
                'name': data.nameAgency,
                'name_representative': data.nameRepresentative,
                'email': data.email,
                'password': data.password,
                'pic_number': data.picContact,
            });
            console.log('----')
            console.log(res)
            console.log('----')

            if (res.status === 200) {
                dispatch({
                    type: authConstants.REGISTER_SUCCESS,
                    payload: {
                        message: res.data.message.custom[0]
                    }
                });
                resolve(res.data.message.custom[0])
            } else {
                if (res.status === 400) {
                    rejected(data?.error ?? 'Internal server error')
                    dispatch({
                        type: authConstants.REGISTER_FAILURE,
                        payload: { error: res }
                    });
                }
            }
        } catch (err) {
            rejected(err?.response?.data?.error ?? 'Internal server error')
            dispatch({
                type: authConstants.REGISTER_FAILURE,
                payload: { error: err?.response?.data?.error ?? 'Internal server error' }
            });
        }
    })
}

export const isUserLoggedIn = () => {
    return async dispatch => {
        const token = localStorage.getItem('token');
        if(token){
            const user = JSON.parse(localStorage.getItem('user'));
            dispatch({
                type: authConstants.LOGIN_SUCCESS,
                payload: {
                    token, user
                }
            });
        }else{
            dispatch({
                type: authConstants.LOGIN_FAILURE,
                payload: { error: 'Failed to login' }
            });
        }
    }
}

export const signout = () => {
    return async dispatch => {

        localStorage.clear();
        dispatch({ type: authConstants.LOGOUT_SUCCESS });

        // dispatch({ type: authConstants.LOGOUT_REQUEST });
        // const res = await axios.post(`/admin/signout`);

        // if(res.status === 200){
        //     localStorage.clear();
        //     dispatch({ type: authConstants.LOGOUT_SUCCESS });
        // }else{
        //     dispatch({
        //         type: authConstants.LOGOUT_FAILURE,
        //         payload: { error: res.data.error }
        //     });
        // }        
    }
}