/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from '../../../components/Base/main/main';
import { FaUserTie, FaSearch, FaArrowLeft } from 'react-icons/fa'
import './table.css';
import FormUmkm from "../../DataUmkm/Umkm/formDetail";
import DataTable from '../../../components/UI/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getProgramUMKMBinaan } from "../../../actions/program.actions";
import { useTranslation } from "react-i18next";

const Detail = ({ program_id, handleBack }) => {
  const { t } = useTranslation();
  const [showFormUmkm, setShowFormUmkm] = useState(false);
  const [selectedUmkm, setSelectedUmkm] = useState([]);
  const loading = useSelector((state) => state.program.loading);
  const ProgramUMKMBinaan = useSelector(
    (state) => state.program.ProgramUMKMBinaan
  );
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const list = ProgramUMKMBinaan?.data.map((i, index) => {
    return {
      col1: ((page - 1) * perPage + (index + 1)).toString(),
      col2: i?.businessman?.business_name,
      col3: i?.businessman?.owner_name,
      col4: i?.businessman?.business_wa_number,
      col5: "-",
      col6: i?.businessman?.okr_level?.level_name,
      col7: i?.business_value !== null ? `${i?.business_value} XP` : "0 XP",
      col8: i?.businessman?.status_disabled !== 0 ? "Tidak Aktif" : "Aktif",
      col9: i?.businessman_id,
    };
  });

  const data = React.useMemo(() => list, [ProgramUMKMBinaan?.data]);
  const columns = React.useMemo(
    () => [
      {
        Header: t("number"),
        accessor: "col1",
        width: "5%",
        textAlign: "center",
      },
      {
        Header: t("msme_name"),
        accessor: "col2",
        width: "15%",
        Cell: ({ row: { original } }) => (
          <div className="td-usaha">
            <span className={`span-icon-${original.col5}`}>
              <FaUserTie className="me-1" />
            </span>
            <span className="span-text">{original.col2}</span>
          </div>
        ),
      },
      {
        Header: t("owners_name"),
        accessor: "col3",
        width: "15%",
      },
      {
        Header: t("whatsapp_contact"),
        accessor: "col4",
        width: "15%",
      },
      {
        Header: t('last_login'),
        accessor: "col5",
        width: "10%",
        textAlign: "center",
      },
      {
        Header: t("class"),
        accessor: "col6",
        width: "10%",
        textAlign: "center",
      },
      {
        Header: t('business_value'),
        accessor: "col7",
        width: "10%",
        textAlign: "center",
      },
      {
        Header: t("status"),
        accessor: "col8",
        width: "15%",
        textAlign: "center",
      },
      {
        Header: t("details"),
        accessor: "col9",
        Cell: (item) => (
          <span
            onClick={() => {
              setSelectedUmkm(
                item.customState.listumkm.find(
                  (val) => val.businessman_id === item.cell.value
                )?.businessman
              );
              setShowFormUmkm(true);
            }}
            className="span-rincian"
          >
            {t("details")}
          </span>
        ),
        width: "10%",
        textAlign: "center",
      },
    ],
    [ProgramUMKMBinaan?.data,t]
  );

  useEffect(() => {
    dispatch(getProgramUMKMBinaan(program_id, page, perPage, search));
  }, [program_id, page, perPage, search]);

  return (
    <>
      <div className="col">
        <div className="row">
          <div className="col-wrapper">
            <div className="text-start">
              <button
                onClick={() => handleBack()}
                className="btn btn-sm btn-outline-secondary btn-back"
              >
                <FaArrowLeft className="me-1" />
                kembali
              </button>
            </div>
            <div className="text-end"></div>
          </div>

          <div className="col-wrapper">
            <div className="text-start">
              <div className="search-wrapper">
                <input
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  id="search-input"
                  placeholder="Cari UMKM.."
                  type="text"
                />
                <button className="btn btn-sm btn-outline-secondary mt-1">
                  <FaSearch className="me-1" />
                </button>
              </div>
            </div>
            <div className="text-end"></div>
          </div>
        </div>

        <DataTable
          data={data}
          customState={{ listumkm: ProgramUMKMBinaan?.data ?? [] }}
          columns={columns}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={ProgramUMKMBinaan?.meta.last_page}
          loading={loading}
        />
      </div>
      <FormUmkm
        isShow={showFormUmkm}
        data={selectedUmkm}
        handleClose={() => setShowFormUmkm(false)}
      />
    </>
  );
};

export default Detail;
