import { legalEntityConstants } from "./constants";
import axios from "../helpers/axios";
import i18next from "i18next";

export const getLegalEntities = () => {
    return async (dispatch) => {
        dispatch({ type: legalEntityConstants.FETCH_LEGAL_ENTITIES_REQUEST })
       
        try {
            axios.get(`${process.env.REACT_APP_MASTER_URI}legal_entities`)
                .then(response => {
                    return response.data
                })
                .then(data => {
                    if (data.status == "success") {
                        const legalEntities = data.result ?? [] 

                        dispatch({
                            type: legalEntityConstants.FETCH_LEGAL_ENTITIES_SUCCESS,
                            payload: { 
                                data: legalEntities 
                            }
                        })
                    }
                })
        } catch (err) {
            console.log(err);
            dispatch({
                type: legalEntityConstants.FETCH_LEGAL_ENTITIES_FAILURE,
                payload: { error: i18next.t("something_went_wrong") }
            })
        }
    }
}
