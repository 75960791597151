import authReducer from './auth.reducers';
import userReducer from './user.reducer';
import instansiReducers from './instansi.reducers';
import { combineReducers } from 'redux';
import dashboardReducers from './dashboard.reducers';
import businessmanReducers from './businessman.reducers';
import category_industryReducers from './category_industry.reducers';
import forgetReducers from './forget.reducers';
import fltWilayahReducers from './filter_wilayah.reducers';
import fltOkrReducers from './filter_okr.reducers';
import programReducers from './program.reducers';
import stakeholderReducers from './stakeholder.reducers';
import stakeholderProgramReducers from './stakeholder_program.reducers';
import agencyReducers from './agency.reducers';
import legalEntityReducers from './legal_entity.reducers';

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    businessman:businessmanReducers,
    instansi: instansiReducers,
    dashboard: dashboardReducers,
    category_industry: category_industryReducers,
    forget: forgetReducers,
    fltwilayah: fltWilayahReducers,
    fltOkr: fltOkrReducers,
    program: programReducers,
    stakeholder: stakeholderReducers,
    stakeholderProgram: stakeholderProgramReducers,
    agency: agencyReducers,
    legalEntity: legalEntityReducers,
});

export default rootReducer;